import gql from 'graphql-tag'

export const PREMIUM_REMINDERS_QUERY = gql`
    query premiumReminders(
        $page: Int = 1
        $count: Int = 1000
        $orderField: PremiumReminderOrderField = CREATED_AT
        $orderDirection: OrderDirection = DESC
        $filterMatchedContractIds: [ID!]
        $filterMatchedContractConfirmed: Boolean!
        $reminderDateFrom: Date
        $reminderDateUntil: Date
    ) {
        premiumReminders(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                matched_contract_ids: $filterMatchedContractIds
                matched_contract_confirmed: $filterMatchedContractConfirmed
                reminder_date_from: $reminderDateFrom
                reminder_date_until: $reminderDateUntil
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                file_import_identification_number
                contract_number
                framework_agreement_number
                invoice_number
                invoice_date
                document_recipient
                sectorCode {
                    id
                    code
                    name
                }
                product_provider_sector_code
                currency
                invoiced_amount
                reminder_charges_amount
                outstanding_invoice_amount
                commission_calculation_basis
                reminder_date
                coverage_interruption_from
                customer_first_name
                customer_last_name
                customer_company_name
                files {
                    id
                    name
                    size
                    mime_type
                    created_at
                }
                contractMatchShortlist {
                    id
                }
                matchedContract {
                    id
                }
                matched_contract_confirmed
                created_at
                updated_at
            }
        }
    }
`
