import { loadEcoHubAndDxpBaseData } from '@/helpers/dxp'
import Vue from 'vue'
import App from '@/App.vue'
import router from '@/routes'
import store from '@/store/store'
import { i18n } from '@/i18n'
import '@/quasar'
import { Cookies } from 'quasar'
import Vuelidate from 'vuelidate'
import { InlineSvgPlugin } from 'vue-inline-svg'

// Permissions
import ROLES from '#/apollo-server/graphql/Users/roles.seeder.json'
import FEATURES from '#/apollo-server/graphql/Tenants/features.seeder.json'
import ACCOUNTING_PERMISSIONS from '#/apollo-server/graphql/Accounting/accountingPermissions.seeder.json'
import COMMENT_PERMISSIONS from '#/apollo-server/graphql/Comments/commentPermissions.seeder.json'
import COMMISSION_PERMISSIONS from '#/apollo-server/graphql/Commissions/commissionPermissions.seeder.json'
import CONTACT_PERMISSIONS from '#/apollo-server/graphql/Contacts/contactPermissions.seeder.json'
import CONTRACT_PERMISSIONS from '#/apollo-server/graphql/Contracts/contractPermissions.seeder.json'
import CORRESPONDENCE_PERMISSIONS from '#/apollo-server/graphql/Correspondence/correspondencePermissions.seeder.json'
import DOCUMENT_PERMISSIONS from '#/apollo-server/graphql/Documents/documentPermissions.seeder.json'
import FILE_PERMISSIONS from '#/apollo-server/graphql/Files/filePermissions.seeder.json'
import PRODUCT_PERMISSIONS from '#/apollo-server/graphql/Products/productPermissions.seeder.json'
import TASK_PERMISSIONS from '#/apollo-server/graphql/Tasks/taskPermissions.seeder.json'
import USER_PERMISSIONS from '#/apollo-server/graphql/Users/userPermissions.seeder.json'
import ECO_HUB_PERMISSIONS from '#/apollo-server/graphql/EcoHub/ecoHubPermissions.seeder.json'
import DXP_PERMISSIONS from '#/apollo-server/graphql/EcoHub/DXP/dxpPermissions.seeder.json'
import MAIL_PROCESSING_PERMISSIONS from '#/apollo-server/graphql/MailProcessing/mailProcessingPermissions.seeder.json'

import Acl from 'vue-browser-acl'
import { CRUDPolicy } from '@/acl'
import { ContactPolicy, ContactContactNumberPolicy, ContactConsultantsPolicy } from '@/acl/contact'
import { CommentPolicy } from '@/acl/comment'
import { DocumentTemplatePolicy } from '@/acl/documentTemplate'
import { DocumentLayoutPolicy } from '@/acl/documentLayout'
import { PostingPolicy } from '@/acl/posting'
import { Comment, DocumentTemplate } from '@/models/models'

import './styles/main.scss'

// Custom Vue components
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import PageLoadingIndicator from '@/components/PageLoadingIndicator.vue' // TODO improvement: Integrate this component directly in `PageWrapper`
import PageWrapper from '@/components/PageWrapper.vue'
import PageHeader from '@/components/PageHeader.vue'
import InPageFooter from '@/components/InPageFooter.vue'
import Illustration from '@/components/Illustration.vue'
import IllustrationHeading from '@/components/IllustrationHeading.vue'
import BaseButton from '@/components/BaseButton.vue'
import InfoBox from '@/components/InfoBox.vue'
import InfoIcon from '@/components/InfoIcon.vue'
import Skeleton from '@/components/Skeleton.vue'
import Card from '@/components/Card.vue'
import GridCard from '@/components/GridCard.vue'
import FormBuilder from '@/libs/form/components/FormBuilder'

// Dev Only Components (TODO: Remove when not needed anymore or find a solution to not include these in the production build.)
import DevHeader from '@/components/dev/DevHeader.vue'

import { User } from '@/models/user'
import { saveUserToStoreAndLoadInitialAppData } from '@/helpers/user'

Vue.use(Vuelidate)
Vue.use(InlineSvgPlugin)

const user = () => store.state.user
Vue.use(
    Acl,
    user,
    acl => {
        // Register models for verb object mapping
        acl.register(Comment, 'Comment')
        acl.register(DocumentTemplate, 'DocumentTemplate')

        // Roles
        ROLES.forEach(role => acl.rule(role.key, user => user && user.aclRoles.includes(role.key)))

        // Features
        FEATURES.forEach(feature => acl.rule(feature.key, user => user && user.aclFeatures.includes(feature.key)))

        // System settings access
        acl.rule('hasSystemSettingsAccess', user => {
            if (user) {
                const permissions = [
                    'User:manage',
                    'ConsultingSettings:create',
                    'Product:manage',
                    'ProductProviderSettings:create',
                    'ProductTemplate:manage',
                    'CorrespondenceSalutation:manage',
                    'Accounting:manage',
                    'Commissions:manage',
                ]
                return permissions.some(permission => user.aclPermissions.includes(permission))
            }
            return false
        })

        // Permissions
        const PERMISSION_SETS = [
            ACCOUNTING_PERMISSIONS,
            COMMENT_PERMISSIONS,
            COMMISSION_PERMISSIONS,
            CONTACT_PERMISSIONS,
            CONTRACT_PERMISSIONS,
            CORRESPONDENCE_PERMISSIONS,
            DOCUMENT_PERMISSIONS,
            FILE_PERMISSIONS,
            PRODUCT_PERMISSIONS,
            TASK_PERMISSIONS,
            USER_PERMISSIONS,
            ECO_HUB_PERMISSIONS,
            DXP_PERMISSIONS,
            MAIL_PROCESSING_PERMISSIONS,
        ]
        PERMISSION_SETS.forEach(PERMISSION_SET => PERMISSION_SET.forEach(permission => acl.rule(permission.key, user => user && user.aclPermissions.includes(permission.key))))

        // Policies
        acl.policy(ContactPolicy, 'Contact')
        acl.policy(ContactContactNumberPolicy, 'Contact.contactNumber')
        acl.policy(ContactConsultantsPolicy, 'Contact.consultants')
        acl.policy(CRUDPolicy, 'ConsultingSettings')
        acl.policy(CRUDPolicy, 'ProductProviderSettings')
        acl.policy(CRUDPolicy, 'Address')
        acl.policy(CRUDPolicy, 'EmailAddress')
        acl.policy(CRUDPolicy, 'PhoneNumber')
        acl.policy(CommentPolicy, 'Comment')
        acl.policy(DocumentTemplatePolicy, 'DocumentTemplate')
        acl.policy(DocumentLayoutPolicy, 'DocumentLayout')
        acl.policy(PostingPolicy, 'Posting')
    },
    { router }
)

Vue.config.productionTip = false
Vue.config.optionMergeStrategies.validations = Vue.config.optionMergeStrategies.data // TODO: Check if writing an own mergeStrategy makes more sense…; if yes, check vue source code of Vue.config.optionMergeStrategies.data as inspiration and check https://github.com/vuelidate/vuelidate/issues/308#issuecomment-391972618
Vue.component('loading-indicator', LoadingIndicator)
Vue.component('page-loading-indicator',PageLoadingIndicator)
Vue.component('page-wrapper', PageWrapper)
Vue.component('page-header', PageHeader)
Vue.component('in-page-footer', InPageFooter)
Vue.component('illustration', Illustration)
Vue.component('illustration-heading', IllustrationHeading)
Vue.component('base-button', BaseButton)
Vue.component('info-box', InfoBox)
Vue.component('info-icon', InfoIcon)
Vue.component('skeleton', Skeleton)
Vue.component('card', Card)
Vue.component('grid-card', GridCard)

// FormBuilder
Vue.component('form-builder-next', FormBuilder)

// Dev Only Components (TODO: Remove when not needed anymore or find a solution to not include these in the production build.)
Vue.component('dev-header', DevHeader)

const initializeApp = function () {
    new Vue({
        router,
        store,
        i18n,
        render: h => h(App),
    }).$mount('#app')
}

// Get user object before initializing the application if user is logged in
if (Cookies.has('jwt_header_payload')) {
    User.objects.me().then(async (user) => {
        // Use this to simulate the UI with different permission. Note: This does not influence the API in any way (sending data, accepting params).
        // Don't forget to comment out the according statements in src/apollo/index.js on line 62.
        // user.setAclFeatures('Feature:correspondence:core', 'Feature:tasks:core') // Set specific features.
        // user.removeAclFeatures('Feature:accounting:core') // Remove listed features.
        // user.setAclRoles('administrator') // Set specific roles.
        // user.removeAclRoles('administrator') // Remove listed roles.
        // user.setAclPermissions('Commissions:manage') // Set specific permissions.
        // user.removeAclPermissions('Accounting:manage') // Remove listed permissions.

        await saveUserToStoreAndLoadInitialAppData(user)
        loadEcoHubAndDxpBaseData()
        initializeApp()
    })
} else {
    initializeApp()
}
