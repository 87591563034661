import gql from 'graphql-tag'

export const DXP_FILE_LIST_QUERY = gql`
    query dxpFileList(
        $search: DxpFileListInputSearch!
    ) {
        dxpFileList(
            search: $search
        ) {
            files {
                id
                name
                mime_type
                documentType {
                    identifier
                    version
                }
                acknowledged
                created_at
            }
            is_truncated
        }
    }
`
