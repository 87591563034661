<template>
    <q-card
        v-bind="$attrs"
        :class="['card', `card-type-${kebabCase(type) || 'default'}`, { 'is-loading': loading, 'blurred': blurred || loadingBlur && loading, 'full-height': fullHeight, 'container-type-normal': containerTypeNormal, 'cover-image-before-header': coverImageBeforeHeader }]"
        :style="[
            {
                minHeight: minHeight,
                maxHeight: maxHeight,
                borderColor: borderColor,
                backgroundColor: backgroundColor,
            },
            cardStyles
        ]"
        bordered
    >
        <q-item
            v-if="$slots.header || heading || subheading || $slots.headerMenu"
            class="card-header"
            :style="[
                {
                    backgroundColor: headerBackgroundColor || backgroundColor,
                },
                headerStyles
            ]"
        >
            <q-item-section v-if="$slots.avatar" avatar>
                <slot name="avatar" />
            </q-item-section>

            <q-item-section v-if="heading || headingInfo || subheading">
                <component :is="`h${headingLevel}`" :class="['heading', headingClass, {'subheading-inline': subheadingInline}]">
                    <template v-if="!heading">
                        <skeleton />
                    </template>
                    <template v-else>{{ heading }}
                        <span v-if="subheading && subheadingInline" class="subheading additional-info">{{ subheading }}</span>
                        <info-icon
                            v-if="headingInfo"
                            :class="[{ 'q-ml-xs': subheading && subheadingInline }]"
                            :text="headingInfo"
                            :tooltip-max-width="headingInfoMaxWidth"
                        />
                    </template>
                </component>
                <p v-if="subheading && !subheadingInline" class="subheading additional-info">{{ subheading }}</p>
            </q-item-section>

            <q-item-section v-if="$slots.headerMenu" side class="self-start">
                <base-button
                    icon="mib-navigation-menu-horizontal"
                    class="action-button further-actions-menu q-ml-sm"
                    flat
                    round
                    text-color="primary"
                    size="sm"
                    data-test="btn:further-actions"
                >
                    <q-tooltip :delay="1000" :offset="[0, 10]">{{ $t('common.term.more') }}</q-tooltip>
                    <!-- TODO improvement: Refactor/improve menu so that actions can be passed in the same way as the actions on detail pages. -->
                    <q-menu anchor="bottom end" self="top end" auto-close>
                        <slot name="headerMenu">
                            <!--
                            <q-list dense>
                                <div v-for="(action, index) in headerMenu" :key="action.key">
                                    <q-item
                                        clickable
                                        @click="$emit('click', action)"
                                    >
                                        <q-item-section>{{ $tc(getTranslationKey(action.key), 1) }}</q-item-section>
                                    </q-item>
                                    <q-separator v-if="index < headerMenu.length - 1" />
                                </div>
                            </q-list>
                            -->
                        </slot>
                    </q-menu>
                </base-button>
            </q-item-section>
        </q-item>

        <q-card-section
            v-if="coverImage || $slots.coverImage"
            :class="['cover-image', { 'full-size': coverImageFullSize }]"
            :style="coverImageStyles"
        >
            <slot name="coverImage">
                <img :src="coverImage">
            </slot>
        </q-card-section>

        <q-card-section
            v-if="$slots.default"
            :class="['card-content', contentClass || null, { 'scrollable': contentScrollable, 'no-content-padding': noContentPadding }]"
            :style="[ contentStyles, { minHeight: contentMinHeight, maxHeight: contentMaxHeight } ]"
            :horizontal="horizontal"
        >
            <q-scroll-area
                v-if="contentScrollable"
                :thumb-style="{ width: '5px', right: '3px', borderRadius: '5px', backgroundColor: 'var(--color-border-tertiary)', opacity: 1 }"
                :bar-style="{ right: '1px', borderLeft: '1px solid var(--color-background-secondary)', backgroundColor: 'var(--color-background-primary)', width: '10px', opacity: 1 }"
                :visible="autoHideContentScrollbar ? null : true"
            >
                <slot name="default" />
            </q-scroll-area>
            <slot v-else name="default" />
        </q-card-section>

        <template>
            <q-card-actions
                :class="['card-footer', { 'is-empty': !$slots.actions, 'do-not-print': !printCardFooter }]"
                :vertical="verticalActions ? true : null"
                :align="alignActions"
            >
                <slot name="actions" />
            </q-card-actions>
        </template>

        <q-inner-loading
            :showing="loading"
            color="secondary"
            :label="loadingLabel"
        />
    </q-card>
</template>

<script>
import { kebabCase } from 'lodash'

export default {
    name: 'Card',
    props: {
        type: {
            type: String,
            default: '', // Options: 'primary', 'secondary', 'accent', 'positive', 'negative', 'warning', 'info'
        },

        cardStyles: {
            type: Object,
            default: () => null,
        },
        fullHeight: {
            type: Boolean,
            default: true,
        },
        minHeight: {
            type: String,
            default: '',
        },
        maxHeight: {
            type: String,
            default: '',
        },
        borderColor: {
            type: String,
            default: '',
        },
        backgroundColor: {
            type: String,
            default: '',
        },
        containerTypeNormal: {
            // Fallback for cases when forms with `in-page-footer` are used within cards.
            // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Container_Queries
            type: Boolean,
            default: false,
        },

        heading: {
            type: String,
            default: '',
        },
        headingLevel: {
            type: Number,
            default: 2,
        },
        headingInfo: {
            type: String,
            default: '',
        },
        headingInfoMaxWidth: {
            type: String,
            default: '22rem',
        },
        headingClass: {
            type: String,
            default: '',
        },
        headerBackgroundColor: {
            type: String,
            default: '',
        },

        subheading: {
            type: String,
            default: '',
        },
        subheadingInline: {
            type: Boolean,
            default: false,
        },

        headerStyles: {
            type: Object,
            default: () => null,
        },
        // headerMenu: {
        //     type: Object,
        //     default: () => null,
        // },

        coverImageStyles: {
            type: Object,
            default: () => null,
        },
        coverImage: {
            type: String,
            default: null,
        },
        coverImageFullSize: {
            type: Boolean,
            default: true,
        },
        coverImageBeforeHeader: {
            type: Boolean,
            default: false,
        },

        horizontal: {
            type: Boolean,
            default: false,
        },
        contentClass: {
            type: String,
            default: '',
        },
        contentStyles: {
            type: Object,
            default: () => null,
        },
        contentMinHeight: {
            type: String,
            default: '',
        },
        contentMaxHeight: {
            type: String,
            default: '',
        },
        noContentPadding: {
            type: Boolean,
            default: false,
        },
        contentScrollable: {
            type: Boolean,
            default: false,
        },
        autoHideContentScrollbar: {
            type: Boolean,
            default: false,
        },

        verticalActions: {
            type: Boolean,
            default: false,
        },
        alignActions: {
            type: String,
            default: 'left',
        },
        printCardFooter: {
            type: Boolean,
            default: false,
        },

        loading: {
            type: Boolean,
            default: false,
        },
        loadingLabel: {
            type: String,
            default: '',
        },
        loadingBlur: {
            type: Boolean,
            default: false,
        },

        blurred: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        kebabCase (...args) {
            return kebabCase(...args)
        },
    },
}
</script>

<style lang="scss" scoped>
$cardBorderColor: var(--color-border-primary);
$cardBorderStyle: 1px solid $cardBorderColor;
$primaryCardBorderColor: var(--q-color-primary-light);
$primaryCardBackgroundColor: var(--q-color-primary-lighter);

.card {
    container-type: inline-size; // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Container_Queries

    display: flex;
    flex: 1 1 100%;
    flex-flow: column wrap;
    justify-content: space-around;
    align-items: stretch;
    overflow: hidden;
    text-overflow: ellipsis;

    border-color: $cardBorderColor;
    border-radius: $sizeSpacingSm;

    // Different types/variations
    @each $variation in ("primary", "secondary", "accent", "positive", "negative", "warning", "info") {
        &-type-#{$variation} {
            // Change colors to match info box type
            --color-base-action: var(--q-color-#{$variation});
            --color-base-action-interaction: var(--q-color-#{$variation}-dark);
            --color-base-action-faded: var(--q-color-#{$variation}-light);
            --color-primary-action: var(--q-color-#{$variation});
            --color-primary-action-interaction: var(--q-color-#{$variation}-dark);
            --color-primary-action-faded: var(--q-color-#{$variation}-light);

            // Default styles
            border-color: var(--q-color-#{$variation}-light);
            background-color: var(--q-color-#{$variation}-lighter);

            // Adjust nested list styles
            ul:not(.diff) li::before {
                color: var(--q-color-#{$variation});
            }

            // Adjust styling of nested form controls
            ::v-deep {
                .q-checkbox__inner--falsy .q-checkbox__bg,
                .q-field__control {
                    //border: 1px solid var(--color-border-secondary);
                    border: 1px solid var(--q-color-#{$variation}-light);
                    background-color: var(--color-background-primary);
                }
            }
        }
    }

    &.container-type-normal {
        container-type: normal;
    }

    &:not(.q-card--flat) {
        box-shadow: $defaultBoxShadow;
    }

    // Set default `order` value so that the order of individual elements can easily be changed
    ::v-deep > .q-card__section,
    ::v-deep > .q-card__actions {
        order: 10;
    }

    // Ensure loading is on top
    .q-inner-loading {
        z-index: 100;
    }

    // Add blur filter (usually add in addition to loading prop)
    &.blurred {
        .cover-image,
        .card-header,
        .card-content,
        .card-footer {
            filter: blur(2px);
        }
    }
}

// TODO improvement @MTR: Refine styling. (Inverted text?)
.card-header {
    order: 1;
    min-height: auto;
    padding: $sizeSpacingSm $sizeSpacingMd ($sizeSpacingSm - 1px);
    //min-height: 74px; // TODO improvement @MTR: Check if this makes sense or if it looks weird if there should never be a subheading or the like.

    color: var(--q-color-secondary-lighter);

    .cover-image-before-header & {
        order: 2;
        //border-bottom: $cardBorderStyle;
        border-radius: 0;
    }
}

.heading {
    margin: $sizeSpacingXs 0;
    font-size: 1.15rem;
    line-height: $sizeLineHeightBase;
    letter-spacing: normal;
    color: var(--q-color-primary-darker);

    // Adjust position of (fake) required highlighting icon
    &.required:not(.subheading-inline) {
        &::after {
            margin-left: -0.15em;
        }
    }
}

.subheading {
    margin-bottom: 0;
    font-size: 1rem;
}

.cover-image {
    overflow: hidden;

    //border-bottom: $cardBorderStyle;

    img {
        display: block;
        max-width: 100%;
        border-radius: $sizeSpacingXs;
    }

    &.full-size {
        padding: 0;

        img {
            border-radius: 0;
        }
    }

    .cover-image-before-header & {
        order: 1;
    }
}

.card-content {
    flex: 1;
    max-width: 100%;
    align-content: flex-start;

    // Reduce spacing when used directly after a .card-header
    .card-header + & {
        padding-top: $sizeSpacingSm;
    }

    ::v-deep {
        p,
        ul,
        ol {
            &:last-child {
                margin-bottom: 0;
            }
        }

        .q-scrollarea {
            height: 100%;
        }
    }

    &.scrollable {
        padding: 0;

        ::v-deep {
            .q-scrollarea {
                height: 100%;
                padding: $sizeSpacingMd ($sizeSpacingMd + $sizeSpacingSm) $sizeSpacingMd $sizeSpacingMd;
            }
        }
    }

    &.no-content-padding {
        padding: 0;

        ::v-deep {
            .q-scrollarea {
                $sizeScrollbarWidth: 10px;

                padding: 0 $sizeScrollbarWidth 0 0;

                // Always show the scrollbar background (copy/placeholder) to avoid weird looking gaps if there is no actual scrollbar.
                // Should look the same as the `:bar-style` of the scroll area.
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 1px;
                    height: 100%;
                    width: $sizeScrollbarWidth;

                    border-left: 1px solid var(--color-background-secondary);
                    background-color: var(--color-background-primary);
                }
            }
        }
    }

    // Remove spacing if defined elements are the only child of a card
    ::v-deep {
        ul,
        ol,
        dl {
            &:only-child {
                margin-bottom: 0;
            }
        }
    }
}

.card-footer {
    margin-top: auto;
    padding: $sizeSpacingSm $sizeSpacingMd ($sizeSpacingSm - 1px);

    //border-top: $cardBorderStyle;
    //background: var(--color-background-primary);

    // Reset styles if footer does not have any content so that the layout does not break.
    // (Workaround for "flexbox issue" where the content is vertically centered if cards are higher than their content (`full-height`) and they only have one
    // child element due to our card styles with the fixed footer.)
    &.is-empty {
        padding: 0;
        height: 0;
        overflow: hidden;
        border: none;
    }
}
</style>
