<template>
    <div>
        <wizard-component
            :wizard="wizard"
            alternative-labels
            :vertical="$q.screen.lt.md"
        >
            <template v-slot:step-documentType>
                <div :class="['row', 'q-col-gutter-md', { 'justify-center': $q.screen.gt.sm }]">
                    <template v-if="hasEcoHubAndDxpBaseData">
                        <div class="col-xs-12 col-md-7 col-lg-6 col-xl-6">
                            <base-select
                                v-model="currentDocumentTypeIdentifier"
                                :options="documentTypeIdentifiers.map(documentTypeIdentifier => ({ value: documentTypeIdentifier, label: $tc(`common.data-exchange.process--entity-name.${kebabCase(documentTypeIdentifier)}`, 2) }))"
                            />
                        </div>
                        <div class="column">
                            <base-button
                                :label="$t('common.term.next')"
                                :disabled="!currentDocumentTypeIdentifier"
                                primary-button
                                @click="wizard.nextStep()"
                            />
                        </div>
                    </template>
                    <div v-else class="col-xs-12 col-md-8 col-lg-6 col-xl-6">
                        <!-- External DxpData (TechUser, InsurerList) not loaded yet. -->
                        <skeleton width="100%" height="40px" />
                    </div>
                </div>
            </template>

            <template v-slot:step-import>
                <form-builder-next
                    :form="form"
                    @submit="onSubmit"
                >
                    <template v-slot="{ formBuilder }">
                        <div class="row q-col-gutter-md">
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                                <form-builder-next :item="formBuilder.form.getItem('serviceProviderId')" @select="onServiceProviderSelect" />

                                <info-box v-if="currentServiceProvider && !isProcessEnabled" no-margin class="q-mt-sm">
                                    <p>{{ $tc('views.data-exchange.service-provider.data-exchange-process-integration--activated--false--provider', 1, { process: $tc(`common.data-exchange.process--entity-name.${kebabCase(currentDocumentTypeIdentifier)}`, 2), productProvider: currentServiceProvider.service_provider_display_name }) }}
                                        <info-icon :text="$t('views.data-exchange.service-provider.data-exchange-process-integration--hint-settings')" />
                                    </p>
                                </info-box>
                            </div>
                            <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                                <form-builder-next :item="formBuilder.form.getItem('from')" />
                            </div>
                            <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                                <form-builder-next :item="formBuilder.form.getItem('to')" />
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                <form-builder-next :item="formBuilder.form.getItem('onlyUnacknowledged')" />
                            </div>
                        </div>
                    </template>

                    <template v-slot:buttons="{ formBuilder }">
                        <base-button
                            v-if="documentTypeMode === DxpFileListDocumentTypeMode.SELECT"
                            :label="$t('common.term.back')"
                            class="q-mr-sm"
                            flat
                            @click="resetToDocumentTypeStep"
                        />
                        <base-button
                            data-test="btn:submit"
                            type="submit"
                            :label="$tc('views.data-exchange.list-document--entity', 2, { entity: $tc(`common.data-exchange.process--entity-name.${kebabCase(currentDocumentTypeIdentifier) || 'document'}`, 2) })"
                            :disable="formBuilder.formSubmitStatus || (currentServiceProvider && !isProcessEnabled)"
                            :loading="formBuilder.formSubmitStatus"
                            primary-button
                        />

                        <template v-if="!formBuilder.formSubmitStatus && files && !files.length">
                            <info-box type="warning" no-margin class="q-mt-lg">
                                <p>{{ $tc('common.notifications.data-exchange.get-items--found-n-items--entity', 0, { entity: $tc(`common.data-exchange.process--entity-name.${kebabCase(currentDocumentTypeIdentifier)}`, 2) }) }}</p>
                            </info-box>
                        </template>
                    </template>
                </form-builder-next>

                <div v-if="files && files.length" class="q-mt-lg">
                    <h2 class="text-h3">{{ $tc('common.file.file', 2) }}</h2>

                    <!-- eslint-disable vue/no-v-html -->
                    <info-box
                        v-if="createDxpInboxItemsError"
                        type="negative"
                        data-test="error:global-message"
                        v-html="createDxpInboxItemsError"
                    />
                    <!-- eslint-enable -->

                    <q-table
                        ref="filesTable"
                        :data="files"
                        :columns="filesTableColumns"
                        row-key="id"
                        :pagination.sync="pagination"
                        :rows-per-page-options="[10, 20, 50, 100]"
                        binary-state-sort
                        flat
                        square
                        dense
                        selection="multiple"
                        :selected.sync="selectedFiles"
                    />
                    <base-button
                        :label="$t('views.data-exchange.import-data')"
                        :disabled="selectedFiles.length === 0 || isCreatingDxpInboxItems"
                        @click="createDxpInboxItems"
                    />
                </div>
            </template>
        </wizard-component>
    </div>
</template>

<script>
import BaseSelect from '@/components/form/BaseSelect'
import { useI18n } from '@/composables/i18n'
import { useQuasar } from '@/composables/quasar'
import { useStore } from '@/composables/store'
import { DxpFileListDocumentTypeMode } from '@/enums'
import { DxpDocumentTypeIdentifier } from '@/enums/graphql'
import { loadEcoHubAndDxpBaseData } from '@/helpers/dxp'
import { extractErrorMessage } from '@/helpers/form'
import { UserSettings } from '@/helpers/user'
import { Wizard, WizardStep } from '@/libs/wizard'
import WizardComponent from '@/libs/wizard/components/WizardComponent'
import { DxpInboxItemService } from '@/services'
import { DxpFileListService } from '@/services/dxpFileList'
import { computed, onBeforeMount, ref, watch } from 'vue'
import { DxpFileListForm } from '@/forms/dxp/dxpFileList'
import { kebabCase } from 'lodash'
import { getContactName } from '@/helpers/contact'

export default {
    name: 'DxpFileList',
    components: { BaseSelect, WizardComponent },
    props: {
        importableDxpDocumentTypeIdentifiers: {
            type: Array,
            required: false,
            default: () => [DxpDocumentTypeIdentifier.BILLING, DxpDocumentTypeIdentifier.REMINDER],
        },
        dxpDocumentTypeIdentifier: {
            type: String,
            required: false,
            default: '',
        },
        documentTypeMode: {
            type: String,
            required: false,
            default: DxpFileListDocumentTypeMode.SELECT,
        },
        serviceProviderId: {
            type: String,
            required: false,
            default: null,
        },
    },
    methods: { getContactName, kebabCase },
    setup (props, context) {
        const $q = useQuasar()
        const { t, tc } = useI18n()
        const store = useStore()

        // EcoHubAndDxpBaseData
        const hasEcoHubAndDxpBaseData = ref(false)
        onBeforeMount(async () => {
            if (!store.state.ecoHubTechUser || !store.state.dxpInsurerListActiveAgreements) {
                await loadEcoHubAndDxpBaseData()
            }
            hasEcoHubAndDxpBaseData.value = true
        })

        // UserSettings
        const userSettings = new UserSettings(store.state.user)

        // DocumentTypeIdentifier
        const documentTypeIdentifiers = props.importableDxpDocumentTypeIdentifiers
        const currentDocumentTypeIdentifier = ref(props.dxpDocumentTypeIdentifier)

        // Wizard
        const wizardSteps = [new WizardStep('import', { title: t('common.data-exchange.data-import') })]
        const documentTypeCaption = computed(() => {
            return currentDocumentTypeIdentifier.value ? tc(`common.data-exchange.process--entity-name.${kebabCase(currentDocumentTypeIdentifier.value)}`, 2) : ''
        })
        if (props.documentTypeMode === DxpFileListDocumentTypeMode.SELECT) wizardSteps.unshift(new WizardStep('documentType', { title: tc('common.data-exchange.process--entity-name.dxp-process--document-type', 1), caption: documentTypeCaption }))
        const wizard = new Wizard(wizardSteps)
        function resetToDocumentTypeStep () {
            resetFilesValues()
            createDxpInboxItemsError.value = ''
            wizard.previousStep()
        }

        // Files
        const pagination = ref({
            sortBy: 'created_at',
            descending: false,
            page: 1,
        })
        pagination.value.rowsPerPage = parseInt(userSettings.getItem(`DxpFileList.pagination.rowsPerPage`)) || 50
        watch(() => pagination.value.rowsPerPage, (rowsPerPage) => {
            userSettings.setItem(`DxpFileList.pagination.rowsPerPage`, rowsPerPage)
        })
        const files = ref(null)
        const selectedFiles = ref([])
        const filesTableColumns = [
            {
                name: 'created_at',
                label: t('common.term.created-on'),
                field: row => row.created_at,
                align: 'left',
                sortable: true,
                required: true,
                classes: row => row.acknowledged ? null : 'text-bold',
            },
            {
                name: 'name',
                label: tc('common.file.file-name', 1),
                field: row => row.name,
                align: 'left',
                sortable: true,
                required: true,
                classes: row => row.acknowledged ? null : 'text-bold',
            },
            {
                name: 'acknowledged',
                label: t('common.term.acknowledged'),
                field: row => row.acknowledged ? t('common.term.yes') : t('common.term.no'),
                align: 'left',
                sortable: true,
                classes: row => row.acknowledged ? null : 'text-bold',
            },
        ]
        function resetFilesValues () {
            files.value = null
            selectedFiles.value = []
        }

        // Form
        const formComponent = ref(null)
        const form = new DxpFileListForm()
        if (props.serviceProviderId) form.getItem('serviceProviderId').setData(props.serviceProviderId)
        form.getItem('onlyUnacknowledged').setData(true)
        const currentServiceProvider = ref(null)
        const isProcessEnabled = computed(() => {
            return !!currentServiceProvider.value?.enabledProcesses.find(enabledProcess => enabledProcess.id === currentDocumentTypeIdentifier.value)
        })
        function onServiceProviderSelect(serviceProvider) {
            currentServiceProvider.value = serviceProvider
        }
        async function onSubmit(formData) {
            resetFilesValues()

            const search = {
                service_provider_id: formData.serviceProviderId,
                document_type_identifiers: [currentDocumentTypeIdentifier.value],
                only_unacknowledged: formData.onlyUnacknowledged,
            }
            if (formData.from) search.from = `${formData.from} 00:00:00`
            if (formData.to) search.to = `${formData.to} 23:59:59`

            const notification = $q.notify({
                group: false, // required to be updatable
                timeout: 0, // we want to be in control when it gets dismissed
                spinner: true,
                message: tc('common.notifications.data-exchange.get-items--entity--provider', 2, { entity: tc(`common.data-exchange.process--entity-name.${kebabCase(currentDocumentTypeIdentifier.value)}`, 2), provider: currentServiceProvider.value.service_provider_display_name }),
                caption: tc('common.notifications.data-exchange.get-items--info', 1),
                multiLine: true,
                attrs: { 'data-test': 'notification:documents-queried' },
            })

            const result = await DxpFileListService.get(search)
            files.value = result.files

            notification({
                type: 'positive',
                spinner: false, // we reset the spinner setting so the icon can be displayed
                timeout: 2000, // we will time out it in x
                attrs: { 'data-test': 'notification:documents-queried-success' },
            })

            formComponent.value.formBuilder.resetFormSubmitStatus()

            if (result?.files) {
                notification({
                    caption: tc('common.notifications.data-exchange.get-items--found-n-items--entity', result.files.length, { count: result.files.length, entity: tc(`common.data-exchange.process--entity-name.${kebabCase(currentDocumentTypeIdentifier.value)}`, result.files.length === 1 ? 1 : 2) }),
                })

                if (result.files.length === 0) {
                    notification({
                        type: 'warning',
                        timeout: 5000, // we will time out it in x
                    })
                }
            }
        }

        // Files
        const createDxpInboxItemsError = ref('')
        const isCreatingDxpInboxItems = ref(false)
        function createDxpInboxItems() {
            createDxpInboxItemsError.value = ''
            isCreatingDxpInboxItems.value = true

            DxpInboxItemService.create({
                serviceProviderId: formComponent.value.formBuilder.form.getItem('serviceProviderId').value,
                fileInfos: selectedFiles.value.map(fileInfo => {
                    return {
                        id: fileInfo.id,
                        name: fileInfo.name,
                        mime_type: fileInfo.mime_type,
                        documentType: {
                            identifier: fileInfo.documentType.identifier,
                            version: fileInfo.documentType.version,
                        },
                        acknowledged: fileInfo.acknowledged,
                        created_at: fileInfo.created_at,
                    }
                }),
            }).then(result => {
                context.emit('success')
            }).catch(error => {
                createDxpInboxItemsError.value = extractErrorMessage(error)
            }).finally(() => {
                isCreatingDxpInboxItems.value = false
            })
        }

        return {
            // Static
            DxpFileListDocumentTypeMode,

            // EcoHubAndDxpBaseData
            hasEcoHubAndDxpBaseData,

            // Document Type Identifier
            documentTypeIdentifiers,
            currentDocumentTypeIdentifier,

            // Wizard
            wizard,
            resetToDocumentTypeStep,

            // Form
            formComponent,
            form,
            currentServiceProvider,
            isProcessEnabled,
            onServiceProviderSelect,
            onSubmit,

            // Files
            pagination,
            files,
            selectedFiles,
            filesTableColumns,
            createDxpInboxItemsError,
            isCreatingDxpInboxItems,
            createDxpInboxItems,
        }
    },
}
</script>
