import gql from 'graphql-tag'

export const DXP_ITEMS_RETRY_MUTATION = gql`
    mutation dxpItemsRetry(
        $dxpItemIds: [ID!]!
    ) {
        dxpItemsRetry(
            dxp_item_ids: $dxpItemIds
        ) {
            ... on DxpInboxItem {
                id
                created_at
                updated_at
            }
            ... on DxpOutboxItem {
                id
                created_at
                updated_at
            }
        }
    }
`
