import { Form, FormField } from '@/libs/form'
import { isAfterOrEqualToDate, isBeforeOrEqualToDate } from '@/libs/form/validators'
import store from '@/store/store'
import { useI18n } from '@/composables/i18n'

export class DxpFileListForm extends Form {
    compose () {
        const { tc } = useI18n()

        this.addField(new FormField('serviceProviderId', {
            label: tc('common.data-exchange.service-provider', 1),
            type: 'dxpServiceProvider',
            validators: [
                { type: 'required' },
            ],
            attributes: {
                'filter-fn': options => {
                    if (store.state.dxpInsurerListActiveAgreements) {
                        return options.filter(option => {
                            return store.state.dxpInsurerListActiveAgreements.download_permissions_granted.includes(option.service_provider_id) && store.state.dxpInsurerListActiveAgreements.upload_permissions_granted.includes(option.service_provider_id)
                        })
                    } else {
                        return options
                    }
                },
            },
        }))

        this.addField(new FormField('onlyUnacknowledged', {
            label: 'common.data-exchange.only-unacknowledged',
            type: 'checkbox',
            validators: [
                { type: 'required' },
            ],
        }))

        this.addField(new FormField('from', {
            label: 'common.term.date-date-from',
            type: 'calendar',
            validators: [
                { type: 'date' },
                {
                    type: 'isBeforeOrEqualToDate',
                    validator: () => {
                        return isBeforeOrEqualToDate(this.getItem('from').value, this.getItem('to').value)
                    },
                    params: {
                        targetLabel: 'common.term.date-date-until',
                    },
                },
            ],
        }))

        this.addField(new FormField('to', {
            label: 'common.term.date-date-until',
            type: 'calendar',
            validators: [
                { type: 'date' },
                {
                    type: 'isAfterOrEqualToDate',
                    validator: () => {
                        return isAfterOrEqualToDate(this.getItem('to').value, this.getItem('from').value)
                    },
                    params: {
                        targetLabel: 'common.term.date-date-from',
                    },
                },
            ],
        }))
    }
}
