<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('views.dev.tools.tools', 1)" :to="{ name: 'dev-tools' }" />
                <q-breadcrumbs-el :label="$t('views.dev.tools.mime-parser.mime-parser')" :to="{ name: 'dev-tools-mime-parser' }" />
            </template>

            <h1>{{ $t('views.dev.tools.mime-parser.mime-parser') }}</h1>
        </page-header>

        <info-box type="warning">
            <p>{{ $t('views.dev.tools.mime-parser.mime-parser-warning') }}</p>
        </info-box>

        <form-builder-next
            :form="form"
            hide-default-buttons
        />

        <q-card v-if="parsedMail?.body?.html" flat bordered>
            <q-item class="q-pt-md q-pb-md">
                <q-item-section avatar>
                    <q-avatar
                        class="sender-avatar"
                        color="background-secondary"
                        size="3.5em"
                        :style="`color: ${stringToColor(parsedMail?.from?.name)};`"
                    >
                        {{ parsedMail?.from?.name?.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '').substring(0, 3) }}
                    </q-avatar>
                </q-item-section>

                <q-item-section>
                    <q-item-label>
                        <span class="additional-info">{{ $t('common.term.to') }}:</span><template v-if="parsedMail.to[0]?.name || parsedMail.to[0]?.email"> {{ parsedMail.to[0]?.name || parsedMail.to[0]?.email }} <span v-if="parsedMail.to[0]?.email && parsedMail.to[0]?.name" class="additional-info">&lt;{{ parsedMail.to[0]?.email }}&gt;</span></template>
                    </q-item-label>
                    <q-item-label>
                        <span class="additional-info">{{ $t('common.term.from') }}:</span><template v-if="parsedMail.from?.name || parsedMail['reply-to']?.email"> {{ parsedMail.from?.name || parsedMail['reply-to']?.email }} <span v-if="parsedMail['reply-to']?.email || parsedMail['from']?.email" class="additional-info">&lt;{{ parsedMail['reply-to']?.email || parsedMail['from']?.email }}&gt;</span></template>
                    </q-item-label>
                    <q-item-label>
                        <span class="additional-info">{{ $tc('common.correspondence.email.subject', 1) }}:</span> <template v-if="parsedMail?.subject"> <span>{{ parsedMail.subject || `${$t('common.term.loading')} …` }}</span></template>
                    </q-item-label>
                </q-item-section>
            </q-item>

            <q-separator />

            <div class="row q-col-gutter-sm q-pa-lg">
                <div class="col-xs-12">
                    <div v-if="parsedMail?.body?.html" v-html="DOMPurify.sanitize(parsedMail?.body?.html)" />
                    <loading-indicator v-else auto-height />
                </div>
            </div>

            <q-separator />

            <div v-if="parsedMail?.attachments?.length" class="row q-col-gutter-sm q-pl-sm">
                <div :class="['col-xs-12 q-pt-md q-pr-lg q-pl-lg bg-background-secondary', { 'q-pb-lg': parsedMail.attachments.length }]">
                    <p :class="{ 'additional-info': !parsedMail.attachments.length }">{{ $tc('common.attachment.number-of-attachments', parsedMail.attachments.length) }}<!--<template v-if="parsedMail.attachments.length"><span class="additional-info">({{ humanStorageSize(attachmentsTotalSize) }})</span>:</template>--></p>

                    <!-- TODO improvement @MTR: Create file library component. -->
                    <!-- TODO @MTR with @TFU: Check why getFileTypeIcon() is called twice? -->
                    <div v-if="parsedMail?.attachments?.length" class="file-library-wrapper">
                        <q-card
                            v-for="attachment in parsedMail.attachments"
                            :key="attachment.id"
                            class="file-library-item"
                            square
                            flat
                            bordered
                        >
                            <q-card-section horizontal>
                                <q-item class="items-start">
                                    <q-item-section side>
                                        <q-avatar square>
                                            <q-icon
                                                v-if="attachment.contentType"
                                                :name="getFileTypeIcon(attachment.contentType)"
                                                size="sm"
                                                color="secondary"
                                            />
                                        </q-avatar>
                                    </q-item-section>

                                    <q-item-section>
                                        <q-item-label>{{ attachment.fileName }}</q-item-label>
                                        <q-item-label v-if="attachment.size" caption>{{ humanStorageSize(attachment.size) }}</q-item-label>
                                    </q-item-section>
                                </q-item>

                                <q-card-actions vertical>
                                    <q-btn
                                        color="primary"
                                        size="sm"
                                        flat
                                        round
                                        icon="mib-cloud-download"
                                        class="do-not-print"
                                        @click="downloadAttachment(attachment)"
                                    >
                                        <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.term.download-item', 1, { item: attachment.fileName }) }}</q-tooltip>
                                    </q-btn>
                                </q-card-actions>
                            </q-card-section>
                        </q-card>
                    </div>
                </div>
            </div>
        </q-card>
    </page-wrapper>
</template>

<script>
import { parseMail } from '@protontech/jsmimeparser';
import DOMPurify from 'dompurify'
import { Form, FormField } from '@/libs/form'
import { computed } from 'vue'
import { getFileTypeIcon, humanStorageSize } from '@/helpers/file'
import { stringToColor } from '@/helpers'
import { useI18n } from '@/composables/i18n'
import { saveAs } from 'file-saver'

export default {
    name: 'DevToolsMimeParser',
    meta () {
        return {
            title: `${this.$tc('views.dev.tools.tools', 1)}: ${this.$t('views.dev.tools.mime-parser.mime-parser')}`,
        }
    },
    components: {},
    setup () {
        const { tc } = useI18n()

        // Form
        const form = new Form()
        form.addField(new FormField('mime', {
            label: tc('views.dev.tools.mime-parser.mime-input', 1),
            type: 'textarea',
        }))

        // Preview
        const parsedMail = computed(() => {
            return parseMail(form.getItem('mime').value)
        })
        function downloadAttachment(attachment) {
            saveAs(new Blob([attachment.content], { type: attachment.contentType }), attachment.fileName)
        }


        return {
            DOMPurify,

            // Functions
            getFileTypeIcon,
            humanStorageSize,
            stringToColor,

            // Form
            form,

            // Preview
            parsedMail,
            downloadAttachment,
        }
    },
}
</script>
