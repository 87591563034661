// TODO @TFU: Verify Model (especially retry, dismiss, etc.)
import store from '@/store/store'
import { computed, unref } from 'vue'
import { Model } from '@/models'
import { DxpItemActions } from '@/enums'
import { DxpItemStatus, DxpItemType, QueueItemStatus } from '@/enums/graphql'

export class DxpOutboxItem extends Model {
    /**
     * Create a DxpOutboxItem model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'trace_id',
            'status',
            'service_provider_id',
            'igb2b_insurer_code',
            'fileInfo',
            'document_type_identifier',
            'related_inbox_item_document_type_identifier',
            'file',
            'targetObject',
            'currentQueueItem',
            'queueItems',
        ], data)
        this.type = DxpItemType.OUTBOX
        this.isInboxItem = false
        this.isOutboxItem = true

        // Retry
        this.isEligibleForRetry = computed( () => {
            return [DxpItemStatus.DXP_ERROR, DxpItemStatus.ON_HOLD].includes(this.status) && [QueueItemStatus.FAILED].includes(this.currentQueueItem.status)
        })

        // Dismiss
        this.isEligibleForDismiss = computed(() => {
            return [DxpItemStatus.PENDING, DxpItemStatus.DXP_ERROR, DxpItemStatus.ON_HOLD, DxpItemStatus.UNPROCESSABLE].includes(this.status) && [QueueItemStatus.QUEUED, QueueItemStatus.FAILED].includes(this.currentQueueItem.status)
        })

        // Aggregated status
        this.aggregatedStatus = computed(() => {
            if (this.status === DxpItemStatus.ON_HOLD && ['invalid_xml_file', 'unknown'].includes(this.currentQueueItem?.status_message?.code)) {
                return this.currentQueueItem?.status
            }

            switch (this.status) {
                case DxpItemStatus.PENDING:
                    return this.currentQueueItem?.status
                case DxpItemStatus.ON_HOLD:
                    return this.targetObject?.nextStep || this.status
                default:
                    return this.status
            }
        })
    }

    static action = DxpItemActions
    static type = DxpItemType

    get actions () {
        const actions = []

        // Retry
        if (unref(this.isEligibleForRetry)) {
            actions.push({
                key: DxpItemActions.RETRY,
                item: this,
            })
        }

        // Dismiss
        if (unref(this.isEligibleForDismiss)) {
            actions.push({
                key: DxpItemActions.DISMISS,
                item: this,
            })
        }

        return actions
    }

    get nextStep () {
        if ([DxpItemStatus.DONE, DxpItemStatus.DISMISSED].includes(this.status)) return null
        return this.targetObject?.nextStep
    }

    get service_provider_display_name() {
        return store.state.dxpServiceProviderConfigurationDisplayNames[this.service_provider_id] ?? ''
    }
}
