<template>
    <fake-select
        v-if="!options.length"
        v-bind="$attrs"
    />
    <base-select-filter
        v-else

        :value="value"
        v-bind="$attrs"
        :options="options"

        option-value="id"
        @input="inputValue => $emit('input', inputValue)"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    </base-select-filter>
</template>

<script>
import { ContactType, ProductProviderSettingsStatus } from '@/enums/graphql'
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { Contact } from '@/models/contact'
import FakeSelect from '@/components/FakeSelect.vue'

export default {
    name: 'ProductProviderSelect',
    components: {
        FakeSelect,
        BaseSelectFilter,
    },
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
        filter: {
            type: Object,
        },
        filterFn: {
            type: Function,
            default: null,
        },
    },
    data () {
        return {
            options: [],
        }
    },
    watch: {
        'value' (id) {
            const option = this.options.find(option => option.id === id)
            this.$emit('select', option ?? null)
        },
    },
    created () {
        const variables = {
            hasProductProviderSettings: true,
            productProviderSettingsStatus: [ProductProviderSettingsStatus.ACTIVE],
        }
        if (this.filter) Object.assign(variables, this.filter)

        Contact.objects.all(variables).then(response => {
            const options = this.filterFn ? this.filterFn(response.data) : response.data
            this.options = options.map(contact => {
                switch (contact.type) {
                    case ContactType.PERSON:
                        contact.label = `${contact.first_name} ${contact.last_name} (${contact.contactNumber.number})`
                        break
                    case ContactType.COMPANY:
                        contact.label = `${contact.company_name} (${contact.contactNumber.number})`
                        break
                }
                return contact
            })
        })
    },
}
</script>
