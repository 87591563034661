var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.state.loading)?_c('page-loading-indicator',{attrs:{"number-of-breadcrumb-elements":2,"actions":"","number-of-next-actions":1}}):_c('page-wrapper',[_c('page-header',{scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(){return [_c('q-breadcrumbs-el',{attrs:{"label":_vm.$t('common.eco-hub.eco-hub'),"to":{ name: 'admin-eco-hub' },"data-test":"breadcrumb:admin-eco-hub"}}),_c('q-breadcrumbs-el',{attrs:{"label":_vm.$tc('views.admin.eco-hub.tech-user.tech-user', 1),"to":{ name: 'eco-hub-tech-user-detail' },"data-test":"breadcrumb:eco-hub-tech-user-detail"}})]},proxy:true},(_vm.state.ecoHubTechUser)?{key:"actions",fn:function(){return [_c('actions',{attrs:{"actions":_vm.state.ecoHubTechUser.actions},on:{"click":_vm.handleActions}}),_c('form-dialog',{ref:"deleteDialog",attrs:{"double-confirm":""},on:{"submit":_vm.handleDelete},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$tc('views.admin.eco-hub.tech-user.delete-tech-user', 1)))]},proxy:true},{key:"default",fn:function(){return [_c('i18n',{attrs:{"path":"views.admin.eco-hub.tech-user.delete-tech-user--confirm--temp-component-interpolation-count","tag":"p"},scopedSlots:_vm._u([(_vm.state.ecoHubTechUser)?{key:"techUserId",fn:function(){return [_c('b',[_vm._v(_vm._s(_vm.state.ecoHubTechUser.idp_user_id))])]},proxy:true}:null],null,true)})]},proxy:true}],null,false,2099581634)})]},proxy:true}:null],null,true)},[_c('h1',[_vm._v(_vm._s(_vm.$tc('views.admin.eco-hub.tech-user.tech-user', 1)))])]),_c('div',{staticClass:"row print-row-lg"},[(_vm.state.ecoHubTechUser)?_c('grid-card',{attrs:{"columns":{
                'xs': 12,
                'sm': 12,
                'md': 8,
                'lg': 6,
                'xl': 6,
            },"heading":_vm.$tc('views.admin.eco-hub.tech-user.enrolled-tech-user', 1),"heading-info":_vm.$t('views.admin.eco-hub.tech-user.tech-user--info--short')}},[_c('p',[_vm._v("ID: "),_c('span',{staticClass:"monospace text-bold"},[_vm._v(_vm._s(_vm.state.ecoHubTechUser.idp_user_id))])])]):_c('div',{staticClass:"col-xs-12 col-md-8 col-lg-6"},[_c('info-box',{scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('base-button',{attrs:{"label":_vm.$tc('views.admin.eco-hub.tech-user.enroll-tech-user', 1),"to":{ name: 'eco-hub-tech-user-create' },"primary-button":"","outline":""}})]},proxy:true}])},[_c('p',[_vm._v(_vm._s(_vm.$t('views.admin.eco-hub.tech-user.tech-user--info')))])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }