<template>
    <page-wrapper>
        <page-header>
            <h1>{{ $t('common.eco-hub.eco-hub') }}</h1>

            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$t('common.eco-hub.eco-hub')" :to="{ name: 'eco-hub' }" data-test="breadcrumb:eco-hub" />
            </template>
        </page-header>

        <info-box v-if="!store.state.ecoHubTechUser">{{ $tc('common.eco-hub.no-tech-user--info', 1) }}</info-box>
        <div class="row q-col-gutter-md">
            <div class="col-xs-12 col-md-3 col-lg-2 file-type-filter">
                <template v-if="documentTypes.length">
                    <in-page-navigation>
                        <q-route-tab
                            :to="{ name: 'eco-hub-data-exchange', params: { statusGroup } }"
                            :label="$tc('common.consulting-file.type.all', 1)"
                        />
                        <template v-for="(item) in documentTypes">
                            <q-route-tab
                                :key="`in-page-navigation-document-type-${item}`"
                                :to="{ name: 'eco-hub-data-exchange', params: { statusGroup, documentType: item } }"
                            >
                                <info-icon
                                    :name="`mib-${['error-technical', 'error-business'].includes(item) ? 'upload' : 'download'}-bottom`"
                                    :text="$tc(`common.data-exchange.${['error-technical', 'error-business'].includes(item) ? 'outbox' : 'inbox'}-item--entity-name--info`, 2, { entity: $tc(`common.data-exchange.process--entity-name.${kebabCase(item)}`, 2) })"
                                    class="q-mr-xs"
                                    anchor="center left"
                                    self="center right"
                                    tooltip-max-width="18rem"
                                    :delay="1000"
                                />
                                {{ $tc(`common.data-exchange.process--entity-name.${kebabCase(item)}`, 2) }}
                            </q-route-tab>
                        </template>
                    </in-page-navigation>
                </template>
            </div>

            <div class="col-xs-12 col-md-9 col-lg-10 file-list">
                <base-table
                    ref="table"
                    table-class="expandable-rows"
                    :columns="columns"
                    :fetch-objects-fn="DxpItemService.all"
                    :fetch-objects-abort-previous-request="true"
                    :additional-filters="additionalFilters"
                    user-settings-base-path="DxpItems"
                    pagination-sort-by-default-key="created_at"
                    pagination-initial-order-direction-descending
                    :show-filter="false"
                    show-refresh-button
                    no-top-padding
                    selection="multiple"
                    :selected.sync="selectedRows"
                    :expanded.sync="expandedRows"
                >
                    <template v-slot:tabs>
                        <main-tabs>
                            <q-tabs
                                dense
                                no-caps
                                inline-label
                                align="left"
                                class="q-mr-sm bg-background-secondary"
                                indicator-color="accent"
                            >
                                <q-route-tab :to="{ name: 'eco-hub-data-exchange', params: { statusGroup: 'pending', documentType: documentType || undefined } }" :label="$tc('views.data-exchange.filter-label--pending', 1)" />
                                <q-route-tab :to="{ name: 'eco-hub-data-exchange', params: { statusGroup: 'processing', documentType: documentType || undefined } }" :label="$tc('views.data-exchange.filter-label--processing', 1)" />
                                <q-route-tab :to="{ name: 'eco-hub-data-exchange', params: { statusGroup: 'done', documentType: documentType || undefined } }" :label="$tc('views.data-exchange.filter-label--done', 1)" />
                            </q-tabs>
                        </main-tabs>
                    </template>

                    <template v-slot:quickfilters>
                        <div class="q-gutter-x-sm">
                            <base-button
                                class="do-not-print"
                                :label="$t('common.term.toggle--expand-all')"
                                flat
                                @click="expandAll"
                            />
                            <base-button
                                class="do-not-print"
                                :label="$t('common.term.toggle--collapse-all')"
                                flat
                                @click="collapseAll"
                            />
                        </div>

                        <q-checkbox
                            v-model="showTechDetails"
                            :label="$tc('common.term.toggle--show-tech-details', 2)"
                            size="sm"
                            data-test="checkbox:tech-details"
                        />
                    </template>

                    <template v-slot:header-cell-created_at="slotProps">
                        <q-th :props="slotProps">
                            {{ $tc('common.term.created-on', 1) }}
                            <info-icon :text="$tc('views.data-exchange.created-on--info', 1)" />
                        </q-th>
                    </template>

                    <template v-slot:header-cell-trace_id="slotProps">
                        <q-th :props="slotProps">
                            {{ $tc('common.data-exchange.trace-id', 1) }}
                            <info-icon :text="$tc('common.data-exchange.trace-id--info', 1)" />
                        </q-th>
                    </template>

                    <template v-slot:body="slotProps">
                        <q-tr
                            :props="slotProps"
                            :class="['expanded-row-parent cursor-pointer', { 'expanded': slotProps.expand }]"
                            :data-trace-id="slotProps.row.trace_id"
                            @dblclick="toggleExpansion(slotProps.key)"
                        >
                            <q-td :class="['checkbox-column', `item-status-${kebabCase(getItemStatus(slotProps.row))}`]">
                                <q-checkbox :value="slotProps.selected" dense @input="toggleSelected(slotProps.row)" />
                            </q-td>

                            <q-td
                                v-for="col in slotProps.cols.slice(0, -1)"
                                :key="col.name"
                                :props="slotProps"
                            >
                                <template v-if="col.name === 'status'">
                                    <status-badge
                                        :status="slotProps.row.aggregatedStatus"
                                        default-translation-base-path="common.status.dxp-item-status"
                                        :label="getItemStatusLabel(slotProps.row)"
                                        :loading="slotProps.row.status === DxpItemStatus.PENDING && slotProps.row.currentQueueItem.status !== QueueItemStatus.FAILED"
                                        :info-text="getItemStatusInfoText(slotProps.row)"
                                        :info-error-code="getItemStatusInfoErrorCode(slotProps.row)"
                                    />
                                </template>

                                <template v-else-if="col.name === 'trace_id'">
                                    <small v-if="col.value" :class="[{ 'monospace': col.name === 'trace_id' }]">{{ col.value }}</small>
                                    <span v-else class="additional-info">–</span>
                                </template>

                                <template v-else>{{ col.value }}</template>
                            </q-td>

                            <q-td
                                class="q-pr-xs text-right"
                                auto-width
                            >
                                <actions
                                    v-if="slotProps.row.actions.length"
                                    :actions="slotProps.row.actions"
                                    :number-of-next-best-actions="0"
                                    translation-base-key="common.actions.dxp-item"
                                    :translation-mapping="{ 'UPDATE-TARGET-OBJECT-MATCHED-CONTRACT': getUpdateTargetObjectMatchedContractLabel(slotProps.row) }"
                                    @click="handleActions"
                                />
                                <q-btn
                                    class="expand-button"
                                    size="sm"
                                    flat
                                    color="secondary"
                                    :ripple="false"
                                    :icon="slotProps.expand ? 'mib-arrow-down-1' : 'mib-arrow-left-1'"
                                    @click="toggleExpansion(slotProps.key)"
                                >
                                    <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc(`common.term.${slotProps.expand ? 'hide' : 'show'}-details`, 1) }}</q-tooltip>
                                </q-btn>
                            </q-td>
                        </q-tr>

                        <q-tr v-show="slotProps.expand" class="expanded-row" :props="slotProps">
                            <q-td :class="['expanded-row-content', `item-status-${kebabCase(getItemStatus(slotProps.row))}`]" colspan="100%">
                                <div class="row q-col-gutter-sm q-pt-xs q-pb-md">
                                    <!-- DxpInboxItems -->
                                    <template v-if="[DxpItemType.INBOX].includes(slotProps.row.type)">
                                        <grid-card
                                            :columns="{
                                                xs: 12,
                                                sm: 6,
                                                md: 6,
                                                lg: 6,
                                                xl: 6,
                                            }"
                                            :heading="$tc('common.data-exchange.data-import', 1)"
                                        >
                                            <!-- Billing, reminder -->
                                            <template v-if="[DxpDocumentTypeIdentifier.BILLING, DxpDocumentTypeIdentifier.REMINDER].includes(slotProps.row.document_type_identifier)">
                                                <dl>
                                                    <template v-if="slotProps.row.targetObject">
                                                        <dt>{{ $tc('common.term.customer', 1) }}: </dt>
                                                        <dd>
                                                            <template v-if="slotProps.row.targetObject.customer_last_name">{{ slotProps.row.targetObject.customer_last_name }}, </template>
                                                            <template v-if="slotProps.row.targetObject.customer_first_name">{{ slotProps.row.targetObject.customer_first_name }}</template>
                                                            <template v-if="slotProps.row.targetObject.customer_company_name">{{ slotProps.row.targetObject.customer_company_name }}</template>
                                                        </dd>
                                                        <br>

                                                        <dt>{{ $tc('common.contract.contract-number', 1) }}: </dt>
                                                        <dd>
                                                            <template v-if="slotProps.row.targetObject?.contract_number">{{ slotProps.row.targetObject.contract_number }}</template>
                                                            <span v-else class="additional-info">–</span>
                                                        </dd>
                                                        <br>
                                                    </template>

                                                    <dt>{{ $tc('common.data-exchange.service-provider', 1) }}: </dt>
                                                    <dd>{{ slotProps.row.service_provider_display_name }}</dd>
                                                    <br>

                                                    <div v-if="showTechDetails" class="q-mt-sm additional-info">
                                                        <dt>{{ $tc('common.term.type', 1) }}: </dt>
                                                        <dd>{{ $tc(`common.data-exchange.process--entity-name.${kebabCase(slotProps.row.document_type_identifier)}`, 1) }}</dd>
                                                        <br>

                                                        <dt>{{ $tc('common.file.file-name', 1) }}: </dt>
                                                        <dd class="display-inline monospace">{{ slotProps.row.fileInfo?.name }}<q-btn
                                                            class="q-ml-xs do-not-print"
                                                            tabindex="0"
                                                            data-test="button:copy-file-name"
                                                            icon="mib-copy-paste"
                                                            round
                                                            size="xs"
                                                            flat
                                                            color="primary"
                                                            @click="triggerCopyToClipboard(slotProps.row.fileInfo?.name)"
                                                            @keyup.enter="triggerCopyToClipboard(slotProps.row.fileInfo?.name)"
                                                        >
                                                            <q-tooltip
                                                                :delay="1000"
                                                                anchor="center right"
                                                                self="center left"
                                                                :offset="[10, 0]"
                                                            >{{ $tc('common.term.copy-something', 1, { something: $tc('common.file.file-name', 1) }) }}</q-tooltip>
                                                        </q-btn></dd>
                                                        <br>

                                                        <dt>{{ $tc('common.file.file-id', 1) }}: </dt>
                                                        <dd class="display-inline monospace">{{ slotProps.row.fileInfo?.id }}<q-btn
                                                            class="q-ml-xs do-not-print"
                                                            tabindex="0"
                                                            data-test="button:copy-file-id"
                                                            icon="mib-copy-paste"
                                                            round
                                                            size="xs"
                                                            flat
                                                            color="primary"
                                                            @click="triggerCopyToClipboard(slotProps.row.fileInfo?.id)"
                                                            @keyup.enter="triggerCopyToClipboard(slotProps.row.fileInfo?.id)"
                                                        >
                                                            <q-tooltip
                                                                :delay="1000"
                                                                anchor="center right"
                                                                self="center left"
                                                                :offset="[10, 0]"
                                                            >{{ $tc('common.term.copy-something', 1, { something: $tc('common.file.file-id', 1) }) }}</q-tooltip>
                                                        </q-btn></dd>
                                                        <br>

                                                        <dt>{{ $tc('common.term.provided-on', 1) }}: </dt>
                                                        <dd>{{ slotProps.row.fileInfo?.formattedCreatedAt }} <info-icon
                                                            :text="$tc('views.data-exchange.provided-on--info--entity-name', 1, { entity: $tc(`common.data-exchange.process--entity-name.the.${kebabCase(slotProps.row.document_type_identifier)}`, 1) })"
                                                        /></dd>
                                                        <br>

                                                        <dt>{{ $tc('common.data-exchange.process.dxp-process', 1) }}: </dt>
                                                        <dd>{{ slotProps.row.fileInfo?.documentType?.identifier }} {{ slotProps.row.fileInfo?.documentType?.version.split('_').slice(1).join('.') }}</dd>
                                                        <br>

                                                        <dt>{{ $tc('common.data-exchange.trace-id', 1) }}: </dt>
                                                        <dd class="display-inline monospace">{{ slotProps.row.trace_id }}<q-btn
                                                            class="q-ml-xs do-not-print"
                                                            tabindex="0"
                                                            data-test="button:copy-trace-id"
                                                            icon="mib-copy-paste"
                                                            round
                                                            size="xs"
                                                            flat
                                                            color="primary"
                                                            @click="triggerCopyToClipboard(slotProps.row.trace_id)"
                                                            @keyup.enter="triggerCopyToClipboard(slotProps.row.trace_id)"
                                                        >
                                                            <q-tooltip
                                                                :delay="1000"
                                                                anchor="center right"
                                                                self="center left"
                                                                :offset="[10, 0]"
                                                            >{{ $tc('common.term.copy-something', 1, { something: $tc('common.data-exchange.trace-id', 1) }) }}</q-tooltip>
                                                        </q-btn></dd>
                                                        <br>
                                                    </div>
                                                </dl>

                                                <info-box
                                                    v-if="getItemContext(slotProps.row) === 'file'"
                                                    :type="getItemInfoType(slotProps.row)"
                                                    no-margin
                                                >
                                                    <p><strong>{{ getItemStatusLabel(slotProps.row) }}</strong></p>
                                                    <p v-if="!!getItemInfoText(slotProps.row)">{{ getItemInfoText(slotProps.row) }}</p>
                                                    <p v-if="!!getRetryInfoText(slotProps.row)" class="additional-info">{{ getRetryInfoText(slotProps.row) }}</p>

                                                    <template v-slot:action>
                                                        <base-button
                                                            v-if="slotProps.row.isEligibleForDismiss"
                                                            :label="$t('common.term.dismiss')"
                                                            :outline="slotProps.row.isEligibleForRetry ? true : null"
                                                            primary-button
                                                            @click="triggerDismiss(slotProps.row)"
                                                        />
                                                        <base-button
                                                            v-if="slotProps.row.isEligibleForRetry"
                                                            :label="$t('common.term.retry')"
                                                            class="q-ml-sm"
                                                            primary-button
                                                            @click="triggerRetry(slotProps.row)"
                                                        />
                                                    </template>
                                                </info-box>
                                            </template>
                                        </grid-card>

                                        <grid-card
                                            :columns="{
                                                xs: 12,
                                                sm: 6,
                                                md: 6,
                                                lg: 6,
                                                xl: 6,
                                            }"
                                            :heading="$tc(`views.data-exchange.item-info.entity-info.${kebabCase(slotProps.row.document_type_identifier)}`, 1)"
                                            :subheading="`(${$tc('common.term.system', 1)})`"
                                            subheading-inline
                                            :loading="slotProps.row.status === DxpItemStatus.PENDING"
                                            loading-blur
                                        >
                                            <!-- Billing, reminder -->
                                            <template v-if="[DxpDocumentTypeIdentifier.BILLING, DxpDocumentTypeIdentifier.REMINDER].includes(slotProps.row.document_type_identifier)">
                                                <template v-if="slotProps.row.targetObject && slotProps.row.status !== DxpItemStatus.PENDING">
                                                    <dl>
                                                        <dt>{{ $tc('common.term.customer', 1) }}: </dt>
                                                        <dd :class="['matched-contract-customer-name', slotProps.row.status !== DxpItemStatus.DONE ? `${slotProps.row.targetObject.contractMatchStatus?.toLowerCase()}` : null, { 'additional-info': !slotProps.row.targetObject.matchedContract }]">
                                                            <router-link
                                                                v-if="slotProps.row.targetObject.matchedContract"
                                                                :to="{ name: 'contact-detail', params: { id: slotProps.row.targetObject.matchedContract.customer.id } }"
                                                            >{{ slotProps.row.targetObject.matchedContract.customer.getContactName({ commaSeparated: true }) }} <span class="additional-info">({{ slotProps.row.targetObject.matchedContract.customer.contactNumber.number }})</span></router-link>
                                                            <template v-else>–</template>
                                                        </dd>
                                                        <br>

                                                        <dt>{{ $tc('common.contract.contract-number', 1) }}: </dt>
                                                        <dd :class="['matched-contract-contract-number', slotProps.row.status !== DxpItemStatus.DONE ? `${slotProps.row.targetObject.contractMatchStatus?.toLowerCase()}` : null, { 'additional-info': !slotProps.row.targetObject.matchedContract }]">
                                                            <router-link
                                                                v-if="slotProps.row.targetObject.matchedContract"
                                                                :to="{ name: 'contract-detail', params: { contactId: slotProps.row.targetObject.matchedContract.customer.id, id: slotProps.row.targetObject.matchedContract.id } }"
                                                            >{{ slotProps.row.targetObject?.matchedContract?.currentContractNumber }}</router-link>
                                                            <template v-else>–</template>
                                                        </dd>
                                                        <br>

                                                        <dt>{{ $tc('common.product.product-provider', 1) }}: </dt>
                                                        <dd :class="['matched-contract-product-provider', { 'additional-info': !slotProps.row.targetObject.matchedContract }]">
                                                            <template v-if="slotProps.row.targetObject.matchedContract">{{ slotProps.row.targetObject.matchedContract?.currentContractInformation?.product.basicProvider.display_name || slotProps.row.targetObject.matchedContract?.currentContractInformation?.product.basicProvider.name }}</template>
                                                            <template v-else>–</template>
                                                        </dd>
                                                        <br>

                                                        <!-- BILLING (INVOICE) -->
                                                        <template v-if="slotProps.row.document_type_identifier === DxpDocumentTypeIdentifier.BILLING">
                                                            <br>
                                                            <dt>{{ $tc(`common.data-exchange.process--entity-name.${kebabCase(slotProps.row.document_type_identifier)}`, 1) }}: </dt>
                                                            <dd :class="['invoice-number', { 'additional-info': !slotProps.row.targetObject.formattedIdentifier }]">
                                                                <router-link
                                                                    v-if="slotProps.row.targetObject.matchedContract && slotProps.row.targetObject.formattedIdentifier"
                                                                    :to="{ name: 'premium-invoice-detail', params: { contactId: slotProps.row.targetObject.matchedContract.customer.id, contractId: slotProps.row.targetObject.matchedContract.id, premiumInvoiceId: slotProps.row.targetObject.id } }"
                                                                >{{ slotProps.row.targetObject.formattedIdentifier }}</router-link>
                                                                <template v-else>{{ slotProps.row.targetObject.formattedIdentifier || '–' }}</template>
                                                            </dd>
                                                            <br>

                                                            <dt>{{ $tc('common.premium-invoice.invoiced-amount', 1) }}: </dt>
                                                            <dd :class="['invoiced-amount', { 'additional-info': !slotProps.row.targetObject.totalPosition?.formattedInvoicedAmount }]">{{ slotProps.row.targetObject.totalPosition?.formattedInvoicedAmount || '–' }}</dd>
                                                            <br>

                                                            <dt>{{ $tc('common.premium-invoice.invoice-date', 1) }}: </dt>
                                                            <dd :class="['invoice-date', { 'additional-info': !slotProps.row.targetObject.formattedInvoiceDate }]">{{ slotProps.row.targetObject.formattedInvoiceDate || '–' }}</dd>
                                                        </template>

                                                        <!-- REMINDER -->
                                                        <template v-else-if="slotProps.row.document_type_identifier === DxpDocumentTypeIdentifier.REMINDER">
                                                            <br>
                                                            <dt>{{ $tc('common.data-exchange.process--entity-name.billing', 1) }}: </dt>
                                                            <dd :class="['invoice-number', { 'additional-info': !slotProps.row.targetObject.hasInvoiceIdentifier }]">{{ slotProps.row.targetObject.hasInvoiceIdentifier ? slotProps.row.targetObject.formattedInvoiceIdentifier : '–' }}</dd>
                                                            <br>

                                                            <dt>{{ $tc('common.premium-invoice.invoice-date', 1) }}: </dt>
                                                            <dd :class="['invoice-date', { 'additional-info': !slotProps.row.targetObject.formattedInvoiceDate }]">{{ slotProps.row.targetObject.formattedInvoiceDate || '–' }}</dd>
                                                            <br>

                                                            <dt>{{ $tc('common.premium-invoice.invoiced-amount', 1) }}: </dt>
                                                            <dd :class="['invoiced-amount', { 'additional-info': !slotProps.row.targetObject.totalPosition?.formattedInvoicedAmount }]">{{ slotProps.row.targetObject.totalPosition?.formattedInvoicedAmount || '–' }}</dd>
                                                            <br>

                                                            <br>
                                                            <dt>{{ $tc(`common.data-exchange.process--entity-name.${kebabCase(slotProps.row.document_type_identifier)}`, 1) }}: </dt>
                                                            <dd :class="['reminder-number', { 'additional-info': !slotProps.row.targetObject.formattedIdentifier }]">
                                                                <router-link
                                                                    v-if="slotProps.row.targetObject.matchedContract && slotProps.row.targetObject.formattedIdentifier"
                                                                    :to="{ name: 'premium-reminder-detail', params: { contactId: slotProps.row.targetObject.matchedContract.customer.id, contractId: slotProps.row.targetObject.matchedContract.id, premiumReminderId: slotProps.row.targetObject.id } }"
                                                                >{{ slotProps.row.targetObject.formattedIdentifier }}</router-link>
                                                                <template v-else>{{ slotProps.row.targetObject.formattedIdentifier || '–' }}</template>
                                                            </dd>
                                                            <br>

                                                            <dt>{{ $tc('common.premium-reminder.reminder-date', 1) }}: </dt>
                                                            <dd :class="['reminder-date', { 'additional-info': !slotProps.row.targetObject.formattedReminderDate }]">{{ slotProps.row.targetObject.formattedReminderDate || '–' }}</dd>
                                                            <br>

                                                            <dt>{{ $tc('common.premium-reminder.reminder-charge', 2) }}: </dt>
                                                            <dd :class="['invoiced-amount', { 'additional-info': !slotProps.row.targetObject.totalPosition?.formattedReminderChargesAmount }]">{{ slotProps.row.targetObject.totalPosition?.formattedReminderChargesAmount || '–' }}</dd>
                                                            <br>

                                                            <dt>{{ $tc('common.premium-reminder.outstanding-amount', 1) }}: </dt>
                                                            <dd :class="['reminder-date', { 'additional-info': !slotProps.row.targetObject.coverage_interruption_from }]">{{ slotProps.row.targetObject.formattedOutstandingInvoiceAmount || '–' }}</dd>
                                                            <br>
                                                        </template>
                                                    </dl>

                                                    <info-box
                                                        v-if="slotProps.row.targetObject.nextStep !== null && getItemContext(slotProps.row) === 'reference'"
                                                        :type="getItemInfoType(slotProps.row)"
                                                        class="q-mt-md"
                                                        no-margin
                                                    >
                                                        <p v-if="!!getItemContractMatchStatusInfoText(slotProps.row)"><strong>{{ getItemContractMatchStatusInfoText(slotProps.row) }}</strong></p>
                                                        <p v-if="!!getItemInfoText(slotProps.row)">{{ getItemInfoText(slotProps.row) }}</p>
                                                        <p v-if="!!getRetryInfoText(slotProps.row)" class="additional-info">{{ getRetryInfoText(slotProps.row) }}</p>

                                                        <template v-slot:action>
                                                            <base-button
                                                                v-if="slotProps.row.isEligibleForRetry"
                                                                :label="$t('common.term.retry')"
                                                                class="q-ml-sm"
                                                                primary-button
                                                                outline
                                                                @click="triggerRetry(slotProps.row)"
                                                            />

                                                            <base-button
                                                                v-if="slotProps.row.canUpdateTargetObjectMatchedContract"
                                                                :label="getItemStatusLabel(slotProps.row)"
                                                                primary-button
                                                                @click="dxpInboxItemTargetObjectMatchedContractUpdateOverlay.open({ dxpInboxItem: slotProps.row, mode: slotProps.row?.targetObject?.nextStep === DxpContractMatchNextStep.CONFIRM_SELECTED_CONTRACT ? DxpInboxItemTargetObjectMatchedContractUpdateOverlayMode.CONFIRM : DxpInboxItemTargetObjectMatchedContractUpdateOverlayMode.UPDATE })"
                                                            />
                                                        </template>
                                                    </info-box>
                                                </template>

                                                <template v-else>
                                                    <p class="additional-info">{{ $tc('common.term.information--available-information', 0) }}.</p>

                                                    <info-box
                                                        v-if="!!getItemInfoText(slotProps.row) && getItemContext(slotProps.row) === 'reference'"
                                                        :type="getItemInfoType(slotProps.row)"
                                                        class="q-mt-md"
                                                        no-margin
                                                    >
                                                        <p>{{ getItemInfoText(slotProps.row) }}</p>
                                                    </info-box>
                                                </template>
                                            </template>
                                        </grid-card>
                                    </template>
                                    <!-- / DxpInboxItems -->

                                    <!-- DxpOutboxItems -->
                                    <template v-if="[DxpItemType.OUTBOX].includes(slotProps.row.type)">
                                        <grid-card
                                            :columns="{
                                                xs: 12,
                                                sm: 6,
                                                md: 6,
                                                lg: 6,
                                                xl: 6,
                                            }"
                                            :heading="$tc(`common.data-exchange.process--entity-name.${kebabCase(slotProps.row.document_type_identifier)}`, 1)"
                                        >
                                            <!-- Technical errors -->
                                            <template v-if="[DxpDocumentTypeIdentifier.ERROR_TECHNICAL].includes(slotProps.row.document_type_identifier)">
                                                <i18n path="views.data-exchange.item-info.error.error-info--related-entity" tag="p">
                                                    <template v-slot:ofSomeEntity><b>{{ $tc(`common.data-exchange.process--entity-name.of-the.${kebabCase(slotProps.row.related_inbox_item_document_type_identifier)}`, 1) }}</b></template>
                                                    <template v-slot:traceId><span class="monospace"><b>{{ slotProps.row.trace_id }}</b></span></template>
                                                    <template v-slot:errorType>{{ $tc(`common.data-exchange.process--entity-name.some.${kebabCase(slotProps.row.document_type_identifier)}`, 1) }}</template>
                                                </i18n>

                                                <p v-if="!!getItemInfoText(slotProps.row) && getItemInfoType(slotProps.row) === 'info'">{{ getItemInfoText(slotProps.row) }}</p>

                                                <div v-if="showTechDetails" class="q-mt-sm additional-info">
                                                    <dl>
                                                        <dt>{{ $tc('common.term.type', 1) }}: </dt>
                                                        <dd>{{ $tc(`common.data-exchange.process--entity-name.${kebabCase(slotProps.row.document_type_identifier)}`, 1) }}</dd>
                                                        <br>

                                                        <dt>{{ $tc('common.file.file-name', 1) }}: </dt>
                                                        <dd class="display-inline monospace">{{ slotProps.row.fileInfo?.name }}<q-btn
                                                            class="q-ml-xs do-not-print"
                                                            tabindex="0"
                                                            data-test="button:copy-file-name"
                                                            icon="mib-copy-paste"
                                                            round
                                                            size="xs"
                                                            flat
                                                            color="primary"
                                                            @click="triggerCopyToClipboard(slotProps.row.fileInfo?.name)"
                                                            @keyup.enter="triggerCopyToClipboard(slotProps.row.fileInfo?.name)"
                                                        >
                                                            <q-tooltip
                                                                :delay="1000"
                                                                anchor="center right"
                                                                self="center left"
                                                                :offset="[10, 0]"
                                                            >{{ $tc('common.term.copy-something', 1, { something: $tc('common.file.file-name', 1) }) }}</q-tooltip>
                                                        </q-btn></dd>
                                                        <br>

                                                        <dt>{{ $tc('common.file.file-id', 1) }}: </dt>
                                                        <dd class="display-inline monospace">{{ slotProps.row.fileInfo?.id }}<q-btn
                                                            class="q-ml-xs do-not-print"
                                                            tabindex="0"
                                                            data-test="button:copy-file-id"
                                                            icon="mib-copy-paste"
                                                            round
                                                            size="xs"
                                                            flat
                                                            color="primary"
                                                            @click="triggerCopyToClipboard(slotProps.row.fileInfo?.id)"
                                                            @keyup.enter="triggerCopyToClipboard(slotProps.row.fileInfo?.id)"
                                                        >
                                                            <q-tooltip
                                                                :delay="1000"
                                                                anchor="center right"
                                                                self="center left"
                                                                :offset="[10, 0]"
                                                            >{{ $tc('common.term.copy-something', 1, { something: $tc('common.file.file-id', 1) }) }}</q-tooltip>
                                                        </q-btn></dd>
                                                        <br>

                                                        <dt>{{ $tc('common.data-exchange.process.dxp-process', 1) }}: </dt>
                                                        <dd>{{ slotProps.row.fileInfo?.documentType?.identifier }} {{ slotProps.row.fileInfo?.documentType?.version.split('_').slice(1).join('.') }}</dd>
                                                        <br>

                                                        <dt>{{ $tc('common.data-exchange.trace-id', 1) }}: </dt>
                                                        <dd class="display-inline monospace">{{ slotProps.row.trace_id }}<q-btn
                                                            class="q-ml-xs do-not-print"
                                                            tabindex="0"
                                                            data-test="button:copy-trace-id"
                                                            icon="mib-copy-paste"
                                                            round
                                                            size="xs"
                                                            flat
                                                            color="primary"
                                                            @click="triggerCopyToClipboard(slotProps.row.trace_id)"
                                                            @keyup.enter="triggerCopyToClipboard(slotProps.row.trace_id)"
                                                        >
                                                            <q-tooltip
                                                                :delay="1000"
                                                                anchor="center right"
                                                                self="center left"
                                                                :offset="[10, 0]"
                                                            >{{ $tc('common.term.copy-something', 1, { something: $tc('common.data-exchange.trace-id', 1) }) }}</q-tooltip>
                                                        </q-btn></dd>
                                                        <br>
                                                    </dl>
                                                </div>

                                                <info-box
                                                    v-if="getItemInfoType(slotProps.row) !== 'info'"
                                                    :type="getItemInfoType(slotProps.row)"
                                                    no-margin
                                                >
                                                    <p><strong>{{ getItemStatusLabel(slotProps.row) }}</strong></p>
                                                    <p v-if="!!getItemInfoText(slotProps.row)">{{ getItemInfoText(slotProps.row) }}</p>
                                                    <p v-if="!!getRetryInfoText(slotProps.row)" class="additional-info">{{ getRetryInfoText(slotProps.row) }}</p>

                                                    <template v-slot:action>
                                                        <base-button
                                                            v-if="slotProps.row.isEligibleForDismiss"
                                                            :label="$t('common.term.dismiss')"
                                                            :outline="slotProps.row.isEligibleForRetry ? true : null"
                                                            primary-button
                                                            @click="triggerDismiss(slotProps.row)"
                                                        />
                                                        <base-button
                                                            v-if="slotProps.row.isEligibleForRetry"
                                                            :label="$t('common.term.retry')"
                                                            class="q-ml-sm"
                                                            primary-button
                                                            @click="triggerRetry(slotProps.row)"
                                                        />
                                                    </template>
                                                </info-box>
                                            </template>
                                        </grid-card>
                                    </template>
                                    <!-- / DxpOutboxItems -->
                                </div>
                            </q-td>
                        </q-tr>
                    </template>
                </base-table>
            </div>
        </div>

        <!-- DxpInboxItemTargetObjectMatchedContractUpdateOverlay -->
        <dxp-inbox-item-target-object-matched-contract-update-overlay ref="dxpInboxItemTargetObjectMatchedContractUpdateOverlay" @success="refreshTable" />

        <!-- Unassign target object matched contract -->
        <form-dialog
            ref="unassignTargetObjectMatchedContractDialog"
            :submit-button-label="$t('common.term.unassign')"
            @cancel="unassignTargetObjectMatchedContractDxpItem = null"
            @submit="handleUnassignTargetObjectMatchedContract"
        >
            <template v-slot:title>{{ $tc('views.data-exchange.unassign-matched-contract', 1) }}</template>

            <template v-slot:default>
                <p>{{ $tc('views.data-exchange.unassign-matched-contract--confirm', 1, { contract: unassignTargetObjectMatchedContractDxpItem?.targetObject?.matchedContract?.currentContractNumber, ofTheEntity: $tc(`common.data-exchange.process--entity-name.of-the.${kebabCase(unassignTargetObjectMatchedContractDxpItem?.document_type_identifier)}`, 1), theEntity: $tc(`common.data-exchange.process--entity-name.the.${kebabCase(unassignTargetObjectMatchedContractDxpItem?.document_type_identifier)}`, 1) }) }}</p>
            </template>
        </form-dialog>

        <in-page-footer>
            <!-- File list overlay -->
            <dxp-file-list-overlay ref="dxpFileListOverlay" @success="onDxpFileListOverlaySuccess" />
            <base-button
                :label="$t('views.data-exchange.import-data-from-eco-hub')"
                primary-button
                :disable="!store.state.ecoHubTechUser"
                @click="triggerDxpFileListOverlayOpen"
            />

            <!-- Retry -->
            <form-dialog
                ref="retryDialog"
                :submit-button-label="$t('common.actions.dxp-item.retry')"
                @cancel="retryDxpItem = null"
                @submit="handleRetry"
            >
                <template v-slot:title>{{ $tc('common.actions.dxp-item.retry', retryDxpItems.length) }}</template>
                <template v-slot:default>
                    <!-- Retry dialog triggered for a single item -->
                    <p v-if="retryDxpItems.length === 1 && !!getRetryInfoText(retryDxpItems.at(0), { confirm: true })">{{ getRetryInfoText(retryDxpItems.at(0), { entityAmount: 1, confirm: true }) }}</p>

                    <!-- Retry dialog triggered for multiple selected items -->
                    <!-- TODO improvement: Add entity if all selected items are of the same `document_type_identifier` -->
                    <!-- TODO improvement: Show info message if the same action should be performed on all items. -->
                    <p v-else>{{ $tc('views.data-exchange.item-info.retry.retry-item--confirm', retryDxpItems.length) }}</p>
                </template>
            </form-dialog>
            <base-button
                v-if="itemsEligibleForRetry.length"
                :label="`${$t('common.term.retry')} (${itemsEligibleForRetry.length})`"
                primary-button
                outline
                @click="triggerRetry()"
            />

            <!-- Dismiss -->
            <form-dialog
                ref="dismissDialog"
                double-confirm
                @cancel="dismissDxpItem = null"
                @submit="handleDismiss"
            >
                <template v-slot:title>
                    <!-- Retry dialog triggered for a single item -->
                    <template v-if="dismissDxpItems.length === 1 && dismissDxpItems.at(0).document_type_identifier">
                        {{ $tc('views.data-exchange.dismiss-entity', 1, { entity: $tc(`common.data-exchange.process--entity-name.${kebabCase(dismissDxpItem.document_type_identifier)}`, 1) }) }}
                    </template>

                    <!-- Retry dialog triggered for multiple selected items -->
                    <template v-else>
                        {{ $tc('views.data-exchange.dismiss-entity', dismissDxpItems.length, { entity: $tc('common.data-exchange.process--entity-name.item', dismissDxpItems.length) }) }}
                    </template>
                </template>

                <template v-slot:default>
                    <!-- Retry dialog triggered for a single item -->
                    <p v-if="dismissDxpItems.length === 1 && dismissDxpItems.at(0).document_type_identifier">{{ $tc('views.data-exchange.dismiss-entity--confirm', 1, { entity: $tc(`common.data-exchange.process--entity-name.this.${kebabCase(dismissDxpItems.at(0).document_type_identifier)}`, 1) }) }}</p>

                    <!-- Retry dialog triggered for multiple selected items -->
                    <!-- TODO improvement: Add entity if all selected items are of the same `document_type_identifier` -->
                    <p v-else>{{ $tc('views.data-exchange.dismiss-item--confirm', dismissDxpItems.length) }}</p>
                </template>
            </form-dialog>
            <base-button
                v-if="itemsEligibleForDismiss.length"
                :label="`${$t('common.term.dismiss')} (${itemsEligibleForDismiss.length})`"
                primary-button
                outline
                @click="triggerDismiss()"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import Actions from '@/components/Actions.vue'
import BaseTable from '@/components/BaseTable.vue'
import DxpFileListOverlay from '@/components/dxp/DxpFileListOverlay.vue'
import DxpInboxItemTargetObjectMatchedContractUpdateOverlay from '@/components/dxp/DxpInboxItemTargetObjectMatchedContractUpdateOverlay.vue'
import { useQuasar } from '@/composables/quasar'
import { useRouter } from '@/composables/router'
import { DxpContractMatchNextStep, DxpInboxItemTargetObjectMatchedContractUpdateOverlayMode, DxpItemActions } from '@/enums'
import { extractErrorMessage } from '@/helpers/form'
import FormDialog from '@/libs/form/components/FormDialog.vue'
import InPageNavigation from '@/components/InPageNavigation.vue'
import StatusBadge from '@/components/StatusBadge.vue'
import { useI18n } from '@/composables/i18n'
import { DxpDocumentTypeIdentifier, DxpItemStatus, DxpItemType, QueueItemStatus, SearchOperator } from '@/enums/graphql'
import { DxpItemService } from '@/services'
import { kebabCase, snakeCase, upperFirst } from 'lodash'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import MainTabs from '@/components/MainTabs.vue'
import { EventBus } from '@/event-bus'
import { UserSettings } from '@/helpers/user'
import { useStore } from '@/composables/store'
import { copyToClipboard } from 'quasar'

export default {
    name: 'EcoHubDataExchange',
    components: {
        Actions,
        FormDialog,
        BaseTable,
        InPageNavigation,
        DxpFileListOverlay,
        StatusBadge,
        DxpInboxItemTargetObjectMatchedContractUpdateOverlay,
        MainTabs,
    },
    meta () {
        return {
            title: this.$t('common.eco-hub.eco-hub'),
        }
    },
    props: {
        statusGroup: {
            type: String,
            default: 'pending',
        },
        documentType: {
            type: String,
            default: '',
            required: false,
        },
    },
    setup (props) {
        const store = useStore()
        const { t, tc, te } = useI18n()
        const $q = useQuasar()
        const router = useRouter()
        const userSettings = new UserSettings(store.state.user)

        // Table
        const showTechDetails = ref(userSettings.getItem('DxpInbox.showTechDetails') === 'true')
        watch(showTechDetails, (value) => {
            userSettings.setItem('DxpInbox.showTechDetails', value)
        })
        const table = ref(null)
        const columns = computed(() => {
            const columns = [
                {
                    name: 'created_at',
                    label: tc('common.term.created-on'),
                    align: 'left',
                    field: row => `${row.formattedCreatedAtDate} – ${row.formattedCreatedAtTime}`,
                    sortable: true,
                    required: true,
                },
                {
                    name: 'document_type_identifier',
                    label: tc('common.term.type'),
                    align: 'left',
                    field: row => tc(`common.data-exchange.process--entity-name.${kebabCase(row.document_type_identifier)}`, 1),
                    sortable: true,
                },
                {
                    name: 'service_provider_display_name',
                    label: tc('common.data-exchange.service-provider', 1),
                    align: 'left',
                    field: row => row.service_provider_display_name,
                    sortable: true,
                    required: true,
                },
                {
                    name: 'reference',
                    label: tc('common.term.reference', 1),
                    align: 'left',
                    field: row => row.targetObject ? row.targetObject?.matchedContract?.currentContractNumber || `(${row.targetObject?.contract_number})` : '–',
                    classes: row => !row.targetObject?.matchedContract ? 'additional-info' : '',
                },
                {
                    name: 'status',
                    label: tc('common.term.status'),
                    align: 'left',
                    field: row => row.status,
                    required: true,
                },
                { name: 'actions', label: tc('common.term.action', 2), align: 'right', required: true },
            ]

            if (showTechDetails.value) {
                columns.splice(2, 0,
                    {
                        name: 'dxp_version',
                        label: tc('common.term.version'),
                        align: 'left',
                        field: row => row.fileInfo?.documentType?.version.split('_').slice(1).join('.'),
                        required: true,
                        classes: 'additional-info',
                    }
                )
                columns.splice(4, 0,
                    {
                        name: 'trace_id',
                        label: tc('common.term.trace_id'),
                        align: 'left',
                        field: row => row.trace_id,
                        required: true,
                    }
                )
            }

            return columns
        })
        function refreshTable () {
            table.value.refreshObjects()
        }

        // Filters
        // # statusGroups
        const statusGroups = {
            'pending': [DxpItemStatus.DXP_ERROR, DxpItemStatus.ON_HOLD, DxpItemStatus.UNPROCESSABLE],
            'processing': [DxpItemStatus.PENDING],
            'done': [DxpItemStatus.DISMISSED, DxpItemStatus.DONE],
        }
        const currentStatusGroup = computed(() => statusGroups[props.statusGroup])

        // # documentTypes
        let documentTypes = []
        const importableDocumentTypes = []
        if (store.state.user.aclPermissions.includes('PremiumInvoice:manage')) {
            documentTypes.push(DxpDocumentTypeIdentifier.BILLING)
            importableDocumentTypes.push(DxpDocumentTypeIdentifier.BILLING)
        }
        if (store.state.user.aclPermissions.includes('PremiumReminder:manage')) {
            documentTypes.push(DxpDocumentTypeIdentifier.REMINDER)
            importableDocumentTypes.push(DxpDocumentTypeIdentifier.REMINDER)
        }
        if ([DxpDocumentTypeIdentifier.BILLING, DxpDocumentTypeIdentifier.REMINDER].some(documentType => documentTypes.includes(documentType))) {
            documentTypes.push(DxpDocumentTypeIdentifier.ERROR_TECHNICAL)
        }
        documentTypes = documentTypes.map(documentTypeIdentifier => kebabCase(documentTypeIdentifier))

        // # Compose filter variables
        const additionalFilters = computed(() => {
            const filterVariables = {}

            if (currentStatusGroup.value.length) {
                filterVariables.filterStatus = currentStatusGroup.value
                filterVariables.filterStatusOperator = SearchOperator.EQUAL_TO
            }

            if (props.documentType) {
                filterVariables.filterDocumentTypeIdentifiers = [snakeCase(props.documentType).toUpperCase()]
            }

            return filterVariables
        })

        // Expansion
        const expandedRows = ref([])

        function toggleExpansion (rowKey) {
            if (expandedRows.value.includes(rowKey)) {
                expandedRows.value = expandedRows.value.filter(key => key !== rowKey)
            } else {
                expandedRows.value.push(rowKey)
            }
        }

        function expandAll () {
            table.value.items.forEach(item => {
                if (!expandedRows.value.includes(item.id)) expandedRows.value.push(item.id)
            })
        }

        function collapseAll () {
            table.value.items.forEach(item => {
                expandedRows.value = expandedRows.value.filter(id => id !== item.id)
            })
        }

        // Selection
        const selectedRows = ref([])

        function isSelected (rowKey) {
            return selectedRows.value.findIndex(selectedItem => selectedItem.id === rowKey) > -1
        }

        function toggleSelected (row) {
            if (isSelected(row.id)) {
                selectedRows.value = selectedRows.value.filter(selectedRow => selectedRow.id !== row.id)
            } else {
                selectedRows.value.push(row)
            }
        }

        function clearSelectedItems () {
            selectedRows.value.length = 0
            if (table.value) table.value.$refs.qTable.clearSelection()
        }

        // Retry
        const retryDialog = ref(null)
        const itemsEligibleForRetry = computed(() => {
            return selectedRows.value.filter(dxpItem => dxpItem.isEligibleForRetry)
        })
        const retryDxpItem = ref(null)
        const retryDxpItems = computed(() => {
            return retryDxpItem.value ? [retryDxpItem.value] : itemsEligibleForRetry.value
        })

        function triggerRetry (dxpItem) {
            if (dxpItem) retryDxpItem.value = dxpItem
            retryDialog.value.open()
        }

        function handleRetry () {
            DxpItemService.retry({ dxpItemIds: retryDxpItems.value.map(item => item.id) })
                .then(() => {
                    retryDialog.value.close()
                    refreshTable()

                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.data-exchange.retry-success', retryDxpItems.value.length),
                    })

                    if (!retryDxpItem.value) clearSelectedItems()
                })
                .catch(error => {
                    retryDialog.value.showFormErrorMessage(error)
                    retryDialog.value.resetFormSubmitStatus()
                })
        }

        // Dismiss
        const dismissDialog = ref(null)
        const itemsEligibleForDismiss = computed(() => {
            return selectedRows.value.filter(dxpItem => dxpItem.isEligibleForDismiss)
        })
        const dismissDxpItem = ref(null)
        const dismissDxpItems = computed(() => {
            return dismissDxpItem.value ? [dismissDxpItem.value] : itemsEligibleForDismiss.value
        })

        function triggerDismiss (dxpItem) {
            if (dxpItem) dismissDxpItem.value = dxpItem
            dismissDialog.value.open()
        }

        function handleDismiss () {
            DxpItemService.dismiss({ dxpItemIds: dismissDxpItems.value.map(item => item.id) })
                .then(() => {
                    dismissDialog.value.close()
                    refreshTable()

                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.data-exchange.dismiss-success', dismissDxpItems.value.length),
                    })

                    if (!dismissDxpItem.value) clearSelectedItems()
                })
                .catch(error => {
                    dismissDialog.value.showFormErrorMessage(error)
                    dismissDialog.value.resetFormSubmitStatus()
                })
        }

        // Unassign target object matched contract
        const unassignTargetObjectMatchedContractDialog = ref(null)
        const unassignTargetObjectMatchedContractDxpItem = ref(null)
        function triggerUnassignTargetObjectMatchedContract (dxpItem) {
            unassignTargetObjectMatchedContractDxpItem.value = dxpItem
            unassignTargetObjectMatchedContractDialog.value.open()
        }
        function handleUnassignTargetObjectMatchedContract () {
            unassignTargetObjectMatchedContractDxpItem.value.updateTargetObjectMatchedContract(null)
                .then(dxpInboxItem => {
                    unassignTargetObjectMatchedContractDialog.value.close()
                    refreshTable()

                    $q.notify({
                        type: 'positive',
                        message: t('common.notifications.data-exchange.unassign-target-object-matched-contract-success'),
                    })
                })
                .catch(error => {
                    unassignTargetObjectMatchedContractDialog.value.showFormErrorMessage(error)
                    unassignTargetObjectMatchedContractDialog.value.resetFormSubmitStatus()
                })
        }

        // Actions
        function handleActions (action) {
            switch (action.key) {
                case DxpItemActions.UPDATE_TARGET_OBJECT_MATCHED_CONTRACT:
                    dxpInboxItemTargetObjectMatchedContractUpdateOverlay.value.open({ dxpInboxItem: action.item, mode: action.item?.targetObject?.nextStep === DxpContractMatchNextStep.CONFIRM_SELECTED_CONTRACT ? DxpInboxItemTargetObjectMatchedContractUpdateOverlayMode.CONFIRM : DxpInboxItemTargetObjectMatchedContractUpdateOverlayMode.UPDATE })
                    break
                case DxpItemActions.UNASSIGN_TARGET_OBJECT_MATCHED_CONTRACT:
                    triggerUnassignTargetObjectMatchedContract(action.item)
                    break
                case DxpItemActions.RETRY:
                    triggerRetry(action.item)
                    break
                case DxpItemActions.DISMISS:
                    triggerDismiss(action.item)
                    break
            }
        }

        // DxpItem
        function getUpdateTargetObjectMatchedContractLabel (item) {
            const targetObjectNextStepTranslationKey = `common.data-exchange.item.next-step--${kebabCase(item.targetObject?.nextStep)}`
            return te(targetObjectNextStepTranslationKey, 1) ? tc(targetObjectNextStepTranslationKey, 1) : null
        }

        function triggerCopyToClipboard (content) {
            const formattedContent = content.replace(/<br>/g, '\n')

            copyToClipboard(formattedContent)
                .then(() => {
                    this.$q.notify({
                        type: 'positive',
                        message: this.$t('common.notifications.copied-to-clipboard-success'),
                        caption: content,
                    })
                })
                .catch(() => {
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$t('common.notifications.copied-to-clipboard-error'),
                    })
                })
        }

        /**
         * Returns the status of an item based on its properties.
         *
         * @param {Object} item - The item object containing status and other properties.
         * @return {string} - The status of the item.
         */
        function getItemStatus (item) {
            let status = item.status

            if (
                item.status === DxpItemStatus.PENDING && ['invalid_document_type', 'unknown'].includes(item.currentQueueItem?.status_message?.code)
                ||
                item.status === DxpItemStatus.ON_HOLD && ['invalid_xml_file', 'unknown'].includes(item.currentQueueItem?.status_message?.code)
            ) {
                status = item.aggregatedStatus
            }

            return status
        }

        /**
         * Returns the status label of the given item.
         *
         * @param {object} item - The item object.
         * @returns {string} - The status label of the item.
         */
        function getItemStatusLabel (item) {
            let label = tc(`common.status.${kebabCase(item.status)}`, 1)

            const mainStatusTranslationKey = `common.status.dxp-item-status.${kebabCase(item.status)}`
            const nestedStatusTranslationKey = `common.status.dxp-item-status.${kebabCase(item.status)}.${kebabCase(item.status)}`
            const currentQueueItemStatusTranslationKey = `common.status.dxp-item-status.${kebabCase(item.currentQueueItem?.status)}.${kebabCase(item.currentQueueItem?.status)}`
            const currentQueueItemStatusMessageTranslationKey = `common.status.dxp-item-status.${kebabCase(item.status)}.${kebabCase(item.currentQueueItem?.status_message?.code)}`

            // PENDING should always be prioritized,
            if (item.status === DxpItemStatus.PENDING) {
                if ([QueueItemStatus.FAILED].includes(item.currentQueueItem.status)) {
                    label = tc(currentQueueItemStatusTranslationKey, 1)
                } else {
                    label = tc(nestedStatusTranslationKey, 1)
                }
            // ON_HOLD should also be prioritized in some cases
            } else if (item.status === DxpItemStatus.ON_HOLD && ['invalid_xml_file', 'unknown'].includes(item.currentQueueItem?.status_message?.code)) {
                label = tc(currentQueueItemStatusTranslationKey, 1)
            } else if (item.nextStep) {
                label = getUpdateTargetObjectMatchedContractLabel(item)
            // DxpInboxItems/DxpOutboxItems can be differentiated in some specific cases
            } else if (te(`${mainStatusTranslationKey}--${kebabCase(item.type)}`, 1) || te(`${nestedStatusTranslationKey}--${kebabCase(item.type)}`, 1)) {
                label = te(`${nestedStatusTranslationKey}--${kebabCase(item.type)}`, 1) ? tc(`${nestedStatusTranslationKey}--${kebabCase(item.type)}`, 1) : tc(`${mainStatusTranslationKey}--${kebabCase(item.type)}`, 1)
            // Default
            } else if (te(mainStatusTranslationKey, 1)) {
                switch (item.status) {
                    case DxpItemStatus.UNPROCESSABLE:
                    case DxpItemStatus.DXP_ERROR:
                        label = tc(nestedStatusTranslationKey, 1)
                        break
                    default:
                        label = te(currentQueueItemStatusMessageTranslationKey, 1) ? tc(currentQueueItemStatusMessageTranslationKey, 1) : tc(nestedStatusTranslationKey, 1)
                        break
                }
            }

            return label
        }

        /**
         * Returns the status info text for a given item.
         *
         * @param {object} item - The item to get the status info text for.
         * @returns {string|null} - The status info text or null if not applicable.
         */
        function getItemStatusInfoText (item) {
            if (![DxpItemStatus.DONE].includes(item.status) && !item.targetObject?.nextStep && item.currentQueueItem?.status_message) {
                return item.currentQueueItem.status_message.message
            }
            if (item.status === DxpItemStatus.DONE && !item.targetObject && ![DxpDocumentTypeIdentifier.ERROR_TECHNICAL, DxpDocumentTypeIdentifier.ERROR_BUSINESS].includes(item.document_type_identifier)) {
                return tc('views.data-exchange.item-info.done.no-target-object', 1, { entity: upperFirst(tc(`common.data-exchange.process--entity-name.this.${kebabCase(item.document_type_identifier)}`, 1)) })
            }
            return null
        }

        /**
         * Retrieves the error code associated with the status message of the given item.
         *
         * @param {Object} item - The item to retrieve the error code from.
         * @returns {(string|null)} The error code associated with the status message, or `null` if no error code is found.
         */
        function getItemStatusInfoErrorCode (item) {
            if ([DxpItemStatus.ON_HOLD, DxpItemStatus.UNPROCESSABLE, DxpItemStatus.DXP_ERROR].includes(item.status) || item.status === DxpItemStatus.PENDING && [QueueItemStatus.FAILED].includes(item.currentQueueItem.status)) {
                return item.currentQueueItem?.status_message?.code
            }
            return null
        }

        /**
         * Retrieves the context of an item based on its status and queue information.
         * @param {Item} item - The item to retrieve the context for.
         * @return {string|null} - The context of the item. Returns 'file' if the item meets certain conditions, 'reference' if another set of conditions are met, or null if neither condition
         * is met.
         */
        function getItemContext (item) {
            if ([DxpItemStatus.DXP_ERROR, DxpItemStatus.UNPROCESSABLE].includes(item.status)
                || (item.status === DxpItemStatus.ON_HOLD && ['invalid_xml_file', 'unknown'].includes(item.currentQueueItem?.status_message?.code))
                || (item.status === DxpItemStatus.PENDING && item.currentQueueItem?.status === QueueItemStatus.FAILED)) {
                return 'file'
            } else if ([DxpItemStatus.DONE].includes(item.status) || item.status === DxpItemStatus.ON_HOLD && !['invalid_xml_file', 'unknown'].includes(item.currentQueueItem?.status_message?.code)) {
                return 'reference'
            }
            return null
        }

        /**
         * Returns the type of item information based on the given item.
         *
         * @param {object} item - The item object containing information about the item.
         * @returns {string} The type of item information ('critical', 'negative', 'warning', or 'info').
         */
        function getItemInfoType (item) {
            // Theoretically, this _should_ never happen. (But it did.)
            if (item.status === DxpItemStatus.DONE && item.targetObject?.matched_contract_confirmed === false) {
                return 'critical'
            }

            if (item.status === DxpItemStatus.PENDING && item.currentQueueItem?.status === QueueItemStatus.FAILED
                || item.status === DxpItemStatus.ON_HOLD && ['invalid_xml_file', 'unknown'].includes(item.currentQueueItem?.status_message?.code)) {
                return 'negative'
            }

            switch (item.status) {
                case DxpItemStatus.UNPROCESSABLE:
                case DxpItemStatus.DXP_ERROR:
                    return 'negative'
                case DxpItemStatus.ON_HOLD:
                    return 'warning'
                case DxpItemStatus.DONE:
                case DxpItemStatus.PENDING:
                default:
                    return 'info'
            }
        }

        /**
         * Returns the text information for the match status of an item's contract.
         *
         * @param {Object} item - The item object representing the contract.
         * @returns {string|null} - The text information for the match status of the contract.
         */
        function getItemContractMatchStatusInfoText (item) {
            const matchStatusTranslationKey = `views.data-exchange.item-info.match-status.${kebabCase(item.targetObject?.contractMatchStatus)}`

            return te(matchStatusTranslationKey) ? t(matchStatusTranslationKey) : null
        }

        /**
         * Retrieves the information text for a given item.
         *
         * @param {Object} item - The item object for which information text is to be retrieved.
         *
         * @return {string|null} The information text based on the item's status and type. Returns null if no information text is applicable.
         */
        function getItemInfoText (item) {
            if (item.status === DxpItemStatus.DONE) {
                // DxpInboxItems
                if (item.type === DxpItemType.INBOX) {
                    // Special case: DxpContractLink / files only
                    if (item.targetObject?.__typename === 'DxpContractLink') {
                        return t(`views.data-exchange.item-info.done.dxp-contract-link.${kebabCase(item.targetObject?.type)}`)
                    }
                    // Special case: Manually deleted targetObject (e.g. premium invoice)
                    if (!item.targetObject) {
                        return tc('views.data-exchange.item-info.done.no-target-object', 1, { entity: upperFirst(tc(`common.data-exchange.process--entity-name.this.${kebabCase(item.document_type_identifier)}`, 1)) })
                    }

                    // Theoretically, this _should_ never happen. (But it did.)
                    if (item.targetObject?.matched_contract_confirmed === false) {
                        return 'TODO: This item should have `targetObject.matched_contract_confirmed === true`'
                    }
                }

                if (item.type === DxpItemType.OUTBOX) {
                    return tc('views.data-exchange.item-info.error.error-info--done', 1, { provider: item.service_provider_display_name })
                }
            }

            if ([DxpItemStatus.ON_HOLD, DxpItemStatus.UNPROCESSABLE, DxpItemStatus.DXP_ERROR].includes(item.status) && item.currentQueueItem?.status_message) {
                return tc(`views.data-exchange.item-info.${kebabCase(item.status)}.${kebabCase(item.currentQueueItem?.status_message?.code || item.aggregatedStatus)}`, 1)
            }

            if (item.status !== DxpItemStatus.PENDING && item.targetObject?.nextStep) {
                return tc(`views.data-exchange.item-info.${kebabCase(item.status)}.${kebabCase(item.targetObject?.nextStep)}--assign-entity`, 1, { entity: tc(`common.data-exchange.process--entity-name.this.${kebabCase(item.document_type_identifier)}`, 1) })
            }

            return null
        }

        /**
         * Retrieves retry information text based on the provided item and options.
         *
         * @param {object} item - The item for which retry information is needed.
         * @param {object} options - The optional parameters to customize the retry information.
         * @param {number} [options.entityAmount=1] - The amount associated with the entity.
         * @param {string} [options.itemType=''] - The type of the item (default is an empty string).
         * @param {boolean} [options.confirm=false] - Flag indicating whether confirmation is needed (default is false).
         *
         * @return {string|null} The generated retry information text, or null if the item is not eligible for retry.
         */
        function getRetryInfoText (
            item,
            {
                entityAmount = 1,
                itemType = '',
                confirm = false,
            } = {
                entityAmount: 1,
                itemType: '',
                confirm: false,
            }
        ) {
            if (item.isEligibleForRetry) {
                const itemType = kebabCase(itemType) || kebabCase(item.document_type_identifier)

                const entityTranslationKey = `common.data-exchange.process--entity-name.${itemType}`
                const theEntityTranslationKey = `common.data-exchange.process--entity-name.the.${itemType}`
                const thisEntityTranslationKey = `common.data-exchange.process--entity-name.this.${itemType}`
                const ofThisEntityTranslationKey = `common.data-exchange.process--entity-name.of-this.${itemType}`
                const aggregatedStatusCode = getItemStatusInfoErrorCode(item) ? `${kebabCase(item.aggregatedStatus)}--${kebabCase(getItemStatusInfoErrorCode(item))}` : kebabCase(item.aggregatedStatus)
                const retryInfoTranslationKey = `views.data-exchange.item-info.retry.${getItemContext(item) === 'reference' ? 'retry-matching' : aggregatedStatusCode}--entity`

                return te(retryInfoTranslationKey) ? tc(`${retryInfoTranslationKey}${confirm ? '--confirm' : ''}`, entityAmount, { entity: tc(entityTranslationKey, entityAmount), theEntity: tc(theEntityTranslationKey, entityAmount), thisEntity: tc(thisEntityTranslationKey, entityAmount), ofThisEntity: tc(ofThisEntityTranslationKey, entityAmount), noEntity: tc(entityTranslationKey, 0) }) : null
            }
            return null
        }

        // DxpInboxItemTargetObjectMatchedContractUpdateOverlay
        const dxpInboxItemTargetObjectMatchedContractUpdateOverlay = ref(null)

        // File list overlay
        const dxpFileListOverlay = ref(null)
        const currentFileListOverlayDocumentType = computed(() => props.documentType && importableDocumentTypes.includes(props.documentType.toUpperCase()) ? props.documentType.toUpperCase() : undefined)
        function triggerDxpFileListOverlayOpen () {
            dxpFileListOverlay.value.open({ importableDxpDocumentTypeIdentifiers: importableDocumentTypes, dxpDocumentTypeIdentifier: currentFileListOverlayDocumentType.value })
        }
        function onDxpFileListOverlaySuccess () {
            router.push({ name: 'eco-hub-data-exchange', params: { statusGroup: 'processing', documentType: props.documentType || undefined } })
            refreshTable()
        }

        // Hooks
        onMounted(() => {
            EventBus.$on('shortcut:expandAll', expandAll)
            EventBus.$on('shortcut:collapseAll', collapseAll)
            EventBus.$on('shortcut:newEntity', triggerDxpFileListOverlayOpen)
        })

        onUnmounted(() => {
            EventBus.$off('shortcut:expandAll', expandAll)
            EventBus.$off('shortcut:collapseAll', collapseAll)
            EventBus.$off('shortcut:newEntity', triggerDxpFileListOverlayOpen)
        })

        return {
            // Static,
            DxpItemService,
            DxpDocumentTypeIdentifier,
            DxpItemStatus,
            QueueItemStatus,
            DxpContractMatchNextStep,
            DxpInboxItemTargetObjectMatchedContractUpdateOverlayMode,
            DxpItemType,

            // Store
            store,

            // Table
            table,
            columns,
            refreshTable,

            // Filters
            documentTypes,
            additionalFilters,

            // Expansion
            expandedRows,
            toggleExpansion,
            expandAll,
            collapseAll,

            // Selection
            selectedRows,
            toggleSelected,

            // Retry
            retryDialog,
            itemsEligibleForRetry,
            retryDxpItem,
            retryDxpItems,
            triggerRetry,
            handleRetry,

            // Dismiss
            dismissDialog,
            itemsEligibleForDismiss,
            dismissDxpItem,
            dismissDxpItems,
            triggerDismiss,
            handleDismiss,

            // Unassign target object matched contract
            unassignTargetObjectMatchedContractDialog,
            unassignTargetObjectMatchedContractDxpItem,
            handleUnassignTargetObjectMatchedContract,

            // Actions
            handleActions,

            // DxpItem
            getUpdateTargetObjectMatchedContractLabel,
            getItemStatus,
            getItemStatusLabel,
            getItemStatusInfoText,
            getItemStatusInfoErrorCode,
            getItemContext,
            getItemInfoType,
            getItemContractMatchStatusInfoText,
            getItemInfoText,
            getRetryInfoText,

            // DxpInboxItemTargetObjectMatchedContractUpdateOverlay
            dxpInboxItemTargetObjectMatchedContractUpdateOverlay,

            // File list overlay
            dxpFileListOverlay,
            currentFileListOverlayDocumentType,
            triggerDxpFileListOverlayOpen,
            onDxpFileListOverlaySuccess,

            // Functions
            kebabCase,
            triggerCopyToClipboard,

            // Misc.
            showTechDetails,
        }
    },
}
</script>

<style scoped>
main {
    --size-checkbox-column: 4.5rem;
    --size-checkbox-column-padding-spacing: var(--size-spacing-sm);
    --size-status-border-width: var(--size-spacing-xs);

    --color-item-status: var(--q-color-secondary-light);

    .item-status-done {
        --color-item-status: var(--q-color-positive);
    }

    .item-status-on-hold {
        --color-item-status: var(--q-color-warning);
    }

    .item-status-unprocessable,
    .item-status-dxp-error,
    .item-status-failed {
        --color-item-status: var(--q-color-negative);
    }
}

::v-deep .checkbox-column {
    position: relative;
    width: var(--size-checkbox-column);
    min-width: var(--size-checkbox-column);

    &::after {
        content: '';
        position: absolute;
        left: calc(100% - var(--size-status-border-width) - var(--size-checkbox-column-padding-spacing));
        top: 0;
        width: var(--size-status-border-width);
        height: 100%;

        background-color: var(--color-item-status);
    }
}

::v-deep .expanded-row {
    & > .q-td {
        padding-left: calc(var(--size-checkbox-column) + var(--size-spacing-sm));
    }

    .expanded-row-content {

        &::after {
            content: '';
            position: absolute;
            left: calc(var(--size-checkbox-column) - var(--size-status-border-width) - var(--size-checkbox-column-padding-spacing));
            top: -1px;
            width: var(--size-status-border-width);
            height: calc(100% + 1px);

            background-color: var(--color-item-status);
        }
    }
}

::v-deep .actions-wrapper {
    display: inline-block;
}
</style>
