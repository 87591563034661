<template>
    <header class="page-header" :class="{ 'has-actions': $slots.actions, 'no-breadcrumbs': noBreadcrumbs }">
        <q-scroll-area
            v-if="!noBreadcrumbs"
            class="breadcrumbs"
            horizontal
        >
            <q-breadcrumbs
                gutter="xs"
                data-test="breadcrumbs"
            >
                <slot name="breadcrumbRoot">
                    <q-breadcrumbs-el
                        :icon="breadcrumbRoot.icon"
                        :label="breadcrumbRoot.label"
                        :to="{ name: breadcrumbRoot.routeName }"
                        data-test="breadcrumb:root"
                    />
                </slot>
                <slot name="breadcrumbs" />
            </q-breadcrumbs>
        </q-scroll-area>

        <div v-if="$slots.beforeContent" class="before-content">
            <slot name="beforeContent" />
        </div>

        <div
            v-if="$slots.default"
            class="header-content"
            data-test="wrapper:page-header-content"
        >
            <slot name="default" />
        </div>

        <div v-if="$slots.actions || backLinkRoute" class="actions">
            <!-- TODO improvement @MTR: Enhance styling of button (different icon? text and icon?) -->
            <base-button
                v-if="backLinkRoute"
                :label="backLinkLabel"
                flat
                :to="backLinkRoute"
            />
            <slot name="actions" />
        </div>

        <div v-if="$slots.afterContent" class="after-content">
            <slot name="afterContent" />
        </div>

        <q-linear-progress
            v-if="progressBar"
            :value="progressBarValue"
            :indeterminate="progressBarIndeterminate"
            track-color="border-primary"
            :color="progressBarColor"
            class="page-header-progress-bar"
        />
    </header>
</template>

<script>
import { i18n } from '@/i18n'

export default {
    name: 'PageHeader',
    props: {
        noBreadcrumbs: {
            type: Boolean,
            default: false,
        },
        progressBar: {
            type: Boolean,
            default: false,
        },
        progressBarValue: {
            type: Number,
            default: 0,
        },
        progressBarIndeterminate: {
            type: Boolean,
            default: false,
        },
        progressBarColor: {
            type: String,
            default: 'primary',
        },
        backLinkRoute: {
            type: Object,
            default: () => null,
        },
        backLinkLabel: {
            type: String,
            default: i18n.t('common.term.back'),
        },
    },
    computed: {
        breadcrumbRoot () {
            let icon = ''
            let label = ''
            let routeName = ''

            // TODO improvement: Find a nicer way to handle this (aka "make it pretty")
            switch (this.$route.meta.appSubSection) {
                case 'account': {
                    switch (this.$route.meta.navGroup) {
                        case 'security': {
                            icon = 'mib-password-lock-2'
                            label = this.$tc('common.term.security', 1)
                            routeName = 'account-security-password'
                            break
                        }
                        default: {
                            icon = 'mib-settings-user'
                            label = this.$tc('common.term.user-account--my-user-account', 1)
                            routeName = 'account-dashboard'
                        }
                    }
                    break
                }

                case 'user-manual': {
                    icon = 'mib-book-flip-page'
                    label = this.$tc('common.user-manual.user-manual', 1)
                    routeName = 'user-manual'
                    break
                }

                case 'admin': {
                    switch (this.$route.meta.navGroup) {
                        case 'users': {
                            icon = 'mib-multiple-actions-address'
                            label = this.$tc('common.term.user', 2)
                            routeName = 'admin-user-management'
                            break
                        }
                        case 'consultingSettings': {
                            icon = 'mib-tools-box'
                            label = this.$tc('common.contact.consulting-setting', 2)
                            routeName = 'admin-consulting-settings-list'
                            break
                        }
                        case 'products': {
                            icon = 'mib-shipment-box'
                            label = this.$tc('common.term.product', 2)
                            routeName = 'admin-product-management'
                            break
                        }
                        case 'correspondence': {
                            icon = 'mib-read-email-letter'
                            label = this.$tc('common.term.correspondence', 1)
                            routeName = 'admin-correspondence'
                            break
                        }
                        case 'accounting': {
                            icon = 'mib-accounting-calculator-2'
                            label = this.$tc('common.accounting.accounting', 1)
                            routeName = 'admin-accounting'
                            break
                        }
                        case 'commissions': {
                            icon = 'mib-accounting-coins-bill'
                            label = this.$tc('common.commission.commission', 2)
                            routeName = 'admin-commissions'
                            break
                        }
                        case 'integrations': {
                            icon = 'mib-server-settings-1'
                            label = this.$tc('common.integrations.integration', 2)
                            routeName = 'admin-eco-hub'
                            break
                        }
                        default: {
                            icon = 'mib-desktop-monitor-settings'
                            label = this.$tc('views.admin.settings.title', 2)
                            routeName = 'admin-dashboard'
                        }
                    }
                    break
                }

                case 'dev': {
                    icon = 'mib-programming-browser-1'
                    label = this.$t('views.dev.title')
                    routeName = 'dev-dashboard'
                    break
                }

                default: {
                    switch (this.$route.meta.navGroup) {
                        case 'contacts': {
                            icon = 'mib-multiple-neutral-2'
                            label = this.$tc('common.term.contact', 2)
                            routeName = 'contact-list'
                            break
                        }
                        case 'tasks': {
                            icon = 'mib-checklist-alternate'
                            label = this.$tc('common.task.task', 2)
                            routeName = 'task-list'
                            break
                        }
                        case 'drive': {
                            icon = 'mib-hard-drive'
                            label = this.$tc('common.drive.drive', 1)
                            routeName = 'drive'
                            break
                        }
                        case 'downloads': {
                            icon = 'mib-hard-drive'
                            label = this.$tc('views.download.download', 2)
                            routeName = 'downloads'
                            break
                        }
                        case 'correspondence': {
                            icon = 'mib-read-email-letter'
                            label = this.$tc('common.term.correspondence', 1)
                            routeName = 'correspondence-dashboard'
                            break
                        }
                        case 'accounting': {
                            icon = 'mib-accounting-calculator-2'
                            label = this.$tc('common.accounting.accounting', 1)
                            routeName = 'accounting'
                            break
                        }
                        case 'commissions': {
                            icon = 'mib-accounting-coins-bill'
                            label = this.$tc('common.commission.commission', 2)
                            routeName = 'commissions'
                            break
                        }
                        case 'dataExchange': {
                            icon = 'mib-server-monitor-exchange'
                            label = this.$tc('common.data-exchange.data-exchange', 1)
                            routeName = 'eco-hub-data-exchange'
                            break
                        }
                        default: {
                            icon = 'mib-browser-gauge'
                            label = this.$tc('common.term.dashboard', 1)
                            routeName = 'dashboard'
                        }
                    }
                }
            }

            const breadcrumbRoot = {
                icon: icon,
                label: label,
                routeName: routeName,
            }

            return breadcrumbRoot
        },
    },
}
</script>

<style lang="scss" scoped>
// TODO: Rethink the whole ".page-header" and make a concept for an additional ".minified-header" with different content that slides in when the whole regular page header is scrolled out of view.
$sizeBreadcrumbsHeight: 2rem;
$sizeBorderBottomWidth: 1px;
$sizeScrollIndicatorGradient: $sizeSpacingSm;

.page-header {
    display: flex;
    flex-flow: row wrap;
    margin-top: 0;
    margin-bottom: 0.7rem;

    // Specific styling for the "main" page header (default case without other [nested] content that has another page header).
    .page-wrapper > & {
        position: sticky;
        top: calc(#{$sizeLayoutHeaderHeight} - #{$sizeBreadcrumbsHeight} - (#{$sizeGridDefaultGap} / 2)); // TODO: Verify the last value
        margin-bottom: 1.5rem;
        z-index: 100;

        border-bottom: 1px solid var(--color-border-primary);
        background-color: var(--color-background-primary);

        // Gradient for smoother visual appearance when content goes behind the .page-header
        // TODO: Check if this matches the overall style
        &::after {
            content: "";
            position: absolute;
            bottom: (-($sizeScrollIndicatorGradient + $sizeBorderBottomWidth));
            width: 100%;
            height: $sizeScrollIndicatorGradient;

            pointer-events: none;

            background: linear-gradient(180deg, var(--color-background-primary, #faf9f8), rgba(#faf9f8, 0));
        }

        // Adjust styles when the content was scrolled (= when `.q-layout` has the `.content-scrolled` class applied (handled in the Vue layouts)
        .content-scrolled & {

            @include mq($from: $sizeBreakpointMd) {

                .header-content {
                    transform: scale(0.8);
                }

                .actions {
                    transform: scale(0.9);
                }
            }
        }
    }

    .breadcrumbs {
        flex: 1 1 100%;
        height: $sizeBreadcrumbsHeight;
        margin-bottom: $sizeSpacingSm;

        // Gradient for visual indication that the content is wider than the viewport
        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: $sizeScrollIndicatorGradient;
            height: 100%;

            pointer-events: none;

            background: linear-gradient(-90deg, var(--color-background-primary, #faf9f8), rgba(#faf9f8, 0));
        }

        ::v-deep .q-breadcrumbs {
            display: flex;

            & > div {
                flex: 1 0 auto;
                padding-right: $sizeScrollIndicatorGradient;
            }
        }
    }

    .before-content,
    .after-content {
        flex: 1 1 100%;
    }

    .after-content {
    }

    .header-content {
        flex: 1 1 60%;
        margin-bottom: 0.5rem;
        padding-right: $sizeSpacingLg;

        /*transform-origin: bottom left;*/
        transform-origin: left center;
        transition: transform 0.5s $defaultTransitionTimingFunction;
    }

    h1,
    h2,
    h3 {
        margin-bottom: 0;
        transition: all 0.5s $defaultTransitionTimingFunction;

        word-break: break-word;
        hyphens: auto;
    }

    dt,
    dd {
        vertical-align: baseline;
    }

    // This class can be used if custom buttons are placed next to the "actions" that should look like "next action" buttons.
    .next-action-button-lookalike {
        background-color: var(--color-background-secondary);
    }

    .actions {
        flex: 1 1 auto; // TODO: Check this again when there is more real content – and maybe make it fullwidth on small screens.
        margin-bottom: 0.5rem;

        text-align: right;

        /*transform-origin: bottom right;*/
        transform-origin: right center;
        transition: transform 0.5s $defaultTransitionTimingFunction;
    }

    .page-header-progress-bar {
        height: 1px;
        margin-bottom: -1px;
    }
}

@media print {
    .page-header {

        // Specific styling for the "main" page header (default case without other [nested] content that has another page header).
        .page-wrapper > & {
            position: relative;
            top: 0;
        }

        &::after {
            display: none;
        }

        .actions {
            display: none;
        }
    }
}
</style>
