<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$t('views.dev.components.components')" :to="{ name: 'dev-components' }" />
                <q-breadcrumbs-el :label="$tc('views.dev.components.info-box.info-box', 1)" :to="{ name: 'dev-component-info-box' }" />
            </template>

            <h1>{{ $tc('views.dev.components.info-box.info-box', 1) }}</h1>
        </page-header>

        <div class="row q-col-gutter-md">
            <div class="col-xs-12 q-mt-lg">
                <p class="q-mb-lg">{{ $t('views.dev.components.info-box.info-box-text') }}</p>

                <info-box
                    v-for="color in mainAppColors"
                    :key="`info-box-${color.name}`"
                    :type="color.name"
                    :inline-actions="$q.screen.width > 1300"
                >
                    <p>
                        <strong>{{ $tc('views.dev.components.info-box.info-box', 1) }}</strong> <var>type="{{ color.name }}"</var>: Styling of <a href="#">links</a> inside info boxes is adjusted, too.
                    </p>

                    <template v-slot:action>
                        <base-button
                            :label="$t('common.term.cancel')"
                            class="q-mr-sm"
                            flat
                            primary-button
                        />
                        <base-button
                            :label="$t('common.term.decline')"
                            class="q-mr-sm"
                            outline
                            primary-button
                        />
                        <base-button
                            :label="$t('common.term.confirm')"
                            primary-button
                        />
                    </template>
                </info-box>

                <info-box :key="`info-box-with-icon`" :inline-actions="$q.screen.width > 1300" icon="mib-common-file-text-info">
                    <p>Info boxes can have <b>Icons</b>, too.</p>
                </info-box>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
export default {
    name: 'DevComponentInfoBox',
    meta () {
        return {
            title: this.$tc('views.dev.components.info-box.info-box', 1),
        }
    },
    components: {},
    data () {
        return {
            mainAppColors: [
                { name: 'primary' },
                { name: 'secondary' },
                { name: 'accent' },
                { name: 'positive' },
                { name: 'negative' },
                { name: 'warning' },
                { name: 'info' },
                { name: 'critical' },
            ],
        }
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
</style>
