import gql from 'graphql-tag'

export const DXP_SERVICE_PROVIDER_CONFIGURATIONS_QUERY = gql`
    query dxpServiceProviderConfigurations(
        $page: Int = 1
        $count: Int = 100
        $orderField: DxpServiceProviderConfigurationOrderField = SERVICE_PROVIDER_ID
        $orderDirection: OrderDirection = ASC
    ) {
        dxpServiceProviderConfigurations(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                service_provider_id
                service_provider_display_name
                supportedProcesses {
                    dxpProcess {
                        id
                    }
                    version
                }
                enabledProcesses {
                    id
                }
                enabledForAutomaticProcessing {
                    id
                }
                created_at
                updated_at
            }
        }
    }
`
