import { Model } from '@/models'
import { DxpServiceProviderConfigurationService } from '@/services'

export class DxpServiceProviderConfiguration extends Model {
    /**
     * Create a DxpServiceProviderConfiguration model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'service_provider_id',
            'service_provider_display_name',
            'supportedProcesses',
            'enabledProcesses',
            'enabledForAutomaticProcessing',
        ], data)
    }

    static get service () { return DxpServiceProviderConfigurationService }

    /**
     * Updates the service provider configuration with the specified parameters.
     *
     * @param {Object} params - The parameters for the update.
     * @param {string} params.serviceProviderId - The ID of the service provider to update.
     * @param {string[]} params.enabledProcessIds - The list of enabled process IDs.
     * @param {string[]} params.enabledForAutomaticProcessingProcessIds - The list of process IDs enabled for automatic processing.
     *
     * @return {Promise<Object>} A promise that resolves to the updated service provider configuration.
     */
    update ({ serviceProviderId, enabledProcessIds, enabledForAutomaticProcessingProcessIds }) {
        const variables = {
            service_provider_id: serviceProviderId,
            enabled_process_ids: enabledProcessIds,
            enabled_for_automatic_processing_process_ids: enabledForAutomaticProcessingProcessIds,
        }

        return DxpServiceProviderConfigurationService.update(variables).then(dxpServiceProviderConfiguration => {
            Object.assign(this, dxpServiceProviderConfiguration)
            return dxpServiceProviderConfiguration
        })
    }

    isEnabled (dxpProcess) {
        return this.enabledProcesses.some(enabledProcess => enabledProcess.id === dxpProcess.id)
    }

    isEnabledForAutomaticProcessing (dxpProcess) {
        return this.enabledForAutomaticProcessing.some(enabledProcess => enabledProcess.id === dxpProcess.id)
    }
}
