<template>
    <form-builder-next
        v-slot="{ formBuilder }"
        :form="form"
        :initial-data="initialData"
        :submit-button-label="$t('views.contact.create.create-contact')"
        in-page-footer
        @submit="onSubmit"
        @cancel="onCancel"
    >
        <!-- TODO improvement @MTR: Form needs some visual fine tuning at a later point. (Read: "Some day") -->
        <div class="row q-col-gutter-lg">
            <div class="col-xs-12 col-lg-6">
                <div class="row q-col-gutter-lg">
                    <div class="col-xs-12">
                        <card
                            :full-height="false"
                            :heading="$t('common.contact.company-info')"
                            class="personal-info"
                        >
                            <div class="row q-col-gutter-md">
                                <div class="col-xs-12 col-md-12">
                                    <form-builder-next :item="formBuilder.form.getItem('companyName')" autofocus />
                                </div>

                                <div class="col-xs-6 col-sm-8  col-xl-5">
                                    <!-- TODO: Add tooltip with info text (see according TODO in BaseInput.vue) -->
                                    <form-builder-next :item="formBuilder.form.getItem('uid')" />
                                </div>

                                <div class="col-xs-6 col-sm-4 col-xl-3">
                                    <form-builder-next :item="formBuilder.form.getItem('isLiableToVat')" />
                                </div>

                                <div class="col-xs-12 col-md-8 col-xl-4">
                                    <form-builder-next :item="formBuilder.form.getItem('correspondenceLanguageId')" />
                                </div>
                            </div>
                        </card>
                    </div>

                    <div class="col-xs-12">
                        <card
                            :full-height="false"
                            :heading="$tc('common.term.address', formBuilder.form.getItem('addresses').items.length)"
                            class="address"
                        >
                            <form-builder-next :item="formBuilder.form.getItem('addresses')">
                                <template v-slot:repeater-item="{ item: repeaterItem }">
                                    <div class="row q-col-gutter-md">
                                        <div v-if="formBuilder.form.getItem('addresses').items.length > 1" class="col-xs-12 q-pt-sm q-pb-none">
                                            <h3 v-if="repeaterItem.index === 0" class="text-h5">{{ $tc('common.contact.main-address', 1) }}</h3>
                                            <template v-else>
                                                <h3 class="text-h5">{{ $tc('common.contact.additional-address', 1) }}</h3>
                                            </template>
                                        </div>

                                        <div class="col-xs-12">
                                            <form-builder-next :item="repeaterItem.getItem('category')" pre-select-key="private" />
                                        </div>

                                        <div class="col-xs-8">
                                            <form-builder-next :item="repeaterItem.getItem('label')" />
                                        </div>

                                        <div class="col-xs-4">
                                            <form-builder-next :item="repeaterItem.getItem('poBox')" />
                                        </div>

                                        <div class="col-xs-12">
                                            <form-builder-next :item="repeaterItem.getItem('address1')" />
                                        </div>

                                        <div class="col-xs-12">
                                            <form-builder-next :item="repeaterItem.getItem('address2')" />
                                        </div>

                                        <div class="col-xs-12">
                                            <form-builder-next :item="repeaterItem.getItem('address3')" />
                                        </div>

                                        <div class="col-xs-4">
                                            <form-builder-next :item="repeaterItem.getItem('zip')" />
                                        </div>
                                        <div class="col-xs-8">
                                            <form-builder-next :item="repeaterItem.getItem('city')" />
                                        </div>

                                        <div class="col-xs-12">
                                            <!--- TODO: Add validation output event though its currently not possible to select no country? -->
                                            <form-builder-next :item="repeaterItem.getItem('country')" />
                                        </div>

                                        <div class="col-xs-12 col-sm-4">
                                            <form-builder-next :item="repeaterItem.getItem('validFrom')" />
                                        </div>

                                        <div v-if="repeaterItem.index > 0" class="col-xs-12 col-sm-4">
                                            <form-builder-next :item="repeaterItem.getItem('validUntil')" />
                                        </div>

                                        <div class="col-xs-12">
                                            <base-button
                                                v-if="repeaterItem.index > 0"
                                                :label="$tc('common.address.delete-address', 1)"
                                                class="q-mb-sm"
                                                outline
                                                text-color="negative"
                                                @click="repeaterItem.remove()"
                                            />

                                            <q-separator
                                                v-if="formBuilder.form.getItem('addresses').items.length > 1"
                                                :class="['q-mt-sm', { 'q-mb-lg': repeaterItem.index < formBuilder.form.getItem('addresses').items.length - 1 }]"
                                            />
                                        </div>
                                    </div>
                                </template>

                                <!-- TODO improvement @TFU with @MTR: Add possibility to move repeater-item-buttons _inside_ repeater item (f.e. in fieldsets, cards, …) -->
                                <template v-slot:repeater-item-buttons="{ item: repeaterItem }">
                                    <!-- Empty element needed to overwrite default content. -->
                                    <div></div>
                                </template>

                                <template v-slot:buttons="{ item }">
                                    <base-button
                                        :label="$tc('common.address.add-address', 1)"
                                        primary-button
                                        outline
                                        class="q-mt-md"
                                        @click="formBuilder.form.getItem('addresses').addItem()"
                                    />
                                </template>
                            </form-builder-next>
                        </card>
                    </div>
                </div>
            </div>

            <div class="col-xs-12 col-lg-6">
                <div class="row q-col-gutter-lg">
                    <div class="col-xs-12">
                        <card
                            :full-height="false"
                            :heading="$t('common.contact.contact-info')"
                            class="contact-info"
                        >
                            <div class="row q-col-gutter-md q-mb-md">
                                <div class="col-xs-12 q-pt-none visually-hidden">
                                    <h3>{{ $tc('common.email-address.email-address', formBuilder.form.getItem('emailAddresses').items.length) }}</h3>
                                </div>

                                <div class="col-xs-12">
                                    <form-builder-next :item="formBuilder.form.getItem('emailAddresses')">
                                        <template v-slot:repeater-item="{ item: repeaterItem }">
                                            <div class="row q-col-gutter-md">
                                                <div v-if="formBuilder.form.getItem('emailAddresses').items.length && repeaterItem.index > 0" class="col-xs-12 q-pt-none">
                                                    <q-separator class="q-mt-lg q-mb-none" />
                                                </div>

                                                <div class="col-xs-12 col-md-6">
                                                    <form-builder-next :item="repeaterItem.getItem('emailAddress')" :label="repeaterItem.index === 0 ? $tc('common.contact.main-email-address', 1) : $tc('common.contact.email-address', 1)" />
                                                </div>

                                                <div class="col-xs-12 col-sm-6 col-md-6">
                                                    <form-builder-next :item="repeaterItem.getItem('category')" pre-select-key="private" />
                                                </div>

                                                <div v-show="state.hasLabels.includes(repeaterItem.getItem('label').id)" class="col-xs-12 col-sm-6 col-md-6">
                                                    <form-builder-next :item="repeaterItem.getItem('label')" @fetchedOptions="options => setHasLabels(repeaterItem.getItem('label'), options)" />
                                                </div>


                                                <div class="col-xs-12">
                                                    <base-button
                                                        :label="$tc('common.email-address.delete-email-address', 1)"
                                                        class="q-mb-sm"
                                                        outline
                                                        text-color="negative"
                                                        @click="repeaterItem.remove()"
                                                    />
                                                </div>
                                            </div>
                                        </template>

                                        <!-- TODO improvement @TFU with @MTR: Add possibility to move repeater-item-buttons _inside_ repeater item (f.e. in fieldsets, cards, …) -->
                                        <template v-slot:repeater-item-buttons="{ item: repeaterItem }">
                                            <!-- Empty element needed to overwrite default content. -->
                                            <div></div>
                                        </template>

                                        <template v-slot:buttons="{ item }">
                                            <base-button
                                                :label="$tc('common.email-address.add-email-address', 1)"
                                                primary-button
                                                outline
                                                @click="item.addItem()"
                                            />
                                        </template>
                                    </form-builder-next>
                                </div>
                            </div>

                            <div class="row q-col-gutter-md q-mb-md">
                                <div class="col-xs-12 q-pt-none visually-hidden">
                                    <h3>{{ $tc('common.contact.phone-number', formBuilder.form.getItem('phoneNumbers').items.length) }}</h3>
                                </div>

                                <div class="col-xs-12">
                                    <form-builder-next :item="formBuilder.form.getItem('phoneNumbers')">
                                        <template v-slot:repeater-item="{ item: repeaterItem }">
                                            <div class="row q-col-gutter-md">
                                                <div v-if="formBuilder.form.getItem('phoneNumbers').items.length" class="col-xs-12 q-pt-none">
                                                    <q-separator class="q-mt-lg q-mb-none" />
                                                </div>

                                                <div class="col-xs-12 col-md-6">
                                                    <form-builder-next :item="repeaterItem.getItem('phoneNumber')" :label="repeaterItem.index === 0 ? $tc('common.contact.main-phone-number', 1) : $tc('common.contact.phone-number', 1)" />
                                                </div>

                                                <div class="col-xs-12 col-sm-6 col-md-6">
                                                    <form-builder-next :item="repeaterItem.getItem('category')" pre-select-key="private" />
                                                </div>

                                                <div v-show="state.hasLabels.includes(repeaterItem.getItem('label').id)" class="col-xs-12 col-sm-6 col-md-6">
                                                    <form-builder-next :item="repeaterItem.getItem('label')" @fetchedOptions="options => setHasLabels(repeaterItem.getItem('label'), options)" />
                                                </div>

                                                <div class="col-xs-12">
                                                    <base-button
                                                        :label="$tc('common.phone-number.delete-phone-number', 1)"
                                                        class="q-mb-sm"
                                                        outline
                                                        text-color="negative"
                                                        @click="repeaterItem.remove()"
                                                    />
                                                </div>
                                            </div>
                                        </template>

                                        <!-- TODO improvement @TFU with @MTR: Add possibility to move repeater-item-buttons _inside_ repeater item (f.e. in fieldsets, cards, …) -->
                                        <template v-slot:repeater-item-buttons="{ item: repeaterItem }">
                                            <!-- Empty element needed to overwrite default content. -->
                                            <div></div>
                                        </template>

                                        <template v-slot:buttons="{ item }">
                                            <base-button
                                                :label="$tc('common.phone-number.add-phone-number', 1)"
                                                primary-button
                                                outline
                                                @click="formBuilder.form.getItem('phoneNumbers').addItem()"
                                            />
                                        </template>
                                    </form-builder-next>
                                </div>
                            </div>

                            <div class="row q-col-gutter-md">
                                <div class="col-xs-12 col-sm-6">
                                    <form-builder-next :item="formBuilder.form.getItem('website')" />
                                </div>
                            </div>
                        </card>
                    </div>

                    <div class="col-xs-12">
                        <card
                            :full-height="false"
                            :heading="$tc('common.contact.customer-relation', 1)"
                            class="customer-relation"
                        >
                            <div class="col-xs-12">
                                <div class="row q-col-gutter-md">
                                    <div class="col-xs-12 col-sm-8">
                                        <form-builder-next :item="formBuilder.form.getItem('customerStatus')" />
                                    </div>

                                    <div class="col-xs-12 col-sm-4">
                                        <form-builder-next :item="formBuilder.form.getItem('customerSince')" />
                                    </div>

                                    <div class="col-xs-12">
                                        <form-builder-next :item="formBuilder.form.getItem('consultants')" @fetchedObjects="prefillConsultantId" />
                                    </div>
                                </div>
                            </div>
                        </card>
                    </div>
                </div>

            </div>
        </div>
    </form-builder-next>
</template>

<script>
import { lightFormat } from 'date-fns'
import { useStore } from '@/composables/store'
import { useRouter } from '@/composables/router'
import { useRoute } from 'vue-router/composables'
import { reactive, ref } from 'vue'
import { FormRepeater } from '@/libs/form'
import { AddressRepeaterItem } from '@/forms/contact/address'
import { EmailAddressRepeaterItem } from '@/forms/contact/emailAddress'
import { PhoneNumberRepeaterItem } from '@/forms/contact/phoneNumber'
import { CompanyForm } from '@/forms/contact/company'

export default {
    name: 'ContactCompanyCreateForm',
    setup (props, context) {
        // Composables
        const store = useStore()
        const router = useRouter()
        const route = useRoute()

        // Data
        const state = reactive({
            hasLabels: [],
        })
        const initialData = {
            addresses: [{
                category: '2', // TODO improvement @TFU: Get dynamically while refactoring to Vue3.
                country: 'CH',
                validFrom: lightFormat(new Date(), 'yyyy-MM-dd'),
            }],
        }

        // Template refs
        const formComponent = ref(null)

        // Prefill correspondenceLanguage if Tenant has only one choice configured.
        if (store.state.user.tenantSettings.correspondenceLanguages.length === 1) initialData.correspondenceLanguageId = store.state.user.tenantSettings.correspondenceLanguages[0].id

        // Form
        const form = new CompanyForm({ excludeItems: ['personalPronoun'] })

        // Addresses
        form.addRepeater(new FormRepeater('addresses', {
            repeaterItemClass: AddressRepeaterItem,
            initialData: {
                'category': '2', // TODO improvement @TFU: Get dynamically while refactoring to Vue3.
                country: 'CH',
                validFrom: lightFormat(new Date(), 'yyyy-MM-dd'),
            },
            validators: [{ type: 'minLength', params: { min: 1 }}],
        }))

        // EmailAddresses
        form.addRepeater(new FormRepeater('emailAddresses', {
            repeaterItemClass: EmailAddressRepeaterItem,
            initialData: {
                category: '2', // TODO improvement @TFU: Get dynamically while refactoring to Vue3.
            },
        }))

        // PhoneNumbers
        form.addRepeater(new FormRepeater('phoneNumbers', {
            repeaterItemClass: PhoneNumberRepeaterItem,
            initialData: {
                category: '2', // TODO improvement @TFU: Get dynamically while refactoring to Vue3.
            },
        }))

        // Prefill from querystring
        Object.keys(route.query).forEach(queryKey => {
            // If the querystring is entered manually in the browsers address bar, everything is of type string and needs to be converted if necessary. $route.query keeps the types internally.
            if (queryKey === 'poBox' && typeof route.query[queryKey] === 'string') {
                initialData.addresses[0][queryKey] = route.query[queryKey] === 'true'
            } else {
                initialData.addresses[0][queryKey] = route.query[queryKey]
            }
        })

        // Methods
        function prefillConsultantId (consultants) {
            if (consultants.length) {
                // Preselect consultant of logged-in user, if it has active consulting settings
                if (store.state.user.aclPermissions.includes('Consultant:read:own') && store.state.user.consultant?.isActive) {
                    form.getItem('consultants').value.push(store.state.user.consultant.id)
                } else if (consultants.length === 1) {
                    // Preselect the first active consultant
                    const activeConsultant = consultants.find(consultant => consultant.isActive)
                    if (activeConsultant) form.getItem('consultants').value.push(activeConsultant.id)
                }
            }
        }
        function onSubmit(formData) {
            context.emit('submit', formData)
        }
        function onCancel () {
            router.go(-1)
        }
        function showFormErrorMessage (error) {
            formComponent.value.formBuilder.showFormErrorMessage(error)
        }
        function resetFormSubmitStatus () {
            formComponent.value.formBuilder.resetFormSubmitStatus()
        }
        function setHasLabels (item, options) {
            if (options.length) {
                if (!state.hasLabels.includes(item.id)) state.hasLabels.push(item.id)
            } else {
                const index = state.hasLabels.indexOf(item.id)
                if (index > -1) state.hasLabels.splice(index, 1)
            }
        }

        return {
            form,

            // Data
            state,
            initialData,

            // Template refs
            formComponent,

            // Methods
            prefillConsultantId,
            onSubmit,
            onCancel,
            showFormErrorMessage,
            resetFormSubmitStatus,
            setHasLabels,
        }
    },
}
</script>
