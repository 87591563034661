import gql from 'graphql-tag'

export function buildDxpItemsQuery (fields, additionalParams = '') {
    return gql`
        query dxpItems(
            $page: Int = 1
            $count: Int = 200
            $orderField: DxpItemOrderField = CREATED_AT
            $orderDirection: OrderDirection = DESC
            $filterServiceProviderIds: [ID!]
            $filterDocumentTypeIdentifiers: [DxpDocumentTypeIdentifier!]
            $filterStatus: [DxpItemStatus!]
            $filterStatusOperator: SearchOperator
            ${additionalParams}
        ) {
            dxpItems(
                page: $page
                count: $count
                order: [{ field: $orderField, direction: $orderDirection }]
                search: {
                    service_provider_ids: $filterServiceProviderIds
                    document_type_identifiers: $filterDocumentTypeIdentifiers
                    status: $filterStatus
                    status_operator: $filterStatusOperator
                }
            ) {
                paginatorInfo {
                    count
                    firstItem
                    lastItem
                    total
                    currentPage
                    lastPage
                    perPage
                    hasMorePages
                }
                data {
                    ${fields}
                }
            }
        }
    `
}

const defaultQueryFields = `
    ... on DxpInboxItem {
        id
        trace_id
        status
        service_provider_id
        fileInfo {
            id
            name
            documentType {
                identifier
                version
            }
            acknowledged
            created_at
        }
        document_type_identifier
        file {
            id
            name
            size
            mime_type
            created_at
            updated_at
        }
        targetObject {
            ... on PremiumInvoice {
                id
                file_import_identification_number
                contract_number
                replaced_contract_number
                framework_agreement_number
                invoice_number
                invoice_date
                document_recipient
                customer_first_name
                customer_last_name
                customer_company_name
                files {
                    id
                    name
                    size
                    mime_type
                    created_at
                }
                positions {
                    id
                    type
                    premium_from
                    premium_to
                    sectorCode {
                        id
                        code
                        name
                    }
                    annual_premium_amount
                    invoiced_amount
                }
                contractMatchShortlist {
                    id
                    customer_name_match
                    is_fuzzy_customer_name_match
                    contract {
                        id
                        contractNumbers {
                            id
                            number
                        }
                        currentContractInformation {
                            product {
                                name
                                basicProvider {
                                    id
                                    name
                                    display_name
                                }
                            }
                        }
                        customer {
                            id
                            ... on Person {
                                first_name
                                last_name
                            }
                            ... on Company {
                                company_name
                            }
                            contactNumber {
                                number
                            }
                        }
                    }
                }
                matchedContract {
                    id
                    contractNumbers {
                        id
                        number
                    }
                    customer {
                        id
                        ... on Person {
                            first_name
                            last_name
                        }
                        ... on Company {
                            company_name
                        }
                        contactNumber {
                            number
                        }
                    }
                    currentContractInformation {
                        product {
                            name
                            basicProvider {
                                id
                                name
                                display_name
                            }
                        }
                    }
                }
                matched_contract_confirmed
            }
            ... on PremiumReminder {
                id
                contract_number
                invoice_number
                invoice_date
                invoiced_amount,
                reminder_charges_amount,
                outstanding_invoice_amount,
                reminder_date,
                customer_first_name
                customer_last_name
                customer_company_name
                files {
                    id
                    name
                    size
                    mime_type
                    created_at
                }
                contractMatchShortlist {
                    id
                    customer_name_match
                    is_fuzzy_customer_name_match
                    contract {
                        id
                        contractNumbers {
                            id
                            number
                        }
                        currentContractInformation {
                            product {
                                name
                                basicProvider {
                                    id
                                    name
                                    display_name
                                }
                            }
                        }
                        customer {
                            id
                            ... on Person {
                                first_name
                                last_name
                            }
                            ... on Company {
                                company_name
                            }
                            contactNumber {
                                number
                            }
                        }
                    }
                }
                matchedContract {
                    id
                    contractNumbers {
                        id
                        number
                    }
                    customer {
                        id
                        ... on Person {
                            first_name
                            last_name
                        }
                        ... on Company {
                            company_name
                        }
                        contactNumber {
                            number
                        }
                    }
                    currentContractInformation {
                        product {
                            name
                            basicProvider {
                                id
                                name
                                display_name
                            }
                        }
                    }
                }
                matched_contract_confirmed
            }
            ... on DxpContractLink {
                id
                type
                contract_number
                customer_first_name
                customer_last_name
                customer_company_name
                files {
                    id
                    name
                    size
                    mime_type
                    created_at
                }
                contractMatchShortlist {
                    id
                    customer_name_match
                    is_fuzzy_customer_name_match
                    contract {
                        id
                        contractNumbers {
                            id
                            number
                        }
                        currentContractInformation {
                            product {
                                name
                                basicProvider {
                                    id
                                    name
                                    display_name
                                }
                            }
                        }
                        customer {
                            id
                            ... on Person {
                                first_name
                                last_name
                            }
                            ... on Company {
                                company_name
                            }
                            contactNumber {
                                number
                            }
                        }
                    }
                }
                matchedContract {
                    id
                    contractNumbers {
                        id
                        number
                    }
                    customer {
                        id
                        ... on Person {
                            first_name
                            last_name
                        }
                        ... on Company {
                            company_name
                        }
                        contactNumber {
                            number
                        }
                    }
                    currentContractInformation {
                        product {
                            name
                            basicProvider {
                                id
                                name
                                display_name
                            }
                        }
                    }
                }
                matched_contract_confirmed
            }
        }
        currentQueueItem {
            id
            status
            status_message
            created_at
            updated_at
        }
        queueItems {
            id
            status
            status_message
            created_at
            updated_at
        }
        created_at
        updated_at
    }
    ... on DxpOutboxItem {
        id
        trace_id
        status
        service_provider_id
        fileInfo {
            id
            name
            mime_type
            documentType {
                identifier
                version
            }
            created_at
        }
        document_type_identifier
        related_inbox_item_document_type_identifier
        currentQueueItem {
            id
            status
            status_message
            created_at
            updated_at
        }
        queueItems {
            id
            status
            status_message
            created_at
            updated_at
        }
        created_at
        updated_at
    }
`

export const DXP_ITEMS_QUERY = buildDxpItemsQuery(defaultQueryFields)
