<template>
    <page-loading-indicator
        v-if="!serviceProviderConfiguration"
        :number-of-breadcrumb-elements="3"
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$t('common.eco-hub.eco-hub')" :to="{ name: 'admin-eco-hub' }" data-test="breadcrumb:admin-eco-hub" />
                <q-breadcrumbs-el :label="$tc('common.data-exchange.service-provider', 2)" :to="{ name: 'eco-hub-dxp-service-provider-configuration' }" data-test="breadcrumb:eco-hub-dxp-service-provider-configuration" />
                <q-breadcrumbs-el :label="serviceProviderConfiguration.service_provider_display_name" />
            </template>

            <h1>{{ serviceProviderConfiguration.service_provider_display_name }}</h1>
        </page-header>

        <info-box
            v-if="agreementPermissions && agreementPermissions !== 'FULL_PERMISSIONS' && serviceProviderConfiguration.supportedProcesses.length"
            type="negative"
            icon="mib-alert-triangle"
            icon-color="negative"
        >
            <p>{{ $t(`views.data-exchange.service-provider.status-message.permissions--${kebabCase(agreementPermissions)}`) }}</p>
        </info-box>

        <div class="row">
            <div class="col-12">
                <!-- Processes -->
                <q-markup-table
                    flat
                    square
                    dense
                >
                    <thead>
                        <tr>
                            <th class="text-left">{{ $tc('views.data-exchange.service-provider.data-exchange-process', 1) }}</th>
                            <th class="text-center">{{ $t('views.data-exchange.service-provider.column-title--activated') }}</th>
                            <th class="text-center">{{ $t('views.data-exchange.service-provider.column-title--automatic-processing') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="dxpProcessVersionMapping in serviceProviderConfiguration.supportedProcesses" :key="`enabled-process-${dxpProcessVersionMapping.dxpProcess.id}`">
                            <td class="text-left">{{ $t(`common.data-exchange.process.${kebabCase(dxpProcessVersionMapping.dxpProcess.id)}`) }}</td>

                            <td class="text-center">
                                <q-toggle
                                    v-model="enabledProcesses"
                                    :val="dxpProcessVersionMapping.dxpProcess.id"
                                    color="positive"
                                    checked-icon="mib-check"
                                />
                            </td>

                            <td class="text-center">
                                <q-toggle
                                    v-model="enabledForAutomaticProcessing"
                                    :val="dxpProcessVersionMapping.dxpProcess.id"
                                    :disable="disableAutomaticProcessing(dxpProcessVersionMapping.dxpProcess.id)"
                                    color="positive"
                                    checked-icon="mib-check"
                                />
                            </td>
                        </tr>
                    </tbody>
                </q-markup-table>
            </div>
        </div>

        <in-page-footer>
            <base-button
                :label="$t('common.term.back')"
                flat
                primary-button
                @click="$router.go(-1)"
            />
            <base-button
                :label="$t('common.term.save')"
                primary-button
                :disable="isSaving"
                @click="onSave"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { useQuasar } from '@/composables/quasar'
import { useRouter } from '@/composables/router'
import { getAgreementPermissions } from '@/helpers/dxp'
import { DxpServiceProviderConfiguration } from '@/models/models'
import { kebabCase } from 'lodash'
import { computed, onBeforeMount, ref, watch } from 'vue'
import { useI18n } from '@/composables/i18n'

export default {
    name: 'DxpServiceProviderConfigurationUpdate',
    meta () {
        return {
            title: !this.serviceProviderConfiguration ? `${this.$t('common.term.loading')} …` : this.serviceProviderConfiguration.service_provider_display_name,
        }
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    setup (props) {
        const router = useRouter()
        const $q = useQuasar()
        const { tc } = useI18n()

        const serviceProviderConfiguration = ref(null)
        const enabledProcesses = ref([])
        const enabledForAutomaticProcessing = ref([])
        const agreementPermissions = computed(() => {
            return getAgreementPermissions(props.id)
        })
        watch(enabledProcesses, () => {
            enabledForAutomaticProcessing.value = enabledForAutomaticProcessing.value.filter(process => enabledProcesses.value.includes(process))
        })
        function disableAutomaticProcessing (id) {
            return !enabledProcesses.value.includes(id)
        }

        // Saving
        const isSaving = ref(false)
        function onSave () {
            isSaving.value = true
            serviceProviderConfiguration.value.update({
                serviceProviderId: props.id,
                enabledProcessIds: enabledProcesses.value,
                enabledForAutomaticProcessingProcessIds: enabledForAutomaticProcessing.value,
            })
                .then(() => {
                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.data-exchange.service-provider-updated-success', 1),
                    })
                    router.push({ name: 'eco-hub-dxp-service-provider-configuration-list' })
                })
                .catch(() => {
                    $q.notify({
                        type: 'negative',
                        message: tc('common.notifications.data-exchange.service-provider-updated-error', 1),
                    })
                })
                .finally(() => {
                    isSaving.value = false
                })
        }

        // Lifecycle hooks
        onBeforeMount(() => {
            DxpServiceProviderConfiguration.objects.get(props.id).then(dxpServiceProviderConfiguration => {
                enabledProcesses.value = dxpServiceProviderConfiguration.enabledProcesses.map(process => process.id)
                enabledForAutomaticProcessing.value = dxpServiceProviderConfiguration.enabledForAutomaticProcessing.map(process => process.id)
                serviceProviderConfiguration.value = dxpServiceProviderConfiguration
            })
        })

        return {
            kebabCase,
            serviceProviderConfiguration,
            enabledProcesses,
            enabledForAutomaticProcessing,
            agreementPermissions,
            disableAutomaticProcessing,

            // Saving
            isSaving,
            onSave,
        }
    },
}
</script>
