<template>
    <q-dialog
        ref="dialog"
        no-backdrop-dismiss
        v-bind="$attrs"
        @show="onShow"
        @hide="onHide"
    >
        <q-card class="overlay-size-md">
            <q-card-section>
                <div class="row">
                    <div class="col-xs-11">
                        <h1>{{ $t('views.data-exchange.import-data-from-eco-hub') }}</h1>
                    </div>
                    <div class="col-xs-1 text-right">
                        <q-btn
                            v-close-popup
                            icon="mib-close"
                            size="xs"
                            flat
                            round
                        />
                    </div>
                </div>
            </q-card-section>

            <q-card-section>
                <dxp-file-list
                    :importable-dxp-document-type-identifiers="state.importableDxpDocumentTypeIdentifiers"
                    :dxp-document-type-identifier="state.dxpDocumentTypeIdentifier"
                    :service-provider-id="state.serviceProviderId"
                    :mode="state.mode"
                    @success="onSuccess"
                />
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script>
import DxpFileList from '@/components/dxp/DxpFileList'
import { DxpFileListDocumentTypeMode } from '@/enums'
import { DxpDocumentTypeIdentifier } from '@/enums/graphql'
import { reactive, ref } from 'vue'

export default {
    name: 'DxpFileListOverlay',
    components: {
        DxpFileList,
    },
    setup (props, context) {
        // State
        const state = reactive({
            importableDxpDocumentTypeIdentifiers: [DxpDocumentTypeIdentifier.BILLING, DxpDocumentTypeIdentifier.REMINDER],
            dxpDocumentTypeIdentifier: '',
            documentTypeMode: DxpFileListDocumentTypeMode.SELECT,
            serviceProviderId: null,
        })
        function resetState () {
            state.dxpDocumentTypeIdentifier = ''
            state.documentTypeMode = DxpFileListDocumentTypeMode.SELECT
            state.serviceProviderId = null
        }

        // Dialog
        const dialog = ref(null)
        function open ({
            importableDxpDocumentTypeIdentifiers,
            dxpDocumentTypeIdentifier,
            documentTypeMode,
            serviceProviderId,
        } = {
            importableDxpDocumentTypeIdentifiers: [DxpDocumentTypeIdentifier.BILLING, DxpDocumentTypeIdentifier.REMINDER],
            dxpDocumentTypeIdentifier: '',
            documentTypeMode: DxpFileListDocumentTypeMode.SELECT,
            serviceProviderId: null,
        }) {
            state.importableDxpDocumentTypeIdentifiers = importableDxpDocumentTypeIdentifiers
            state.dxpDocumentTypeIdentifier = dxpDocumentTypeIdentifier
            state.documentTypeMode = documentTypeMode
            state.serviceProviderId = serviceProviderId

            dialog.value.show()
        }
        function close () {
            dialog.value.hide()
        }
        function onShow () {
            context.emit('show')
        }
        function onHide () {
            resetState()
            context.emit('hide')
        }
        function onSuccess () {
            close()
            context.emit('success')
        }

        return {
            // State
            state,

            // Dialog
            dialog,
            open,
            close,
            onShow,
            onHide,
            onSuccess,
        }
    },
}
</script>
