<template>
    <fake-select
        v-if="!options.length"
        v-bind="$attrs"
    />
    <base-select-filter
        v-else

        :value="value"
        v-bind="$attrs"
        :options="options"

        option-value="service_provider_id"
        option-label="service_provider_display_name"
        @input="inputValue => $emit('input', inputValue)"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" /><!-- Named slots -->
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template><!-- Scoped slots -->
    </base-select-filter>
</template>

<script>
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { DxpServiceProviderConfigurationService } from '@/services'
import FakeSelect from '@/components/FakeSelect.vue'
import { onBeforeMount, ref, watch } from 'vue'

export default {
    name: 'DxpServiceProviderSelect',
    components: {
        FakeSelect,
        BaseSelectFilter,
    },
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
        filter: {
            type: Object,
        },
        filterFn: {
            type: Function,
            default: null,
        },
    },
    setup (props, context) {
        const options = ref([])
        watch(() => props.value, (id) => {
            const option = options.value.find(option => option.service_provider_id === id)
            context.emit('select', option ?? null)
        })

        onBeforeMount(() => {
            const variables = {};
            if (props.filter) Object.assign(variables, props.filter)

            DxpServiceProviderConfigurationService.all(variables).then(response => {
                options.value = props.filterFn ? props.filterFn(response.data) : response.data
            })
        })

        return {
            options,
        }
    },
}
</script>
