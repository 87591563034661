<template>
    <div>
        <page-header no-breadcrumbs>
            <h2>{{ $tc('views.contract.settings.commission.commission-setting', 2) }}</h2>
        </page-header>

        <div class="row q-col-gutter-md">
            <grid-card
                :columns="{ sm: 12 }"
                :heading="$t('views.contract.settings.commission.commission-recipient-override.commission-recipient-override-title')"
                :heading-info="$t('views.contract.settings.commission.commission-recipient-override.commission-recipient-override--description')"
            >
                <info-box
                    inline-actions
                    :type="hasOverride ? 'warning' : 'info'"
                    no-margin
                >
                    <template v-if="hasOverride">
                        <p>{{ $t('views.contract.settings.commission.commission-recipient-override.contract-has-override-text') }}</p>
                        <p>{{ $tc(`views.contract.settings.commission.commission-recipient-override.specified-commission-recipient--${currentCommissionRecipient.gender.toLowerCase()}`, 1) }}: <b>{{ currentCommissionRecipient.getContactName({ consultingCompanyName: !currentCommissionRecipient.worksForUserConsultingCompany }) }}</b></p>
                    </template>

                    <template v-else>
                        <p>{{ $t('views.contract.settings.commission.commission-recipient-override.contract-does-not-have-override-text') }}</p>
                        <p>{{ $tc(`common.contact.consultant--assigned-consultant--${currentCommissionRecipient.gender.toLowerCase()}`, 1) }} / {{ $tc(`views.contract.settings.commission.commission-recipient-override.commission-recipient--${currentCommissionRecipient.gender.toLowerCase()}`, 1) }}: <b>{{ currentCommissionRecipient.getContactName({ consultingCompanyName: !currentCommissionRecipient.worksForUserConsultingCompany }) }}</b></p>
                    </template>

                    <template v-slot:action>
                        <!-- TODO improvement: Change button action to "reset to default" if there is an override. (Set `formData.commissionRecipientOverrideId` to null and submit form.) -->
                        <base-button
                            outline
                            data-test="btn:add"
                            :label="$tc('views.contract.settings.commission.commission-recipient-override.change-commission-recipient', 1)"
                            @click="toggleCreateFormVisibility"
                        />
                    </template>
                </info-box>

                <div v-if="createFormIsVisible" class="row">
                    <div class="col-xs-12 q-mt-md">
                        <commission-recipient-override-form
                            ref="commissionRecipientOverrideForm"
                            :contract="contract"
                            @submit="handleSubmit"
                            @cancel="toggleCreateFormVisibility"
                        />
                    </div>
                </div>
            </grid-card>

            <grid-card
                :columns="{ sm: 12 }"
                :heading="$tc('views.contract.settings.commission.commission-splitting.commission-splitting', 1)"
                :heading-info="$t('views.contract.settings.commission.commission-splitting.commission-splitting--description')"
            >
                <commission-split-manage :contract="contract" />

                <p class="q-mt-sm">
                    <small><a @click="$refs.activityLogOverlay.open({ filterSubjects: contract.commissionSplits.map(commissionSplit => ({ id: commissionSplit.id, type: ActivityLogSubjectType.COMMISSION_SPLIT })) })">{{ $tc('common.change-log.show-change-log', 1) }}</a></small>
                </p>
            </grid-card>
        </div>

        <!-- Activity log -->
        <activity-log-overlay ref="activityLogOverlay" />
    </div>
</template>

<script>
import { createProductFieldsNameValuePairs } from '@/helpers/contract'
import { ActivityLogSubjectType, ContractManagedByStatus } from '@/enums/graphql'
import CommissionSplitManage from '@/components/commissionSplits/CommissionSplitManage'
import CommissionRecipientOverrideForm from '@/components/commission/commissionRecipientOverride/CommissionRecipientOverrideForm.vue'
import ActivityLogOverlay from '@/components/ActivityLogOverlay'

export default {
    name: 'ContractCommissionSettings',
    components: {
        CommissionSplitManage,
        CommissionRecipientOverrideForm,
        ActivityLogOverlay,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        contactId: {
            type: String,
            required: true,
        },
        contract: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            ActivityLogSubjectType,
            createFormIsVisible: false,
        }
    },
    computed: {
        hasOverride () {
            return !!this.contract.commissionRecipientOverride
        },
        currentCommissionRecipient () {
            return this.contract.commissionRecipientOverride || this.contract.consultant
        },
    },
    methods: {
        toggleCreateFormVisibility () {
            this.createFormIsVisible = !this.createFormIsVisible
        },
        handleSubmit (formData) {
            const productFields = createProductFieldsNameValuePairs(this.contract)
            const variables = {
                productFields: productFields,
                consultantId: this.contract.consultant.id,
                startDate: this.contract.currentContractInformation.start_date,
                endDate: this.contract.currentContractInformation.end_date,
                premium: this.contract.currentContractInformation.premium,
                managedByStatus: this.contract.currentContractInformation.managed_by_status,
                commissionRecipientOverrideId: formData.commissionRecipientOverrideId,
            }
            if ([ContractManagedByStatus.CONSULTING_MANDATE_SUBMISSION_PENDING, ContractManagedByStatus.CONSULTING_MANDATE_CANCELLATION_PENDING].includes(this.contract.currentContractInformation.managed_by_status) && this.contract.currentContractInformation.consulting_mandate_enquiry_send_date) {
                variables.consultingMandateEnquirySendDate = this.contract.currentContractInformation.consulting_mandate_enquiry_send_date
            }
            this.contract.update(variables).then(() => {
                this.$q.notify({
                    type: 'positive',
                    message: this.$tc('common.notifications.contract.commission-recipient-override-success', 1),
                })
            }).catch(error => {
                this.$q.notify({
                    type: 'negative',
                    message: this.$tc('common.term.error', 1),
                    caption: this.$tc('common.notifications.contract.commission-recipient-override-error', 1),
                })
            }).finally(() => {
                this.$refs.commissionRecipientOverrideForm.resetFormSubmitStatus()
                this.toggleCreateFormVisibility()
            })
        },
    },
}
</script>




