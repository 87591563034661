import gql from 'graphql-tag'

export const PREMIUM_REMINDER_QUERY = gql`
    query premiumReminder(
        $id: ID!
    ) {
        premiumReminder(
            id: $id
        ) {
            id
            file_import_identification_number
            contract_number
            framework_agreement_number
            invoice_number
            invoice_date
            document_recipient
            sectorCode {
                id
                code
                name
            }
            product_provider_sector_code
            currency
            invoiced_amount
            reminder_charges_amount
            outstanding_invoice_amount
            commission_calculation_basis
            reminder_date
            coverage_interruption_from
            customer_first_name
            customer_last_name
            customer_company_name
            files {
                id
                name
                size
                mime_type
                created_at
            }
            contractMatchShortlist {
                id
                customer_name_match
                is_fuzzy_customer_name_match
                contract {
                    id
                    contractNumbers {
                        id
                        number
                    }
                    currentContractInformation {
                        product {
                            name
                            basicProvider {
                                id
                                name
                                display_name
                            }
                        }
                    }
                    customer {
                        id
                        ... on Person {
                            first_name
                            last_name
                        }
                        ... on Company {
                            company_name
                        }
                        contactNumber {
                            number
                        }
                    }
                }
            }
            matchedContract {
                id
                contractNumbers {
                    id
                    number
                }
                customer {
                    id
                    ... on Person {
                        first_name
                        last_name
                    }
                    ... on Company {
                        company_name
                    }
                    contactNumber {
                        number
                    }
                }
                currentContractInformation {
                    product {
                        name
                        basicProvider {
                            id
                            name
                            display_name
                        }
                    }
                }
            }
            matched_contract_confirmed
            created_at
            updated_at
        }
    }
`
