<template>
    <page-wrapper>
        <!--
            Improvements:
            [ ] Selection/Bulk editing
                [ ] bulk acknowledge and/or "acknowledge all" option
                [ ] bulk download and/or "download unacknowledged" option
            [ ] Make document types directly accessible with according routes
        -->
        <page-header>
            <h1>{{ $tc('common.drive.drive', 1) }}</h1>
        </page-header>

        <div class="drive-wrapper">
            <div class="row q-col-gutter-md">
                <div class="col-xs-12 col-md-3 col-lg-2 file-type-filter">
                    <!-- TODO improvement: Make in-page-navigation sticky. -->
                    <in-page-navigation
                        v-if="filteredFileTypes.length"
                        v-model="filters.currentFileType"
                    >
                        <q-tab
                            :name="null"
                            :label="$tc('common.consulting-file.type.all', 1)"
                        />
                        <!-- TODO @TFU: Hide file types that the User does not have access to. -->
                        <template v-for="(fileType, index) in filteredFileTypes">
                            <q-tab
                                :key="index"
                                :name="fileType"
                            >
                                {{ $tc(`common.drive.file-type.${kebabCase(fileType)}`, 2) }}
                                <q-badge v-if="getTotalNotYetAcknowledgedItemsPerType(fileType) > 0" class="q-ml-xs" color="accent">
                                    {{ getTotalNotYetAcknowledgedItemsPerType(fileType) }}
                                    <q-tooltip
                                        :delay="1000"
                                        :offset="[4, 0]"
                                        anchor="center end"
                                        self="center start"
                                    >{{ $tc(`common.drive.file-type.${kebabCase(fileType)}--unacknowledged`, getTotalNotYetAcknowledgedItemsPerType(fileType)) }}
                                    </q-tooltip>
                                </q-badge>
                            </q-tab>
                        </template>
                    </in-page-navigation>
                    <skeleton
                        v-else
                        square
                        width="100%"
                        height="4rem"
                    />
                </div>

                <div class="col-xs-12 col-md-9 col-lg-10 file-list">
                    <base-table
                        ref="table"
                        table-class="expandable-rows"
                        :columns="columns"
                        :fetch-objects-fn="Drive.objects.all"
                        :fetch-objects-get-paginator-fn="response => response.fileStorageItems.paginatorInfo"
                        :fetch-objects-get-data-fn="response => response.fileStorageItems.data"
                        :additional-filters="additionalFilters"
                        user-settings-base-path="DriveFileStorageItems"
                        pagination-sort-by-default-key="created_at"
                        pagination-initial-order-direction-descending
                        :show-filter="false"
                        :selected.sync="selected"
                        :expanded.sync="expanded"
                        @update:response="updateFileTypesAndMetaData"
                    >
                        <template v-slot:quickfilters>
                            <div class="q-gutter-x-sm">
                                <p class="inline-block q-mb-none">{{ $tc('common.term.quick-filter', 2) }}:</p>
                                <base-button-toggle
                                    v-model="quickFilters.onlyMine"
                                    :options="[{ label: $t('views.drive.quick-filter--only-mine'), value: true }]"
                                    clearable
                                />
                                <base-button-toggle
                                    v-model="quickFilters.acknowledged"
                                    :options="[{ label: $t('views.drive.quick-filter--only-unacknowledged'), value: false }]"
                                    clearable
                                />

                                <!-- TODO improvement: Move to separate row if more "real" quick filters should be implemented. -->
                                <base-button
                                    :label="$t('common.term.toggle--expand-all')"
                                    flat
                                    @click="expandAll"
                                />
                                <base-button
                                    :label="$t('common.term.toggle--collapse-all')"
                                    flat
                                    @click="collapseAll"
                                />
                            </div>
                        </template>

                        <template v-slot:body="slotProps">
                            <q-tr
                                :props="slotProps"
                                :class="['expanded-row-parent cursor-pointer', { 'expanded': slotProps.expand, 'unacknowledged': !slotProps.row.acknowledged_at }]"
                                @dblclick="expandedClick(slotProps)"
                            >
                                <q-td
                                    v-for="col in getCols(slotProps)"
                                    :key="col.name"
                                    :props="slotProps"
                                >
                                    {{ col.value }}
                                </q-td>

                                <q-td class="q-pr-xs" auto-width>
                                    <q-btn
                                        color="primary"
                                        size="sm"
                                        flat
                                        round
                                        icon="mib-cloud-download"
                                        @click="triggerDownloadFilesPackage(slotProps.row)"
                                    >
                                        <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.term.download', 1) }}</q-tooltip>
                                    </q-btn>

                                    <q-btn
                                        class="overflow-hidden"
                                        color="primary"
                                        size="sm"
                                        flat
                                        round
                                        icon="mib-navigation-menu-horizontal"
                                        data-test="btn:tooltip-actions"
                                    >
                                        <q-tooltip
                                            :delay="1000"
                                            :offset="[0, 10]"
                                        >
                                            {{ $t('common.term.more') }}
                                        </q-tooltip>
                                        <q-menu anchor="top left" self="top right">
                                            <div class="q-pl-xs q-pr-xs">
                                                <q-btn
                                                    color="primary"
                                                    size="sm"
                                                    flat
                                                    round
                                                    icon="mib-common-file-check"
                                                    @click="triggerSetAcknowledgeAt(slotProps.row)"
                                                >
                                                    <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.drive.acknowledge--action-text', 1) }}</q-tooltip>
                                                </q-btn>
                                            </div>
                                        </q-menu>
                                    </q-btn>

                                    <q-btn
                                        class="expand-button"
                                        size="sm"
                                        flat
                                        color="secondary"
                                        :ripple="false"
                                        :icon="slotProps.expand ? 'mib-arrow-down-1' : 'mib-arrow-left-1'"
                                        @click="expandedClick(slotProps)"
                                    >
                                        <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc(`common.term.${slotProps.expand ? 'hide' : 'show'}-details`, 1) }}</q-tooltip>
                                    </q-btn>
                                </q-td>
                            </q-tr>

                            <q-tr v-show="slotProps.expand" class="expanded-row" :props="slotProps">
                                <q-td colspan="100%">
                                    <div class="row q-col-gutter-sm q-pt-sm q-pb-md">
                                        <div class="col-xs-12 col-md-6">
                                            <template v-if="slotProps.row.type === FileStorageItemType.STATEMENT">
                                                <dl>
                                                    <div>
                                                        <dt>{{ $tc(`common.contact.consulting-${kebabCase(slotProps.row.targetObject.consultingEntity.type)}`, 1) }}: </dt>
                                                        <dd>{{ slotProps.row.targetObject.consultingEntity.getContactName() }} <span v-if="slotProps.row.targetObject.consultingEntity.consulting_company_name" class="font-weight-normal">({{ slotProps.row.targetObject.consultingEntity.consulting_company_name }})</span></dd>
                                                    </div>

                                                    <div>
                                                        <dt>{{ $tc('common.commission.accounting-period', 1) }}: </dt>
                                                        <dd>{{ slotProps.row.targetObject.formattedPeriod }}</dd>
                                                    </div>
                                                </dl>
                                            </template>

                                            <template v-else-if="slotProps.row.type === FileStorageItemType.ACCOUNT_STATEMENT_REPORT">
                                                <dl>
                                                    <div>
                                                        <dt>{{ $tc('common.accounting.account.account', slotProps.row.targetObject.accountStatementReportAccounts.length || 2) }}: </dt>
                                                        <!-- TODO improvement: Expansion item (or similar) with full list of accounts including account.name -->
                                                        <dd>{{ slotProps.row.targetObject.formattedAccountList.teaser ? slotProps.row.targetObject.formattedAccountList.teaser.short : slotProps.row.targetObject.formattedAccountList.accounts.toString() }}
                                                            <info-icon v-if="slotProps.row.targetObject.formattedAccountList.teaser" :text="slotProps.row.targetObject.formattedAccountList.accounts.toString()" />
                                                        </dd>
                                                    </div>
                                                    <div>
                                                        <dt>{{ $tc('common.term.period', 1) }}: </dt>
                                                        <dd>{{ slotProps.row.targetObject.formattedPeriod }}</dd>
                                                    </div>
                                                </dl>
                                            </template>

                                            <template v-else-if="slotProps.row.type === FileStorageItemType.ACCOUNT_BALANCE_REPORT">
                                                <dl>
                                                    <div>
                                                        <dt>{{ $tc('common.accounting.account.account', slotProps.row.targetObject.accountBalanceReportAccounts.length || 2) }}: </dt>
                                                        <!-- TODO improvement: Expansion item (or similar) with full list of accounts including account.name -->
                                                        <dd>{{ slotProps.row.targetObject.formattedAccountList.teaser ? slotProps.row.targetObject.formattedAccountList.teaser.short : slotProps.row.targetObject.formattedAccountList.accounts.toString() }}
                                                            <info-icon v-if="slotProps.row.targetObject.formattedAccountList.teaser" :text="slotProps.row.targetObject.formattedAccountList.accounts.toString()" />
                                                        </dd>
                                                    </div>
                                                    <div>
                                                        <dt>{{ $tc('common.term.period', 1) }}: </dt>
                                                        <dd>{{ slotProps.row.targetObject.formattedPeriod }}</dd>
                                                    </div>
                                                </dl>
                                            </template>
                                        </div>

                                        <div class="col-xs-12 col-md-6">
                                            <!-- TODO improvement @MTR: Create file library component. -->
                                            <div class="file-library-wrapper">
                                                <q-card
                                                    v-for="(file, index) in slotProps.row.files"
                                                    :key="index"
                                                    class="file-library-item"
                                                    square
                                                    flat
                                                    bordered
                                                    data-test="item:drive"
                                                >
                                                    <q-card-section horizontal>
                                                        <q-item class="items-start">
                                                            <q-item-section side>
                                                                <q-avatar square>
                                                                    <q-icon
                                                                        v-if="file.mime_type"
                                                                        :name="getFileTypeIcon(file.mime_type)"
                                                                        size="sm"
                                                                        color="secondary"
                                                                    />
                                                                </q-avatar>
                                                            </q-item-section>

                                                            <q-item-section>
                                                                <q-item-label class="file-name" data-test="text:drive-file-name">{{ file.name }}</q-item-label>
                                                                <q-item-label v-if="file.size" caption data-test="text:drive-file-size">{{ humanStorageSize(file.size) }}</q-item-label>
                                                            </q-item-section>
                                                        </q-item>

                                                        <q-card-actions vertical>
                                                            <q-btn
                                                                color="primary"
                                                                size="sm"
                                                                flat
                                                                round
                                                                icon="mib-cloud-download"
                                                                @click="triggerFileDownload(file, slotProps.row)"
                                                            >
                                                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.term.download-item', 1, { item: file.name }) }}</q-tooltip>
                                                            </q-btn>
                                                        </q-card-actions>
                                                    </q-card-section>
                                                </q-card>
                                            </div>
                                        </div>
                                    </div>
                                </q-td>
                            </q-tr>
                        </template>
                    </base-table>
                </div>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import { Drive, FileStorageItem, TemporaryDownloadFile } from '@/models/models'
import { SearchOperator, FileStorageItemType } from '@/enums/graphql'
import { getFileTypeIcon, humanStorageSize } from '@/helpers/file'
import { UserSettings } from '@/helpers/user'

import InPageNavigation from '@/components/InPageNavigation'
import BaseTable from '@/components/BaseTable'
import BaseButtonToggle from '@/components/form/BaseButtonToggle'
import { EventBus } from '@/event-bus'

export default {
    name: 'Drive',
    components: {
        InPageNavigation,
        BaseTable,
        BaseButtonToggle,
    },
    meta () {
        return {
            title: this.$tc('common.drive.drive', 1),
        }
    },
    data () {
        const userSettings = new UserSettings(this.$store.state.user)
        return {
            userSettings,
            Drive,
            FileStorageItem,
            FileStorageItemType,
            TemporaryDownloadFile,
            drive: null,
            fileTypes: [],
            totalOfItemsPerType: null,
            totalOfNotYetAcknowledgedItemsPerType: null,
            fileStorageItemsTotalOfItemsPerType: null,
            fileStorageItemsTotalOfNotYetAcknowledgedItemsPerType: null,
            expanded: [],
            selected: [],
            filters: {
                currentFileType: null,
                statement: {
                    consulting_entity_ids: [this.$store.state.user.consultant.id],
                    consulting_entity_ids_operator: SearchOperator.EQUAL_TO,
                },
            },
            quickFilters: {
                onlyMine: userSettings.getItem('Drive.quickFilters.onlyMine') === 'true' ? true : null,
                acknowledged: userSettings.getItem('Drive.quickFilters.acknowledged') === 'false' ? false : null,
            },
            columns: [
                {
                    name: 'created_at',
                    label: this.$tc('common.term.added-on'),
                    align: 'left',
                    field: row => row.formattedCreatedAtFull,
                    sortable: true,
                    required: true,
                    classes: row => !row.acknowledged_at ? 'text-bold' : null,
                },
                {
                    name: 'type',
                    label: this.$tc('common.term.type'),
                    align: 'left',
                    field: row => this.$tc(`common.drive.file-type.${kebabCase(row.type)}`, 1),
                    sortable: true,
                    required: true,
                    classes: row => !row.acknowledged_at ? 'text-bold' : null,
                },
                {
                    name: 'owner',
                    label: this.$tc('common.term.owner'),
                    align: 'left',
                    field: row => row.targetObject.consultingEntity?.getContactName({ consultingCompanyName: !row.targetObject.consultingEntity.worksForUserConsultingCompany }) || this.$store.state.user.consultant.getContactName(),
                },
                {
                    name: 'acknowledged_at',
                    label: this.$tc('common.drive.acknowledged-at', 1),
                    align: 'left',
                    field: row => row.formattedAcknowledgedAt || this.$t('common.drive.unacknowledged'),
                    sortable: true,
                    classes: row => !row.acknowledged_at ? 'additional-info' : null,
                },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left', required: true },
            ],
        }
    },
    computed: {
        filteredFileTypes () {
            let fileTypes = this.fileTypes

            if (!this.$can('Accounting:manage')) {
                fileTypes = fileTypes.filter(fileType => ![FileStorageItemType.ACCOUNT_STATEMENT_REPORT, FileStorageItemType.ACCOUNT_BALANCE_REPORT].includes(fileType))
            }

            return fileTypes
        },
        additionalFilters () {
            let filterVariables = {
                search: {},
            }

            if (this.filters.currentFileType) {
                filterVariables.search.type = [this.filters.currentFileType]
            }

            if (this.quickFilters.onlyMine !== null) {
                filterVariables.search.statement = {
                    ...this.filters.statement,
                }
            }

            if (this.quickFilters.acknowledged !== null) filterVariables.search.acknowledged = this.quickFilters.acknowledged

            return filterVariables
        },
    },
    watch: {
        'quickFilters.onlyMine' (newValue) {
            if (newValue !== null) {
                this.userSettings.setItem('Drive.quickFilters.onlyMine', newValue)
            } else {
                this.userSettings.removeItem('Drive.quickFilters.onlyMine')
            }
        },
        'quickFilters.acknowledged' (newValue) {
            if (newValue !== null) {
                this.userSettings.setItem('Drive.quickFilters.acknowledged', newValue)
            } else {
                this.userSettings.removeItem('Drive.quickFilters.acknowledged')
            }
        },
    },
    created () {
        EventBus.$on('shortcut:expandAll', this.expandAll)
        EventBus.$on('shortcut:collapseAll', this.collapseAll)
    },
    beforeDestroy () {
        EventBus.$off('shortcut:expandAll', this.expandAll)
        EventBus.$off('shortcut:collapseAll', this.collapseAll)
    },
    methods: {
        kebabCase: function (text) {
            return kebabCase(text)
        },
        getTotalItemsPerType (fileType) {
            return this.totalOfItemsPerType[fileType]
        },
        getTotalNotYetAcknowledgedItemsPerType (fileType) {
            return this.totalOfNotYetAcknowledgedItemsPerType[fileType]
        },
        getFileStorageItemsTotalItemsPerType (fileType) {
            return this.fileStorageItemsTotalOfItemsPerType[fileType]
        },
        getFileStorageItemsTotalNotYetAcknowledgedItemsPerType (fileType) {
            return this.fileStorageItemsTotalOfNotYetAcknowledgedItemsPerType[fileType]
        },
        updateFileTypesAndMetaData (response) {
            this.totalOfNotYetAcknowledgedItemsPerType = response.total_of_not_yet_acknowledged_items_per_type
            this.totalOfItemsPerType = response.total_of_items_per_type

            this.fileStorageItemsTotalOfNotYetAcknowledgedItemsPerType = response.fileStorageItems.total_of_not_yet_acknowledged_items_per_type
            this.fileStorageItemsTotalOfItemsPerType = response.fileStorageItems.total_of_items_per_type

            this.fileTypes = Object.keys(this.totalOfItemsPerType)
        },
        expandedClick (props) {
            if (this.expanded.includes(props.key)) {
                this.expanded = this.expanded.filter(key => key !== props.key)
            } else {
                this.expanded.push(props.key)
            }
        },
        expandAll () {
            this.$refs.table.items.forEach(item => {
                if (!this.expanded.includes(item.id)) this.expanded.push(item.id)
            })
        },
        collapseAll () {
            this.$refs.table.items.forEach(item => {
                this.expanded = this.expanded.filter(id => id !== item.id)
            })
        },
        getCols (props) {
            props.cols.pop()
            return props.cols
        },
        triggerDownloadFilesPackage (fileStorageItem) {
            fileStorageItem.downloadFilesPackage().then(() => {
                this.triggerSetAcknowledgeAt(fileStorageItem)
            })
        },
        triggerFileDownload (file, fileStorageItem) {
            file.download()
            fileStorageItem.setAcknowledgeAt().then(() => {
                this.$refs.table.fetchObjects()
            })
        },
        triggerSetAcknowledgeAt (fileStorageItem) {
            fileStorageItem.setAcknowledgeAt().then(() => {
                this.$refs.table.fetchObjects()
            })
        },
        getFileTypeIcon (...args) {
            return getFileTypeIcon(...args)
        },
        humanStorageSize (...args) {
            return humanStorageSize(...args)
        },
    },
}
</script>

<style lang="scss" scoped>
.file-library-wrapper {
    --file-library-items-per-line: 1;
    --column-gap: #{$sizeSpacingSm};
    --row-gap: #{$sizeSpacingSm};

    //@media screen and (min-width: $sizeBreakpointSm), print {
    //    --file-library-items-per-line: 2;
    //}
    //
    //@media screen and (min-width: $sizeBreakpointMd), print {
    //    --column-gap: #{$sizeSpacingMd};
    //    --row-gap: #{$sizeSpacingMd};
    //}
    //
    //@media screen and (min-width: $sizeBreakpointFormBuilderLg), print {
    //    --file-library-items-per-line: 3;
    //}
    //
    //@media screen and (min-width: $sizeBreakpointXl + $sizeAppDrawerRightWidth), print and (min-width: 150mm) {
    //    --file-library-items-per-line: 4;
    //}

    display: grid;
    align-items: stretch;
    grid-template-columns: repeat(var(--file-library-items-per-line), 1fr);
    column-gap: var(--column-gap);
    row-gap: var(--row-gap);
}

.file-library-item.file-library-item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    transition: all 1s $defaultTransitionTimingFunction 0s;

    &.delete {
        border-style: dashed;
        border-color: var(--q-color-negative-light);
        background-color: var(--q-color-negative-lighter);
    }

    & > .q-card__section {
        width: 100%;
        justify-content: space-between;
    }

    .file-name {
        white-space: normal;
    }
}
</style>
