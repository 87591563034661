import gql from 'graphql-tag'

export const PREMIUM_INVOICES_QUERY = gql`
    query premiumInvoices(
        $page: Int = 1
        $count: Int = 1000
        $orderField: PremiumInvoiceOrderField = CREATED_AT
        $orderDirection: OrderDirection = DESC
        $filterMatchedContractIds: [ID!]
        $filterMatchedContractConfirmed: Boolean!
        $invoiceDateFrom: Date
        $invoiceDateUntil: Date
    ) {
        premiumInvoices(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                matched_contract_ids: $filterMatchedContractIds
                matched_contract_confirmed: $filterMatchedContractConfirmed
                invoice_date_from: $invoiceDateFrom
                invoice_date_until: $invoiceDateUntil
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                file_import_identification_number
                contract_number
                replaced_contract_number
                framework_agreement_number
                invoice_number
                invoice_date
                document_recipient
                customer_first_name
                customer_last_name
                customer_company_name
                files {
                    id
                    name
                    size
                    created_at
                }
                positions {
                    id
                    type
                    premium_from
                    premium_to
                    premium_kind
                    premium_type
                    sectorCode {
                        id
                    }
                    annual_premium_amount
                    instalment_surcharge
                    taxes_statutory_charges
                    invoiced_amount
                }
                contractMatchShortlist {
                    id
                }
                matchedContract {
                    id
                }
                created_manually
                created_at
                updated_at
            }
        }
    }
`
