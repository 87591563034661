<template>
    <q-dialog
        ref="dialog"
        v-bind="$attrs"
        position="top"
        @show="onShow"
        @hide="onHide"
    >
        <q-card v-if="dxpInboxItem" class="overlay-size-md">
            <q-card-section>
                <slot name="header"><h1>{{ overlayTitle }}</h1></slot>

                <q-table
                    :columns="tableColumns"
                    :data="tableData"
                    :pagination="{ rowsPerPage: 0 }"
                    hide-pagination
                    row-key="id"
                    class="base-table matched-contract-table"
                    flat
                    square
                    dense
                >
                    <template v-slot:body-cell-source="slotProps">
                        <q-td :props="slotProps">
                            <!-- Show different text if there already is a matched contract but it’s not this one -->
                            <template v-if="slotProps.row.source !== TableDataSource.IMPORT && targetObject.matchedContract && targetObject.matchedContract.id !== slotProps.row.contract.id">
                                {{ $tc(`views.data-exchange.matched-contract.source--${slotProps.row.source.toLowerCase()}--other`, 1, { entity: $tc(`common.data-exchange.process--entity-name.the.${kebabCase(dxpInboxItem.document_type_identifier)}`, 1) }) }}
                            </template>
                            <template v-else>
                                {{ $tc(`views.data-exchange.matched-contract.source--${slotProps.row.source.toLowerCase()}`, 1, { entity: $tc(`common.data-exchange.process--entity-name.${kebabCase(dxpInboxItem.document_type_identifier)}`, 1) }) }}
                            </template>
                        </q-td>
                    </template>

                    <!-- TODO improvement @MTR: Add the same/similar highlighting of contract number and customer name as in the CommissionListDetail list -->
                    <template v-slot:body-cell-provider="slotProps">
                        <q-td :props="slotProps">
                            <span v-if="slotProps.row.source === TableDataSource.IMPORT">
                                {{ dxpInboxItem.service_provider_display_name }}
                            </span>

                            <span v-else :class="getProductProviderClasses(slotProps.row)">{{ slotProps.row.contract.currentContractInformation.product.basicProvider.display_name || slotProps.row.contract.currentContractInformation.product.basicProvider.name }}</span>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-customer_name="slotProps">
                        <q-td :props="slotProps">
                            {{ slotProps.row.contract.customer.getContactName({ commaSeparated: true }) }}
                            <span v-if="slotProps.row.contract.customer.contactNumber" class="additional-info">({{ slotProps.row.contract.customer.contactNumber.number }})</span>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-actions="slotProps">
                        <q-td auto-width>
                            <template v-if="slotProps.row.source !== TableDataSource.IMPORT && mode === DxpInboxItemTargetObjectMatchedContractUpdateOverlayMode.UPDATE">
                                <template v-if="targetObject.matchedContract && targetObject.matchedContract.id === slotProps.row.contract.id">
                                    <q-btn
                                        color="primary"
                                        size="sm"
                                        flat
                                        round
                                        icon="mib-undo"
                                        :disabled="!enableSelectContractButton(slotProps.row)"
                                        @click="resetContract"
                                    />
                                    <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.data-exchange.matched-contract.reassign-to-contract', 1, { entity: $tc(`common.data-exchange.process--entity-name.${kebabCase(dxpInboxItem.document_type_identifier)}`, 1) }) }}</q-tooltip>
                                </template>

                                <template v-else>
                                    <q-btn
                                        color="primary"
                                        size="sm"
                                        flat
                                        round
                                        icon="mib-check"
                                        :disabled="!enableSelectContractButton(slotProps.row)"
                                        @click="selectContract(slotProps.row.contract)"
                                    />
                                    <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.data-exchange.matched-contract.assign-to-contract', 1, { entity: $tc(`common.data-exchange.process--entity-name.${kebabCase(dxpInboxItem.document_type_identifier)}`, 1) }) }}</q-tooltip>
                                </template>
                            </template>
                        </q-td>
                    </template>
                </q-table>
            </q-card-section>

            <q-card-section v-if="mode === DxpInboxItemTargetObjectMatchedContractUpdateOverlayMode.UPDATE">
                <h4>{{ $tc('views.data-exchange.matched-contract.search-contract', 1) }} <info-icon :text="$t('views.data-exchange.matched-contract.search-contract--info')" /></h4>
                <fetch-contracts-search :enabled-result-types="['Contract']" @open-search-result="selectContract" />
            </q-card-section>

            <q-card-section v-if="mode === DxpInboxItemTargetObjectMatchedContractUpdateOverlayMode.CONFIRM">
                <info-box no-margin>
                    <i18n path="views.data-exchange.matched-contract.selected-contract--confirm" tag="p">
                        <template v-slot:entity>{{ $tc(`common.data-exchange.process--entity-name.the.${kebabCase(dxpInboxItem.document_type_identifier)}`, 1) }}</template>
                        <template v-slot:contract><b>{{ targetObject.matchedContract.currentContractNumber }}</b></template>
                        <template v-slot:customerName><b>{{ targetObject.matchedContract.customer.getContactName({ contactNumber: true }) }}</b></template>
                    </i18n>
                </info-box>
            </q-card-section>

            <q-card-section v-if="selectedContract">
                <info-box no-margin>
                    <dl>
                        <dt>{{ $t('views.data-exchange.matched-contract.selected-contract') }}: </dt>
                        <dd>
                            {{ selectedContract.currentContractNumber }}
                            ({{ selectedContract.currentContractInformation.product.basicProvider.display_name || selectedContract.currentContractInformation.product.basicProvider.name }})
                        </dd>
                    </dl>
                    <i18n path="views.data-exchange.matched-contract.selected-contract--confirm" tag="p">
                        <template v-slot:entity>{{ $tc(`common.data-exchange.process--entity-name.the.${kebabCase(dxpInboxItem.document_type_identifier)}`, 1) }}</template>
                        <template v-slot:contract><b>{{ selectedContract.currentContractNumber }}</b></template>
                        <template v-slot:customerName><b>{{ selectedContract.customer.getContactName({ contactNumber: true }) }}</b></template>
                    </i18n>
                </info-box>
            </q-card-section>

            <!-- eslint-disable vue/no-v-html -->
            <q-card-section v-if="updateTargetObjectMatchedContractError" class="q-pt-none q-mt-none">
                <info-box
                    type="negative"
                    no-margin
                    data-test="error:global-message"
                    v-html="updateTargetObjectMatchedContractError"
                />
            </q-card-section>
            <!-- eslint-enable -->

            <q-card-actions align="right" class="q-gutter-sm">
                <base-button
                    :label="$t('common.term.cancel')"
                    flat
                    primary-button
                    @click="close"
                />
                <base-button
                    v-if="mode === DxpInboxItemTargetObjectMatchedContractUpdateOverlayMode.CONFIRM"
                    :label="$tc('common.data-exchange.item.select-other-contract', 1)"
                    primary-button
                    outline
                    @click="setMode(DxpInboxItemTargetObjectMatchedContractUpdateOverlayMode.UPDATE)"
                />
                <base-button
                    data-test="btn:handle-matched-contract-update"
                    :label="mode === DxpInboxItemTargetObjectMatchedContractUpdateOverlayMode.UPDATE ? $t('common.term.save') : $t('common.term.confirm')"
                    :disable="(!selectedContract && mode === DxpInboxItemTargetObjectMatchedContractUpdateOverlayMode.UPDATE) || isUpdatingTargetObjectMatchedContract"
                    primary-button
                    @click="handleMatchedContractUpdate"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import FetchContractsSearch from '@/components/search/FetchContractsSearch.vue'
import { useI18n } from '@/composables/i18n'
import { DxpContractMatchNextStep, DxpInboxItemTargetObjectMatchedContractUpdateOverlayMode } from '@/enums'
import { extractErrorMessage } from '@/helpers/form'
import { kebabCase } from 'lodash'
import { computed, ref } from 'vue'
import { useQuasar } from '@/composables/quasar'

export default {
    name: 'DxpInboxItemTargetObjectMatchedContractUpdateOverlay',
    components: { FetchContractsSearch },
    setup (props, context) {
        const { t, tc } = useI18n()
        const $q = useQuasar()

        // DxpInboxItem
        const dxpInboxItem = ref(null)
        function resetDxpInboxItem () {
            dxpInboxItem.value = null
        }
        const targetObject = computed(() => dxpInboxItem.value?.targetObject ?? null)

        // Mode
        const mode = ref(DxpInboxItemTargetObjectMatchedContractUpdateOverlayMode.UPDATE)
        function setMode (value) {
            mode.value = value
        }
        function resetMode () {
            setMode(DxpInboxItemTargetObjectMatchedContractUpdateOverlayMode.UPDATE)
        }

        // Title
        const overlayTitle = computed(() => {
            if (targetObject.value?.nextStep === DxpContractMatchNextStep.CONFIRM_SELECTED_CONTRACT && mode.value === DxpInboxItemTargetObjectMatchedContractUpdateOverlayMode.UPDATE) {
                return t(`views.commission.commission-list.entry.next-step--${kebabCase(DxpContractMatchNextStep.SELECT_CONTRACT)}`)
            }
            if (!targetObject.value?.nextStep) {
                return tc('common.actions.dxp-item.update-target-object-matched-contract', 1)
            }
            return t(`common.data-exchange.item.next-step--${kebabCase(targetObject?.value?.nextStep)}`)
        })

        // Table
        const TableDataSource = Object.freeze({
            'IMPORT': 'IMPORT',
            'MATCHED': 'MATCHED',
            'SHORTLIST': 'SHORTLIST',
        })
        const tableData = computed(() => {
            const tableData = []

            if (targetObject.value) {
                // Import
                tableData.push({
                    id: 'import',
                    source: TableDataSource.IMPORT,
                    contract: {
                        currentContractNumber: targetObject.value.contract_number,
                        customer: {
                            getContactName: () => {
                                if (targetObject.value.customer_last_name && targetObject.value.customer_first_name) {
                                    return `${targetObject.value.customer_last_name}, ${targetObject.value.customer_first_name}`
                                } else if (targetObject.value.customer_company_name) {
                                    return targetObject.value.customer_company_name
                                } else if (targetObject.value.customer_full_name) {
                                    return targetObject.value.customer_full_name
                                }
                                return ''
                            },
                        },
                    },
                })

                // Matched contract
                if (targetObject.value.matchedContract) {
                    tableData.push({
                        id: 'matched',
                        source: TableDataSource.MATCHED,
                        contract: targetObject.value.matchedContract,
                    })
                }

                // Shortlist
                if (targetObject.value.contractMatchShortlist.length) {
                    targetObject.value.contractMatchShortlist.forEach(contractMatch => {
                        if (tableData.find(entry => entry.contract.id === contractMatch.contract.id)) return
                        tableData.push({
                            id: `shortlist-${contractMatch.contract.id}`,
                            source: TableDataSource.SHORTLIST,
                            contract: contractMatch.contract,
                        })
                    })
                }
            }

            return tableData
        })
        const tableColumns = [
            {
                name: 'source',
                label: tc('common.term.source', 1),
                align: 'left',
                classes: row => (row.source === TableDataSource.MATCHED) ? 'source text-bold' : 'source',
            },
            {
                name: 'contract_number',
                label: tc('common.contract.contract-number', 1),
                field: row => row.contract.currentContractNumber,
                align: 'left',
                classes: row => (row.source === TableDataSource.MATCHED) ? 'text-bold' : '',
            },
            {
                name: 'provider',
                label: tc('common.term.provider', 1),
                align: 'left',
            },
            {
                name: 'customer_name',
                label: tc('common.term.customer', 1),
                align: 'left',
                classes: row => (row.source === TableDataSource.MATCHED) ? 'text-bold' : '',
            },
            {
                name: 'actions',
                label: tc('common.term.action', 2),
                align: 'left',
            },
        ]

        // Contract
        const updateTargetObjectMatchedContractError = ref('')
        const isUpdatingTargetObjectMatchedContract = ref(false)
        const selectedContract = ref(null)
        function enableSelectContractButton (row) {
            if (!selectedContract.value && targetObject.value.matchedContract?.id === row.contract.id) return false
            if (row.contract.id === selectedContract.value?.id) return false
            return true
        }
        function selectContract (contract) {
            selectedContract.value = contract
        }
        function resetContract () {
            selectedContract.value = null
        }
        function handleMatchedContractUpdate () {
            const contract = mode.value === DxpInboxItemTargetObjectMatchedContractUpdateOverlayMode.CONFIRM ? targetObject.value?.matchedContract : selectedContract.value
            updateTargetObjectMatchedContractError.value = ''
            isUpdatingTargetObjectMatchedContract.value = true

            dxpInboxItem.value.updateTargetObjectMatchedContract(contract.id)
                .then(dxpInboxItem => {
                    $q.notify({
                        type: 'positive',
                        message: t('common.notifications.data-exchange.update-matched-contract-success'),
                    })
                    close()
                    context.emit('success')
                })
                .catch(error => {
                    updateTargetObjectMatchedContractError.value = extractErrorMessage(error)
                })
                .finally(() => {
                    isUpdatingTargetObjectMatchedContract.value = false
                })
        }

        // ProductProvider
        function getProductProviderClasses (entry) {
            const classes = []

            if (entry.source === TableDataSource.MATCHED) {
                classes.push('text-bold')
            }

            return classes.join(' ')
        }

        // Dialog
        const dialog = ref(null)
        function open ({
            dxpInboxItem: dxpInboxItemArg,
            mode: modeArg,
        } = {
            mode: DxpInboxItemTargetObjectMatchedContractUpdateOverlayMode.UPDATE,
        }) {
            dxpInboxItem.value = dxpInboxItemArg
            mode.value = modeArg

            dialog.value.show()
        }
        function close () {
            dialog.value.hide()
        }
        function onShow () {
            context.emit('show')
        }
        function onHide () {
            resetDxpInboxItem()
            resetMode()
            resetContract()
            context.emit('hide')
        }

        return {
            // Functions
            kebabCase,

            // DxpInboxItem
            dxpInboxItem,
            targetObject,

            // Mode
            DxpInboxItemTargetObjectMatchedContractUpdateOverlayMode,
            mode,
            setMode,

            // Title
            overlayTitle,

            // Table
            TableDataSource,
            tableColumns,
            tableData,

            // Contract
            updateTargetObjectMatchedContractError,
            isUpdatingTargetObjectMatchedContract,
            selectedContract,
            enableSelectContractButton,
            selectContract,
            resetContract,
            handleMatchedContractUpdate,

            // ProductProvider
            getProductProviderClasses,

            // Dialog
            dialog,
            open,
            close,
            onShow,
            onHide,
        }
    },
}
</script>

<style lang="scss" scoped>
.matched-contract-table.matched-contract-table {
    ::v-deep tr:first-of-type {
        td {
            border-bottom: 1px solid var(--q-color-secondary-dark);
            background-color: var(--color-background-tertiary);

            &.source {
                font-style: italic;
            }
        }
    }

    td {
        height: 36px; // Adapt to height of rows with small round buttons
    }
}
</style>
