var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xs-12"},[_c('div',{staticClass:"row q-col-gutter-md"},[_c('grid-card',{attrs:{"columns":{
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6,
                xl: 4
            },"container-type-normal":"","heading":_vm.$tc('common.product-provider.product-provider-info', 2)}},[_c('form-builder-next',{attrs:{"form":_vm.productProviderSettingsForm,"initial-data":_vm.state.initialData,"in-page-footer":"","submit-button-label":_vm.productProviderSettings ? _vm.$t('common.term.save') : _vm.$t('views.admin.products.product-providers.add-product-provider-settings')},on:{"cancel":function($event){return _vm.$emit('cancel')},"submit":formData => _vm.$emit('submit', formData)},scopedSlots:_vm._u([{key:"default",fn:function({ formBuilder }){return undefined}}])})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }