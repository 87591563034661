import { i18n } from '@/i18n'
import store from '@/store/store'
import { checkRouteAccessPermissions } from '@/helpers/acl'

import DefaultLayout from '@/layouts/Default'
import ContractCreate from '@/views/contract/ContractCreate'
import ContractDetail from '@/views/contract/ContractDetail'
import ContractUpdate from '@/views/contract/ContractUpdate'
import ContractSettings from '@/views/contract/ContractSettings'
import ContractCommissionSettings from '@/views/contract/ContractCommissionSettings'
import PremiumInvoiceCreate from '@/views/contract/PremiumInvoiceCreate'
import PremiumInvoiceDetail from '@/views/contract/PremiumInvoiceDetail'
import PremiumInvoiceUpdate from '@/views/contract/PremiumInvoiceUpdate'
import PremiumReminderDetail from '@/views/contract/PremiumReminderDetail.vue'

export default [
    {
        path: '/contacts/:contactId(\\d+)/contracts/create',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'contract-create',
            component: ContractCreate,
            props: true,
            meta: {
                navGroup: 'contacts',
            },
        }],
    },
    {
        path: '/contacts/:contactId(\\d+)/contracts/:id(\\d+)/premium-invoices',
        name: 'contract-detail-premium-invoices-redirect',
        redirect: to => {
            const type = (store.state.user.aclPermissions.includes('PremiumInvoice:manage')) ? 'invoices' : 'reminders';
            return { name: 'contract-detail-premium-invoices', params: { contactId: to.params.contactId, id: to.params.id, tab: 'premium-invoices', type } };
        },
    },
    {
        path: '/contacts/:contactId(\\d+)/contracts/:id(\\d+)/:tab(premium-invoices)/:type(invoices|reminders)',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'contract-detail-premium-invoices',
            component: ContractDetail,
            props: true,
            meta: { // TODO improvement @TFU: Allow/disallow access to this route based on tab-param values.
                navGroup: 'contacts',
            },
        }],
    },
    {
        path: '/contacts/:contactId(\\d+)/contracts/:id(\\d+)/:tab(documents|comments|history|commission-postings|premium-invoices)?',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'contract-detail',
            component: ContractDetail,
            props: true,
            meta: { // TODO improvement @TFU: Allow/disallow access to this route based on tab-param values.
                navGroup: 'contacts',
            },
        }],
    },
    {
        path: '/contacts/:contactId(\\d+)/contracts/:id(\\d+)/update',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'contract-update',
            component: ContractUpdate,
            props: true,
            meta: {
                navGroup: 'contacts',
            },
        }],
    },
    {
        path: '/contacts/:contactId(\\d+)/contracts/:id(\\d+)/settings',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'contract-settings',
            component: ContractSettings,
            props: true,
            meta: {
                navGroup: 'contacts',
            },
            redirect: { name: 'contract-settings-commission-settings' },
            children: [
                {
                    path: 'commission-settings',
                    name: 'contract-settings-commission-settings',
                    component: ContractCommissionSettings,
                    props: route => {
                        return {
                            contactId: route.params.contactId,
                            id: route.params.id,
                        }
                    },
                    meta: {
                        accessPermissions: ['Feature:commissions:core', 'CommissionSplit:manage'],
                        can: checkRouteAccessPermissions,
                        navGroup: 'contacts',
                        title: i18n.tc('views.contract.settings.commission.commission-setting', 2),
                    },
                },
            ],
        }],
    },
    {
        path: '/contacts/:contactId(\\d+)/contracts/:contractId(\\d+)/premium-invoices/invoices/create',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'premium-invoice-create',
            component: PremiumInvoiceCreate,
            props: true,
            meta: {
                navGroup: 'contacts',
            },
        }],
    },
    {
        path: '/contacts/:contactId(\\d+)/contracts/:contractId(\\d+)/premium-invoices/invoices/:premiumInvoiceId(\\d+)',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'premium-invoice-detail',
            component: PremiumInvoiceDetail,
            props: true,
            meta: {
                navGroup: 'contacts',
            },
        }],
    },
    {
        path: '/contacts/:contactId(\\d+)/contracts/:contractId(\\d+)/premium-invoices/invoices/:premiumInvoiceId(\\d+)/update',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'premium-invoice-update',
            component: PremiumInvoiceUpdate,
            props: true,
            meta: {
                navGroup: 'contacts',
            },
        }],
    },
    {
        path: '/contacts/:contactId(\\d+)/contracts/:contractId(\\d+)/premium-invoices/reminders/:premiumReminderId(\\d+)',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'premium-reminder-detail',
            component: PremiumReminderDetail,
            props: true,
            meta: {
                navGroup: 'contacts',
            },
        }],
    },
]
