import gql from 'graphql-tag'

export const DXP_INBOX_ITEM_TARGET_OBJECT_MATCHED_CONTRACT_UPDATE_MUTATION = gql`
    mutation dxpInboxItemTargetObjectMatchedContractUpdate(
        $dxpInboxItemId: ID!
        $contractId: ID
    ) {
        dxpInboxItemTargetObjectMatchedContractUpdate(
            dxp_inbox_item_id: $dxpInboxItemId
            contract_id: $contractId
        ) {
            id
        }
    }
`
