import { Model } from '@/models'

import { ProductProviderSettingsService } from '@/services'
import { ProductProviderSettingsStatus } from '@/enums/graphql'
import { ProductProviderSettingsActions } from '@/enums'

export class ProductProviderSettings extends Model {
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'contact_id',
            'status',
            'display_name',
            'igb2bInsurerCode',

            // Timestamps
            'created_at',
            'updated_at',
        ], data)

        this.statusTransitions[ProductProviderSettingsStatus.ACTIVE] = [ProductProviderSettingsStatus.INACTIVE]
        this.statusTransitions[ProductProviderSettingsStatus.INACTIVE] = [ProductProviderSettingsStatus.ACTIVE]

        this.statusActionMapping[ProductProviderSettingsStatus.ACTIVE] = ProductProviderSettingsActions.ACTIVATE
        this.statusActionMapping[ProductProviderSettingsStatus.INACTIVE] = ProductProviderSettingsActions.DEACTIVATE
    }

    static get service () { return ProductProviderSettingsService }
    static status = ProductProviderSettingsStatus
    static action = ProductProviderSettingsActions

    static create ({ contactId, displayName, igb2bInsurerCode }) {
        const variables = {
            contact_id: contactId,
            productProviderSettings: {
                status: ProductProviderSettingsStatus.ACTIVE,
                display_name: displayName,
                igb2b_insurer_code_id: igb2bInsurerCode,
            },
        }

        return ProductProviderSettingsService.create(variables)
    }

    update ({ displayName, igb2bInsurerCode }) {
        const variables = {
            contact_id: this.contact_id,
            productProviderSettings: {
                status: this.status,
                display_name: displayName,
                igb2b_insurer_code_id: igb2bInsurerCode,
            },
        }

        return ProductProviderSettingsService.update(variables).then(productProviderSettings => {
            Object.assign(this, productProviderSettings)
            return productProviderSettings
        })
    }

    delete () {
        return ProductProviderSettingsService.delete(this.contact_id)
    }

    get actions () {
        const actions = super.actions

        if (this.status === ProductProviderSettingsStatus.INACTIVE) {
            actions.push({
                key: ProductProviderSettingsActions.DELETE,
                item: this,
            })
        }

        return actions
    }

    activate () {
        return ProductProviderSettingsService.changeStatus(this.contact_id, ProductProviderSettingsStatus.ACTIVE).then(productProviderSettings => {
            this.status = productProviderSettings.status
            return productProviderSettings
        })
    }

    deactivate () {
        return ProductProviderSettingsService.changeStatus(this.contact_id, ProductProviderSettingsStatus.INACTIVE).then(productProviderSettings => {
            this.status = productProviderSettings.status
            return productProviderSettings
        })
    }
}
