<template>
    <div class="display-contents">
        <base-table
            :columns="state.columns"
            :fetch-objects-fn="PremiumInvoice.service.all"
            :additional-filters="state.additionalFilters"
            user-settings-base-path="ContractPremiumInvoiceList"
            pagination-sort-by-default-key="created_at"
            :show-filter="false"
            enable-visible-columns
            no-top-padding
            @row-dblclick="onDblClick"
        >
            <template v-slot:tabs>
                <h2>{{ $tc('common.premium-invoice.invoice', 2) }}</h2>
            </template>

            <!-- TODO: Remove dev only code -->
            <!--<template v-slot:body-cell-invoice_number="props">
                <pre><code>{{ props.row }}</code></pre>
                &lt;!&ndash;<pre><code>{{ props.row.totalPosition }}</code></pre>&ndash;&gt;
            </template>-->

            <template v-slot:body-cell-actions="props">
                <q-td :props="props" auto-width>
                    <q-btn
                        class="do-not-print"
                        color="primary"
                        size="sm"
                        flat
                        round
                        icon="mib-view-1"
                        :to="{ name: 'premium-invoice-detail', params: { contactId: contract.customer.id, contractId: contract.id, premiumInvoiceId: props.row.id } }"
                    >
                        <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.premium-invoice.show-premium-invoice', 1) }}</q-tooltip>
                    </q-btn>
                    <q-btn
                        class="do-not-print"
                        color="primary"
                        size="sm"
                        flat
                        round
                        icon="mib-pencil"
                        :to="{ name: 'premium-invoice-update', params: { contactId: contract.customer.id, contractId: contract.id, premiumInvoiceId: props.row.id } }"
                    >
                        <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.premium-invoice.update-premium-invoice', 1) }}</q-tooltip>
                    </q-btn>
                </q-td>
            </template>
        </base-table>

        <in-page-footer>
            <base-button
                :label="$tc('common.premium-invoice.add-premium-invoice', 1)"
                :to="{ name: 'premium-invoice-create', params: { contactId: contract.customer.id, contractId: contract.id } }"
                icon="mib-common-file-text-add"
                primary-button
                data-test="btn:create-premium-invoice"
            />
        </in-page-footer>
    </div>
</template>

<script>
import BaseTable from '@/components/BaseTable'
import { PremiumInvoice } from '@/models/premiumInvoice'
import { reactive } from 'vue'
import { useI18n } from '@/composables/i18n'
import { useRouter } from '@/composables/router'
import { kebabCase } from 'lodash'
import { useOnNewEntity } from '@/composables/onNewEntity'

export default {
    name: 'ContractPremiumInvoiceList',
    components: {
        BaseTable,
    },
    props: {
        contract: {
            type: Object,
            required: true,
        },
    },
    setup (props) {
        // Composables
        const { t, tc } = useI18n()
        const router = useRouter()

        // Data
        const state = reactive({
            columns: [
                {
                    name: 'invoice_number',
                    label: tc('common.premium-invoice.invoice-number', 1),
                    field: row => row.invoice_number || '–',
                    align: 'left',
                    classes: row => row.invoice_number ? 'text-bold' : 'additional-info',
                    required: true,
                    sortable: true,
                },
                {
                    name: 'billing_period',
                    label: tc('common.premium-invoice.billing-period', 1),
                    field: row => row.totalPosition.formattedBillingPeriod || '–',
                    align: 'left',
                    classes: row => row.totalPosition.formattedBillingPeriod ? '' : 'additional-info',
                    required: true,
                },
                {
                    name: 'invoice_date',
                    label: tc('common.premium-invoice.invoice-date', 1),
                    field: row => row.formattedInvoiceDate || '–',
                    align: 'left',
                    classes: row => row.formattedInvoiceDate ? '' : 'additional-info',
                    sortable: true,
                },
                {
                    name: 'total_position_invoiced_amount',
                    label: tc('common.premium-invoice.total-amount', 1),
                    field: row => row.totalPosition.formattedInvoicedAmount,
                    align: 'left',
                    classes: row => row.totalPosition.formattedInvoicedAmount ? 'text-bold monospace' : 'additional-info',
                    required: true,
                },
                {
                    name: 'record_sub_type',
                    label: tc('common.premium-invoice.record-sub-type.record-sub-type', 1),
                    field: row => row.totalPosition.premium_kind ? tc(`common.premium-invoice.premium-kind.${kebabCase(row.totalPosition.premium_kind)}`, 1) : '–',
                    align: 'left',
                    classes: row => row.totalPosition.premium_kind ? '' : 'additional-info',
                    hideInitially: true,
                },
                {
                    name: 'actions',
                    label: tc('common.term.action', 2),
                    align: 'left',
                    required: true,
                },
            ],
            additionalFilters: {
                filterMatchedContractIds: [props.contract.id],
                filterMatchedContractConfirmed: true,
            },
        })

        // Functions
        function onDblClick (event, premiumInvoice) {
            router.push({ name: 'premium-invoice-detail', params: { contactId: props.contract.customer.id, contractId: props.contract.id, premiumInvoiceId: premiumInvoice.id } })
        }
        function onNewEntity () {
            router.push({ name: 'premium-invoice-create', params: { contactId: props.contract.customer.id, contractId: props.contract.id } })
        }

        // Lifecycle hooks
        useOnNewEntity(onNewEntity)

        return {
            // Static
            PremiumInvoice,

            // Data
            state,

            // Functions
            onDblClick,
        }
    },
}
</script>
