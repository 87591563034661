import { apolloClient } from '@/apollo/client'

import { CONTACT_PRODUCT_PROVIDER_SETTINGS_UPDATE_MUTATION } from '@/graphql/contact/contactProductProviderSettingsUpdate'
import { CONTACT_PRODUCT_PROVIDER_SETTINGS_DELETE_MUTATION } from '@/graphql/contact/contactProductProviderSettingsDelete'

export const ProductProviderSettingsService = {
    create (variables) {
        return apolloClient.mutate({
            mutation: CONTACT_PRODUCT_PROVIDER_SETTINGS_UPDATE_MUTATION,
            variables,
        }).then(response => response.data.contactProductProviderSettingsUpdate)
    },
    update (variables) {
        return apolloClient.mutate({
            mutation: CONTACT_PRODUCT_PROVIDER_SETTINGS_UPDATE_MUTATION,
            variables,
        }).then(response => response.data.contactProductProviderSettingsUpdate)
    },
    changeStatus (contactId, status) {
        return apolloClient.mutate({
            mutation: CONTACT_PRODUCT_PROVIDER_SETTINGS_UPDATE_MUTATION,
            variables: {
                contact_id: contactId,
                productProviderSettings: {
                    status: status,
                },
            },
        }).then(response => response.data.contactProductProviderSettingsUpdate)
    },
    delete (contactId) {
        return apolloClient.mutate({
            mutation: CONTACT_PRODUCT_PROVIDER_SETTINGS_DELETE_MUTATION,
            variables: {
                contact_id: contactId,
            },
        }).then(response => response.data.contactProductProviderSettingsDelete)
    },
}
