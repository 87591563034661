<template>
    <q-list padding class="main-menu">
        <q-item :to="{ name: 'dev-dashboard' }" exact>
            <q-item-section avatar>
                <q-icon name="mi-app-window-code" />
            </q-item-section>
            <q-item-section>{{ $t('views.dev.dev-dashboard') }}</q-item-section>
        </q-item>
        <q-item :to="{ name: 'dev-roles-configurator' }" exact-path>
            <q-item-section avatar>
                <q-icon name="mi-human-resources-team-settings" />
            </q-item-section>
            <q-item-section>{{ $t('views.dev.roles-configurator') }}</q-item-section>
        </q-item>

        <q-expansion-item
            v-model="navGroupState.design"
            :to="{ name: 'dev-design' }"
            :label="$t('views.dev.design.design')"
            expand-icon-toggle
            icon="mi-color-painting-palette"
            expand-icon="mib-arrow-left-1"
            expanded-icon="mib-arrow-down-1"
            :content-inset-level="1"
        >
            <q-list dense>
                <q-item :to="{ name: 'dev-design-color-palette' }" exact-path>
                    <q-item-section>{{ $t('views.dev.design.color-palette.color-palette') }}</q-item-section>
                </q-item>
                <q-item :to="{ name: 'dev-design-icons' }" exact-path>
                    <q-item-section>{{ $t('views.dev.design.icons.title') }}</q-item-section>
                </q-item>
                <q-item :to="{ name: 'dev-design-illustrations' }" exact-path>
                    <q-item-section>{{ $t('views.dev.design.illustrations.title') }}</q-item-section>
                </q-item>
                <q-item :to="{ name: 'dev-design-typography' }" exact-path>
                    <q-item-section>{{ $t('views.dev.design.typography.title') }}</q-item-section>
                </q-item>
            </q-list>
        </q-expansion-item>

        <q-expansion-item
            v-model="navGroupState.components"
            :to="{ name: 'dev-components' }"
            :label="$t('views.dev.components.components')"
            expand-icon-toggle
            icon="mi-app-window-module"
            expand-icon="mib-arrow-left-1"
            expanded-icon="mib-arrow-down-1"
            :content-inset-level="1"
        >
            <q-list dense>
                <q-item :to="{ name: 'dev-component-info-box' }" exact-path>
                    <q-item-section>{{ $tc('views.dev.components.info-box.info-box', 1) }}</q-item-section>
                </q-item>
                <q-item :to="{ name: 'dev-component-cards' }" exact-path>
                    <q-item-section>{{ $t('views.dev.components.cards.cards') }}</q-item-section>
                </q-item>
                <q-item :to="{ name: 'dev-component-markup-table' }" exact-path>
                    <q-item-section>{{ $tc('views.dev.components.markup-table.markup-table', 1) }}</q-item-section>
                </q-item>
            </q-list>
        </q-expansion-item>

        <q-expansion-item
            v-model="navGroupState.libs"
            :to="{ name: 'dev-libs' }"
            :label="$t('views.dev.libs.libs')"
            expand-icon-toggle
            icon="mi-warehouse-package"
            expand-icon="mib-arrow-left-1"
            expanded-icon="mib-arrow-down-1"
            :content-inset-level="1"
        >
            <q-list dense>
                <q-item :to="{ name: 'dev-libs-form' }" exact-path>
                    <q-item-section>{{ $tc('views.dev.libs.form.form', 1) }}</q-item-section>
                </q-item>
                <q-item :to="{ name: 'dev-libs-wizard' }" exact-path>
                    <q-item-section>{{ $tc('views.dev.libs.form.wizard', 1) }}</q-item-section>
                </q-item>
            </q-list>
        </q-expansion-item>

        <q-expansion-item
            v-model="navGroupState.automation"
            :to="{ name: 'dev-automation' }"
            :label="$tc('views.dev.automation.automation', 1)"
            expand-icon-toggle
            icon="mi-robot-head-1"
            expand-icon="mib-arrow-left-1"
            expanded-icon="mib-arrow-down-1"
            :content-inset-level="1"
        >
            <q-list dense>
                <q-item :to="{ name: 'dev-automation-contract-file-management' }" exact-path>
                    <q-item-section>{{ $tc('views.dev.automation.contract-file-management', 1) }}</q-item-section>
                </q-item>
                <q-item :to="{ name: 'dev-automation-premium-invoice-premium-reminder' }" exact-path>
                    <q-item-section>{{ $tc('views.dev.automation.premium-invoice-premium-reminder', 1) }}</q-item-section>
                </q-item>
            </q-list>
        </q-expansion-item>

        <q-expansion-item
            v-model="navGroupState.tools"
            :to="{ name: 'dev-tools' }"
            :label="$tc('views.dev.tools.tools', 1)"
            expand-icon-toggle
            icon="mi-tools-wench-screwdriver"
            expand-icon="mib-arrow-left-1"
            expanded-icon="mib-arrow-down-1"
            :content-inset-level="1"
        >
            <q-list dense>
                <q-item :to="{ name: 'dev-tools-mime-parser' }" exact-path>
                    <q-item-section>{{ $tc('views.dev.tools.mime-parser.mime-parser', 1) }}</q-item-section>
                </q-item>
            </q-list>
        </q-expansion-item>
    </q-list>
</template>

<script>
export default {
    name: 'MainMenuDev',
    data () {
        return {
            navGroupState: {
                design: this.$route.meta.navGroup === 'design',
                components: this.$route.meta.navGroup === 'components',
                libs: this.$route.meta.navGroup === 'libs',
                automation: this.$route.meta.navGroup === 'automation',
                tools: this.$route.meta.navGroup === 'tools',
            },
        }
    },
    watch: {
        $route (to) {
            if (this.navGroupState[to.meta.navGroup] !== undefined) {
                this.navGroupState[to.meta.navGroup] = true
            }
        },
    },
}
</script>
