import { i18n } from '@/i18n'

// TODO @TFU: Exchange with native useI18n composable from vue-i18n package once upgraded to Vue 3 (see https://vue-i18n.intlify.dev/guide/advanced/composition.html).
export function useI18n () {
    return {
        t: i18n.t.bind(i18n),
        tc: i18n.tc.bind(i18n),
        te: i18n.te.bind(i18n),
    }
}
