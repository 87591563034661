<template>
    <div>
        <q-tabs
            :value="value"
            class="in-page-navigation"
            :vertical="$q.screen.gt.sm"
            dense
            outside-arrows
            indicator-color="accent"
            active-bg-color="white"
            no-caps
            inline-label
            @input="value => $emit('input', value)"
        >
            <slot />
        </q-tabs>
        <slot name="append" />
    </div>
</template>

<script>
export default {
    name: 'InPageNavigation',
    props: {
        value: {},
    },
}
</script>

<style lang="scss" scoped>
.in-page-navigation {

    ::v-deep .q-tab {
        background-color: var(--color-background-faded);
        border: 1px solid var(--color-border-primary);
        border-bottom: none;

        // Left-align labels
        justify-content: flex-start;

        &:last-child {
            border-bottom: 1px solid var(--color-border-primary);
        }

        // Reset link styles of `<q-route-tab>` which have a `:to` property / are links (and thus inherit the styling of <a> anchors).
        &.q-hoverable {
            &:hover,
            &:focus,
            &:active {
                background-color: transparent;
                color: var(--color-text-primary);

                & > ::v-deep .q-focus-helper {
                    //background-color: currentColor;
                }
            }
        }

        // Styling of active tab
        &.q-tab--active {

            .q-tab__label {
                font-weight: bold;
            }
        }
    }

    ::v-deep .q-tab__label {
        // Add ellipsis if text is too long
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    ::v-deep .q-tab__content {
        // Proper alignment of content
        flex-direction: row;
    }
}

@include mq($to: $sizeBreakpointMd) {
    .in-page-navigation {
        ::v-deep .q-tab {
            border: none;

            &:last-child {
                border-bottom: none;
            }
        }
    }
}

@media print {
    .in-page-navigation {

        ::v-deep {

            .q-tabs__content {
                display: inline-block !important; // !important is necessary
            }

            .q-tab {

                &.q-tab--active {
                    border-bottom: 2px solid var(--q-color-accent);

                    .q-tab__indicator {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>
