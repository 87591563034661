import { apolloClient } from '@/apollo/client'

import { DXP_INBOX_ITEMS_CREATE_MUTATION } from '@/graphql/dxpInboxItem/dxpInboxItemsCreate'
import { DXP_INBOX_ITEM_TARGET_OBJECT_MATCHED_CONTRACT_UPDATE_MUTATION } from '@/graphql/dxpInboxItem/dxpInboxItemTargetObjectMatchedContractUpdate'

export const DxpInboxItemService = {
    create (variables) {
        return apolloClient.mutate({
            mutation: DXP_INBOX_ITEMS_CREATE_MUTATION,
            variables,
        }).then(response => response.data.dxpInboxItemsCreate)
    },
    updateTargetObjectMatchedContract (variables) {
        return apolloClient.mutate({
            mutation: DXP_INBOX_ITEM_TARGET_OBJECT_MATCHED_CONTRACT_UPDATE_MUTATION,
            variables,
        }).then(response => response.data.dxpInboxItemTargetObjectMatchedContractUpdate)
    },
}
