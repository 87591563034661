import { i18n } from '@/i18n'
import { computed } from 'vue'
import { Model } from '@/models'
import { PremiumReminderActions } from '@/enums'
import { DxpContractMatchMixin } from '@/models/mixins/dxpContractMatchMixin'
import { formatDate } from '@/helpers/date'
import { formatCurrency } from '@/helpers/number'
import { PremiumReminderService } from '@/services'

export class PremiumReminder extends DxpContractMatchMixin(Model) {
    /**
     * Create a PremiumReminder model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'file_import_identification_number',
            'contract_number',
            'framework_agreement_number',
            'invoice_number',
            'invoice_date',
            'document_recipient',
            'sectorCode',
            'product_provider_sector_code',
            'currency',
            'invoiced_amount',
            'reminder_charges_amount',
            'outstanding_invoice_amount',
            'commission_calculation_basis',
            'reminder_date',
            'coverage_interruption_from',
            'customer_first_name',
            'customer_last_name',
            'customer_company_name',
            'files',
            'contractMatchShortlist',
            'matchedContract',
            'matched_contract_confirmed',
        ], data)

        // Computed
        this.formattedInvoiceDate = computed( () => {
            return formatDate(this.invoice_date)
        })

        this.formattedReminderDate = computed( () => {
            return formatDate(this.reminder_date)
        })

        this.formattedCoverageInterruptionFrom = computed( () => {
            return formatDate(this.coverage_interruption_from)
        })

        this.formattedInvoicedAmount = computed( () => {
            return formatCurrency(this.invoiced_amount)
        })

        this.formattedReminderChargesAmount = computed( () => {
            return formatCurrency(this.reminder_charges_amount)
        })

        this.formattedOutstandingInvoiceAmount = computed( () => {
            return formatCurrency(this.outstanding_invoice_amount)
        })

        this.hasInvoiceIdentifier = computed( () => {
            return !!(this.invoice_number || this.invoice_date)
        })

        this.formattedInvoiceIdentifier = computed( () => {
            return this.hasInvoiceIdentifier.value ? this.invoice_number || `${i18n.tc('common.premium-invoice.invoice-from', 1)} ${this.formattedInvoiceDate.value}` : null
        })

        this.formattedIdentifier = computed( () => {
            if (this.hasInvoiceIdentifier.value) {
                return this.invoice_number ? i18n.tc('common.premium-reminder.reminder-for-invoice--invoice-number', 1, { invoiceNumber: this.invoice_number }) : i18n.tc('common.premium-reminder.reminder-for-invoice--invoice-date', 1, { invoiceDate: this.formattedInvoiceDate.value })
            } else {
                return `${i18n.tc('common.premium-reminder.reminder-from', 1)} ${this.formattedReminderDate.value}`
            }
        })

        this.formattedIgb2bSectorCode = computed( () => {
            return this.sectorCode ? `${this.sectorCode.code} – ${this.sectorCode.name}` : null
        })
    }

    static get service () { return PremiumReminderService }
    static action = PremiumReminderActions

    /**
     * Add custom actions.
     * @returns {Array}
     */
    get actions () {
        const actions = super.actions
        actions.push(
            {
                key: PremiumReminderActions.DELETE,
                item: this,
            }
        )
        return actions
    }

    /**
     * Delete this premiumReminder.
     * @returns {Promise<*>}
     */
    delete () {
        return PremiumReminderService.delete(this.id)
    }
}
