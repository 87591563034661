<template>
    <q-page>
        <div class="row q-col-gutter-md q-pa-lg justify-center">
            <div class="q-pt-xl col-xs-12 col-sm-8 col-md-6 col-lg-5 col-xl-4 self-center">
                <!-- TODO: Fix formHasErrors so that the illustration is highlighted accordingly – also when the validation of the form fails (before submitting it) -->
                <illustration-heading :title="$t('views.auth.login.title')" img="password-lock" :error="formHasErrors" />

                <q-stepper
                    ref="stepper"
                    v-model="activeStep"
                    alternative-labels
                    :vertical="$q.screen.lt.md"
                    color="primary"
                    flat
                    class="auth"
                >
                    <q-step
                        name="credentials"
                        :title="$t('common.auth.login')"
                        icon="mib-single-neutral"
                        active-icon="mib-single-neutral"
                        done-color="secondary"
                    >
                        <div class="col-xs-12 q-gutter-y-md">
                            <auth-credentials ref="authCredentials" :prefill-username="prefillUsername" @submit="onCredentialsSubmit" />
                        </div>
                    </q-step>

                    <q-step
                        name="2fa"
                        :title="$tc('common.auth.2fa-code')"
                        icon="mib-phone-type"
                        active-icon="mib-phone-type"
                        done-color="secondary"
                    >
                        <div class="col-xs-12 q-gutter-y-md">
                            <auth-2fa-code
                                v-if="!useBackupCodeFor2FA"
                                ref="auth2faCode"
                                submit-button-label="common.auth.login-action"
                                @submit="on2faCodeSubmit"
                                @back="onBack"
                            >
                                <template v-slot:formNavigationDefault>
                                    <small><a
                                        data-test="link:backup-code"
                                        tabindex="0"
                                        @click="onBackupCode"
                                        @keyup.enter="onBackupCode"
                                    >{{ $t('views.auth.2fa.login-with-backup-code-link') }}</a></small>
                                </template>
                            </auth-2fa-code>
                            <template v-else>
                                <p>{{ $t('views.auth.2fa.login-with-backup-code-text') }}</p>
                                <auth-login-backup-code ref="authLoginBackupCode" @submit="on2faBackupCodeSubmit" @back="onBack2fa" />
                            </template>
                        </div>
                    </q-step>
                </q-stepper>
            </div>
        </div>
    </q-page>
</template>

<script>
import { apolloClient } from '@/apollo/client'
import { onLogin } from '@/apollo'
import { loadEcoHubAndDxpBaseData } from '@/helpers/dxp'
import { ensureLocaleConformity } from '@/i18n'
import AuthCredentials from '@/components/auth/AuthCredentials.vue'
import Auth2faCode from '@/components/auth/Auth2faCode.vue'
import AuthLoginBackupCode from '@/components/auth/AuthLoginBackupCode.vue'

import { AuthService } from '@/services'
import { sha256 } from '@/helpers'
import { saveUserToStoreAndLoadInitialAppData } from '@/helpers/user'

export default {
    name: 'AuthLogin',
    components: {
        AuthCredentials,
        'auth-2fa-code': Auth2faCode,
        AuthLoginBackupCode,
    },
    meta () {
        return {
            title: this.$t('common.auth.login'),
        }
    },
    data () {
        return {
            activeStep: 'credentials',
            useBackupCodeFor2FA: false,
            prefillUsername: (this.$route.query.username) ? this.$route.query.username : '',
            processToken: null,
            formHasErrors: false,
        }
    },
    methods: {
        onCredentialsSubmit ({ username, password }) {
            this.formHasErrors = false
            AuthService.login(username, password)
                .then(response => {
                    this.processToken = response.token
                    this.activeStep = '2fa'
                })
                .catch(error => {
                    this.formHasErrors = true
                    this.$refs.authCredentials.showFormErrorMessage(error)
                    this.$refs.authCredentials.resetFormData()
                    this.$refs.authCredentials.resetFormSubmitStatus()
                })
        },
        on2faCodeSubmit ({ twoFACode }) {
            this.formHasErrors = false
            AuthService.validate2fa(this.processToken, twoFACode.split(' ').join(''))
                .then(async (response) => {
                    await onLogin(apolloClient)
                    await saveUserToStoreAndLoadInitialAppData(response.user)
                    loadEcoHubAndDxpBaseData()
                    this.$router.replace({ name: 'dashboard' })
                })
                .catch(error => {
                    this.formHasErrors = true
                    this.$refs.auth2faCode.showFormErrorMessage(error)
                    this.$refs.auth2faCode.resetFormData()
                    this.$refs.auth2faCode.resetFormSubmitStatus()
                })
        },
        on2faBackupCodeSubmit ({ twoFABackupCode }) {
            this.formHasErrors = false
            AuthService.validate2faBackupCode(this.processToken, twoFABackupCode.split('-').join(''))
                .then(async (response) => {
                    await onLogin(apolloClient)
                    await saveUserToStoreAndLoadInitialAppData(response.user)
                    loadEcoHubAndDxpBaseData()
                    this.$router.replace({ name: 'dashboard' })
                })
                .catch(error => {
                    this.formHasErrors = true
                    this.$refs.authLoginBackupCode.showFormErrorMessage(error)
                    this.$refs.authLoginBackupCode.resetFormData()
                    this.$refs.authLoginBackupCode.resetFormSubmitStatus()
                })
        },
        onBack () {
            this.formHasErrors = false
            this.activeStep = 'credentials'
            this.useBackupCodeFor2FA = false
        },
        onBack2fa () {
            this.formHasErrors = false
            this.activeStep = '2fa'
            this.useBackupCodeFor2FA = false
        },
        onBackupCode () {
            this.formHasErrors = false
            this.useBackupCodeFor2FA = true
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .q-stepper__step-inner.q-stepper__step-inner {
    @include mq($from: $sizeBreakpointLg) {
        padding-left: 0;
        padding-right: 0;
    }
}
</style>
