<template>
    <page-loading-indicator
        v-if="state.isLoading"
        :number-of-breadcrumb-elements="5"
        subtitle
        actions
        :number-of-next-actions="0"
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="state.contract.customer.getContactName()" :to="{ name: 'contact-detail', params: { id: contactId } }" data-test="breadcrumb:contact-detail" />
                <q-breadcrumbs-el :label="$tc('common.term.contract', 2)" :to="{ name: 'contact-detail', params: { id: contactId }}" data-test="breadcrumb:contracts" />
                <q-breadcrumbs-el :label="state.contract.currentContractNumber" :to="{ name: 'contract-detail', params: { id: state.contract.id }}" data-test="breadcrumb:contract-detail" />
                <q-breadcrumbs-el :label="$tc('common.premium-reminder.reminder', 2)" :to="{ name: 'contract-detail-premium-invoices', params: { contactId: contactId, id: state.contract.id, tab: 'premium-invoices', type: 'reminders' }}" data-test="breadcrumb:premium-invoices" />
                <q-breadcrumbs-el :label="state.premiumReminder.formattedIdentifier" :to="{ name: 'premium-invoice-detail', params: { id: state.contract.id }}" data-test="breadcrumb:premium-reminder-detail" />
            </template>

            <h1>{{ state.premiumReminder.formattedIdentifier }}</h1>

            <template v-slot:actions>
                <actions
                    :actions="state.premiumReminder.actions"
                    :number-of-next-best-actions="0"
                    translation-base-key="common.actions.premium-reminder"
                    @click="handleActions"
                />

                <!-- Delete PremiumReminder -->
                <form-dialog
                    ref="deleteDialog"
                    double-confirm
                    @submit="handleDelete"
                >
                    <template v-slot:title>{{ $tc('common.premium-reminder.delete-premium-reminder', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.premium-reminder.delete-premium-reminder--confirm--temp-component-interpolation-count" tag="p">
                            <template v-if="state.premiumReminder" v-slot:reminderId><b>{{ state.premiumReminder.formattedIdentifier }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <div class="row print-row-lg q-col-gutter-md entity-info-wrapper">
            <grid-card
                :columns="{
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 4,
                    xl: 4,
                }"
                class="entity-info reminder-info"
                data-test="wrapper:reminder-info"
            >
                <dl class="q-mb-none">
                    <dt>{{ $tc('common.premium-reminder.reminder-date', 1) }}: </dt>
                    <dd class="reminder-date" data-test="text:reminder-date">{{ state.premiumReminder.formattedReminderDate }}</dd>
                    <br>

                    <dt>{{ $tc('common.premium-invoice.document-recipient.document-recipient', 1) }}: </dt>
                    <dd :class="[{ 'additional-info': !state.premiumReminder.document_recipient }]" data-test="text:document-recipient">{{ state.premiumReminder.document_recipient ? $tc(`common.premium-invoice.document-recipient.${kebabCase(state.premiumReminder.document_recipient)}`, 1) : '–' }}</dd>
                    <br>

                    <br>
                    <dt>{{ $tc('common.premium-reminder.coverage-interruption', 1) }}: </dt>
                    <dd :class="['coverage-interruption-date', { 'additional-info': !state.premiumReminder.coverage_interruption_from }]" data-test="text:coverage-interruption-date">{{ state.premiumReminder.formattedCoverageInterruptionFrom || '–' }} <info-icon
                        v-if="state.premiumReminder.coverage_interruption_from"
                        :text="$tc('common.premium-reminder.coverage-interruption--info', 1, { coverageInterruptionDate: state.premiumReminder.formattedCoverageInterruptionFrom })"
                    /></dd>
                </dl>
            </grid-card>

            <grid-card
                :columns="{
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 8,
                    xl: 8,
                }"
                class="entity-info additional-reminder-info"
                data-test="wrapper:additional-reminder-info"
            >
                <dl class="q-mb-none">
                    <dt>{{ $tc('common.premium-reminder.sector-code', 1) }}: </dt>
                    <dd :class="[{ 'additional-info': !state.premiumReminder.sectorCode.code }]" data-test="text:sector-code">{{ state.premiumReminder.sectorCode.code ? state.premiumReminder.formattedIgb2bSectorCode : '–' }}</dd>
                </dl>

                <dl class="q-mb-none">
                    <dt>{{ $tc('common.data-exchange.process--entity-name.billing', 1) }}: </dt>
                    <dd :class="['invoice-number', { 'additional-info': !state.premiumReminder.hasInvoiceIdentifier }]" data-test="text:invoice-number">{{ state.premiumReminder.hasInvoiceIdentifier ? state.premiumReminder.formattedInvoiceIdentifier : '–' }}</dd>
                    <br>

                    <dt>{{ $tc('common.premium-invoice.invoice-date', 1) }}: </dt>
                    <dd :class="['invoice-date', { 'additional-info': !state.premiumReminder.formattedInvoiceDate }]" data-test="text:invoice-date">{{ state.premiumReminder.formattedInvoiceDate || '–' }}</dd>
                    <br>

                    <dt>{{ $tc('common.premium-invoice.invoiced-amount', 1) }}: </dt>
                    <dd :class="['invoiced-amount', { 'additional-info': !state.premiumReminder.formattedInvoicedAmount }]" data-test="text:invoiced-amount">{{ state.premiumReminder.invoiced_amount ? state.premiumReminder.formattedInvoicedAmount : '–' }}</dd>
                    <br>

                    <dt>{{ $tc('common.premium-reminder.reminder-charge', 2) }}: </dt>
                    <dd :class="['reminder-charges', { 'additional-info': !state.premiumReminder.formattedReminderChargesAmount }]" data-test="text:reminder-charges">{{ state.premiumReminder.formattedReminderChargesAmount || '–' }}</dd>
                    <br>

                    <dt>{{ $tc('common.premium-reminder.outstanding-amount', 1) }}: </dt>
                    <dd :class="['outstanding-amount', { 'additional-info': !state.premiumReminder.outstanding_invoice_amount }]" data-test="text:outstanding-amount">{{ state.premiumReminder.formattedOutstandingInvoiceAmount || '–' }}</dd>
                    <br>
                </dl>
            </grid-card>

            <div class="col-xs-12">
                <h2>{{ $tc('common.premium-invoice.file.file', 2) }}</h2>
                <!-- Files -->
                <template v-if="state.premiumReminder?.files?.length">
                    <!-- TODO improvement @MTR: Create file library component. -->
                    <div class="file-library-wrapper">
                        <q-card
                            v-for="file in state.premiumReminder.files"
                            :key="`premium-invoice-file-${file.id}`"
                            class="file-library-item"
                            square
                            flat
                            bordered
                            data-test="item:premium-invoice-files"
                        >
                            <q-card-section horizontal>
                                <q-item class="items-start">
                                    <q-item-section side>
                                        <q-avatar square>
                                            <q-icon
                                                :name="getFileTypeIcon(file.mime_type || null)"
                                                size="sm"
                                                color="secondary"
                                            />
                                        </q-avatar>
                                    </q-item-section>

                                    <q-item-section>
                                        <q-item-label class="file-name" data-test="text:premium-invoice-file-name">{{ file.name }}</q-item-label>
                                        <q-item-label v-if="file.size" caption data-test="text:premium-invoice-file-size">{{ humanStorageSize(file.size) }}</q-item-label>
                                    </q-item-section>
                                </q-item>

                                <q-card-actions vertical>
                                    <q-btn
                                        class="do-not-print"
                                        color="primary"
                                        size="sm"
                                        flat
                                        round
                                        icon="mib-cloud-download"
                                        @click="file.download()"
                                    >
                                        <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.term.download-item', 1, { item: file.name }) }}</q-tooltip>
                                    </q-btn>
                                </q-card-actions>
                            </q-card-section>
                        </q-card>
                    </div>
                </template>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { onBeforeMount, reactive, ref } from 'vue'
import { PremiumReminder } from '@/models/premiumReminder'
import Actions from '@/components/Actions'
import FormDialog from '@/libs/form/components/FormDialog'
import { useRouter } from '@/composables/router'
import { useQuasar } from '@/composables/quasar'
import { useI18n } from '@/composables/i18n'
import { Contract } from '@/models/contract'
import { kebabCase } from 'lodash'
import { getFileTypeIcon, humanStorageSize } from '@/helpers/file'

export default {
    name: 'PremiumReminderDetail',
    meta () {
        return {
            title: !this.state.premiumReminder ? `${this.$t('common.term.loading')} …` : this.state.premiumReminder?.formattedIdentifier,
        }
    },
    components: {
        Actions,
        FormDialog,
    },
    props: {
        contactId: {
            type: String,
            required: true,
        },
        contractId: {
            type: String,
            required: true,
        },
        premiumReminderId: {
            type: String,
            required: true,
        },
    },
    setup (props) {
        // Composables
        const router = useRouter()
        const $q = useQuasar()
        const { tc } = useI18n()

        // Data
        const state = reactive({
            isLoading: true,
            premiumReminder: null,
        })

        // Template refs
        const deleteDialog = ref(null)

        // Functions
        async function fetchObjects () {
            state.contract = await Contract.objects.get(props.contractId)
            state.premiumReminder = await PremiumReminder.objects.get(props.premiumReminderId)
            state.isLoading = false
        }
        function handleActions (action) {
            switch (action.key) {
                case PremiumReminder.action.DELETE:
                    deleteDialog.value.open()
                    break
            }
        }
        function handleDelete () {
            state.premiumReminder.delete()
                .then(() => {
                    deleteDialog.value.close()
                    router.replace({ name: 'contract-detail', params: { contactId: props.contactId, id: props.contractId, tab: 'premium-invoices', type: 'reminders' } })

                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.premium-reminder.premium-reminder-deleted-success', 1),
                    })
                })
                .catch(error => {
                    deleteDialog.value.showFormErrorMessage(error)
                    deleteDialog.value.resetFormSubmitStatus()
                })
        }

        // Lifecycle hooks
        onBeforeMount(() => {
            fetchObjects()
        })

        return {
            // Data
            state,

            // Template refs
            deleteDialog,

            // Functions
            handleActions,
            handleDelete,
            kebabCase,
            getFileTypeIcon,
            humanStorageSize,
        }
    },
}
</script>
