import { apolloClient } from '@/apollo/client'

import { DXP_FILE_LIST_QUERY } from '@/graphql/dxpFileList/dxpFileList'

export const DxpFileListService = {
    get (search) {
        return apolloClient.query({
            query: DXP_FILE_LIST_QUERY,
            variables: {
                search,
            },
        }).then(response => response.data.dxpFileList)
    },
}
