import gql from 'graphql-tag'

export const IGB2B_INSURER_CODES_QUERY = gql`
    query igb2bInsurerCodes(
        $page: Int = 1
        $count: Int = 1000
        $orderField: IGB2BInsurerCodeOrderField = NAME
        $orderDirection: OrderDirection = ASC
        $filterQuery: String
        $filterIsAssignedToProductProvider: Boolean
    ) {
        igb2bInsurerCodes(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                query: $filterQuery
                is_assigned_to_product_provider: $filterIsAssignedToProductProvider
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                name
                is_assigned_to_product_provider
            }
        }
    }
`
