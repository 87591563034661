import { Model } from '@/models'
import { DxpContractMatchMixin } from '@/models/mixins/dxpContractMatchMixin'

export class DxpContractLink extends DxpContractMatchMixin(Model) {
    /**
     * Create a DxpContractLink model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'type',
            'file_import_identification_number',
            'contract_number',
            'replaced_contract_number',
            'framework_agreement_number',
            'customer_first_name',
            'customer_last_name',
            'customer_company_name',
            'files',
            'contractMatchShortlist',
            'matchedContract',
            'matched_contract_confirmed',
        ], data)
    }
}
