<!--TODO improvement: Integrate this component directly in `PageWrapper` and remove global import from main.js -->
<template>
    <q-page class="page-wrapper q-gutter-y-md">
        <page-header>
            <template v-slot:breadcrumbs>
                <!-- TODO improvement: Add possibility to pass text-only / static text breadcrumb elements directly and only use skeleton for additional / dynamic elements. -->
                <q-breadcrumbs-el v-for="index in numberOfBreadcrumbElements" :key="index">
                    <skeleton type="text" />
                </q-breadcrumbs-el>
            </template>

            <h1><skeleton height="2rem" inline /> <component :is="subtitleOnNewLine ? 'div' : 'span'" v-if="subtitle" class="subtitle"><skeleton
                :min-random-width="5"
                :max-random-width="15"
                height="1.5rem"
                :inline="!subtitleOnNewLine"
            /></component></h1>

            <template v-slot:actions>
                <div v-if="actions" class="actions-wrapper">
                    <template v-if="numberOfNextActions > 0">
                        <base-button
                            v-for="index in numberOfNextActions"
                            :key="index"
                            :class="['bg-secondary-lighter', { 'q-ml-sm': numberOfNextActions > 1 }]"
                            :style="`width: ${Math.floor(Math.random() * 9) + 6}em`"
                            flat
                            no-text-decoration
                            disabled
                        ><span class="hidden">{{ $t('common.app.name') }}</span></base-button>
                    </template>

                    <template v-if="settingsButton">
                        <base-button
                            icon="mib-cog-double-3"
                            class="q-ml-sm"
                            flat
                            round
                            text-color="secondary"
                            size="sm"
                            disabled
                        />
                    </template>

                    <template v-if="!noMoreActionsButton">
                        <base-button
                            icon="mib-navigation-menu-horizontal"
                            class="q-ml-sm"
                            flat
                            round
                            text-color="secondary"
                            size="sm"
                            disabled
                        />
                    </template>
                </div>
            </template>
        </page-header>

        <loading-indicator style="height: 30vh;" />
    </q-page>
</template>

<script>
export default {
    name: 'PageWrapper',
    props: {
        numberOfBreadcrumbElements: {
            type: Number,
            default: 1,
        },
        subtitle: {
            type: Boolean,
            default: false,
        },
        subtitleOnNewLine: {
            type: Boolean,
            default: false,
        },
        actions: {
            type: Boolean,
            default: false,
        },
        numberOfNextActions: {
            type: Number,
            default: 1,
        },
        noMoreActionsButton: {
            type: Boolean,
            default: false,
        },
        settingsButton: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
.page-wrapper {
    // TODO: Add/refine styling (including adjustments for responsive behavior)
    padding: pxToRem(24); // TODO: Replace value with $sizeSpacingSm or $sizeSpacingMd after MAX-479 has been merged
}
</style>
