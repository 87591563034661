<template>
    <div class="display-contents">
        <base-table
            :columns="state.columns"
            :fetch-objects-fn="PremiumReminder.service.all"
            :additional-filters="state.additionalFilters"
            user-settings-base-path="ContractPremiumReminderList"
            pagination-sort-by-default-key="reminder_date"
            :show-filter="false"
            enable-visible-columns
            no-top-padding
            @row-dblclick="onDblClick"
        >
            <template v-slot:tabs>
                <h2>{{ $tc('common.premium-reminder.reminder', 2) }}</h2>
            </template>

            <template v-slot:body-cell-actions="props">
                <q-td :props="props" auto-width>
                    <q-btn
                        class="do-not-print"
                        color="primary"
                        size="sm"
                        flat
                        round
                        icon="mib-view-1"
                        :to="{ name: 'premium-reminder-detail', params: { contactId: contract.customer.id, contractId: contract.id, premiumReminderId: props.row.id } }"
                    >
                        <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.premium-reminder.show-reminder', 1) }}</q-tooltip>
                    </q-btn>
                </q-td>
            </template>
        </base-table>
    </div>
</template>

<script>
import BaseTable from '@/components/BaseTable'
import { PremiumReminder } from '@/models/premiumReminder'
import { reactive } from 'vue'
import { useI18n } from '@/composables/i18n'
import { useRouter } from '@/composables/router'

export default {
    name: 'ContractPremiumReminderList',
    components: {
        BaseTable,
    },
    props: {
        contract: {
            type: Object,
            required: true,
        },
    },
    setup (props) {
        // Composables
        const { t, tc } = useI18n()
        const router = useRouter()

        // Data
        const state = reactive({
            columns: [
                {
                    name: 'reminder_date',
                    label: tc('common.premium-reminder.reminder-date', 1),
                    field: row => row.formattedReminderDate,
                    align: 'left',
                    classes: 'text-bold',
                    required: true,
                    sortable: true,
                },
                {
                    name: 'reminded_invoice',
                    label: tc('views.data-exchange.reminder-for-invoice--column-title', 1),
                    field: row => row.formattedInvoiceIdentifier || '–',
                    align: 'left',
                    classes: row => row.formattedInvoiceIdentifier ? '' : 'additional-info',
                    required: true,
                    sortable: true,
                },
                {
                    name: 'invoice_date',
                    label: tc('common.premium-invoice.invoice-date', 1),
                    field: row => row.formattedInvoiceDate || '–',
                    align: 'left',
                    classes: row => row.formattedInvoiceDate ? '' : 'additional-info',
                    required: false,
                    sortable: true,
                    hideInitially: true,
                },
                {
                    name: 'invoiced_amount',
                    label: tc('common.premium-invoice.invoiced-amount', 1),
                    field: row => row.formattedInvoicedAmount || '–',
                    align: 'left',
                    classes: row => row.invoiced_amount ? '' : 'additional-info',
                    required: true,
                    hideInitially: false,
                },
                {
                    name: 'reminder_charges',
                    label: tc('common.premium-reminder.reminder-charge', 2),
                    field: row => row.formattedReminderChargesAmount || '–',
                    align: 'left',
                    classes: row => row.formattedReminderChargesAmount ? '' : 'additional-info',
                    required: false,
                    hideInitially: false,
                },
                {
                    name: 'outstanding_amount',
                    label: tc('common.premium-reminder.outstanding-amount', 1),
                    field: row => row.formattedOutstandingInvoiceAmount || '–',
                    align: 'left',
                    classes: row => row.outstanding_invoice_amount ? '' : 'additional-info',
                    required: false,
                    hideInitially: false,
                },
                {
                    name: 'coverage_interruption',
                    label: tc('common.premium-reminder.coverage-interruption', 1),
                    field: row => row.formattedCoverageInterruptionFrom || '–',
                    align: 'left',
                    classes: row => row.coverage_interruption_from ? '' : 'additional-info',
                    required: false,
                    hideInitially: true,
                },
                {
                    name: 'actions',
                    label: tc('common.term.action', 2),
                    align: 'left',
                    required: true,
                },
            ],
            additionalFilters: {
                filterMatchedContractIds: [props.contract.id],
                filterMatchedContractConfirmed: true,
            },
        })

        // Functions
        function onDblClick (event, premiumReminder) {
            router.push({ name: 'premium-reminder-detail', params: { contactId: props.contract.customer.id, contractId: props.contract.id, premiumReminderId: premiumReminder.id } })
        }

        return {
            // Static
            PremiumReminder,

            // Data
            state,

            // Functions
            onDblClick,
        }
    },
}
</script>
