import gql from 'graphql-tag'

export const CONTACT_QUERY = gql`
    query contact(
        $id: ID!
    ) {
        contact(
            id: $id
        ) {
            id
            contactNumber {
                id
                value_structure
                number
            }
            status
            customer_status
            customer_since
            customer_until
            consultingSettings {
                contact_id
                status
                company {
                    id
                    company_name
                }
                registration_authority
                registration_number
                defaultDocumentLayout {
                    id
                }
                signature {
                    id
                    name
                    size
                    mime_type
                    created_at
                    updated_at
                }
                currentAccount {
                    id
                    type
                    status
                    number
                    name
                }
                cancellationReserveAccount {
                    id
                    type
                    status
                    number
                    name
                }
                otherIncomeAccount {
                    id
                    type
                    status
                    number
                    name
                }
                commissionIncomeAccount {
                    id
                    type
                    status
                    number
                    name
                }
                mailProcessingRevenueAccount {
                    id
                    type
                    status
                    number
                    name
                }
                statementRecipients {
                    id
                    consultants {
                        id
                    }
                }
                commissionDistributionRuleSet {
                    id
                    name
                    description
                    is_assigned
                }
                mailProcessingSettings {
                    active_from
                    active_until
                    mail_processing_is_active
                    price_per_processed_mail_item
                    notification_email_override
                    error_notification_email
                }
            }
            productProviderSettings {
                contact_id
                status
                display_name
                igb2bInsurerCode {
                    id
                    name
                }
            }
            consultants {
                id
                contact_number
                first_name
                last_name
                gender
                title
                consultingCompany {
                    id
                }
                consulting_company_name
                consulting_settings_status
            }
            addresses {
                id
                category {
                    id
                    key
                    name
                }
                label {
                    id
                    key
                    label
                }
                po_box
                address1
                address2
                address3
                zip
                city
                country {
                    id
                    name
                }
                valid_from
                valid_until
                is_main_correspondence_address
                status
            }
            mainAddress {
                id
                category {
                    id
                    key
                    name
                }
                label {
                    id
                    key
                    label
                }
                po_box
                address1
                address2
                address3
                zip
                city
                country {
                    id
                    name
                }
                valid_from
                valid_until
                is_main_correspondence_address
                status
            }
            emailAddresses {
                id
                category {
                    id
                    key
                    name
                }
                label {
                    id
                    key
                    label
                }
                email
                is_main_email_address
            }
            mainEmailAddress {
                id
                category {
                    id
                    key
                    name
                }
                label {
                    id
                    key
                    label
                }
                email
                is_main_email_address
            }
            phoneNumbers {
                id
                category {
                    id
                    key
                    name
                }
                label {
                    id
                    key
                    label
                }
                phone_number
                is_main_phone_number
            }
            mainPhoneNumber {
                id
                category {
                    id
                    key
                    name
                }
                label {
                    id
                    key
                    label
                }
                phone_number
                is_main_phone_number
            }
            website
            ... on Person {
                gender
                personal_pronoun
                title
                profession
                role
                first_name
                last_name
                nationality {
                    id
                    name
                }
                civil_status
                driving_licence_since
                date_of_birth
                date_of_death
                is_deceased
            }
            ... on Company {
                company_name
                uid
                is_liable_to_vat
                is_tenant_company
            }
            pinnedComments {
                id
                creator {
                    id
                    contact_number
                    first_name
                    last_name
                    gender
                    title
                    consultingCompany {
                        id
                    }
                    consulting_company_name
                }

                text
                pinned

                created_at
                updated_at
            },
            correspondenceLanguage {
                id
                name
            }
            contactConsultantRelationSettings {
                formality
                correspondence_salutation_override {
                    schemaType
                    schemaVersion
                    contentJSON
                    contentHTML
                    attrs
                }
                correspondenceSalutation {
                    id
                    contact_type
                    formality
                    gender
                    language {
                        id
                        name
                    }
                    is_default
                    salutation {
                        schemaType
                        schemaVersion
                        contentJSON
                        contentHTML
                        attrs
                    }
                }
                created_at
                updated_at
            }
            number_of_contracts
            number_of_contact_files
            number_of_comments
            created_at
            updated_at
        }
    }
`
