import { apolloClient } from '@/apollo/client'

import { buildDxpItemsQuery, DXP_ITEMS_QUERY } from '@/graphql/dxpItem/dxpItems'
import { DXP_ITEMS_DISMISS_MUTATION } from '@/graphql/dxpItem/dxpItemsDismiss'
import { DXP_ITEMS_RETRY_MUTATION } from '@/graphql/dxpItem/dxpItemsRetry'

export const DxpItemService = {
    all (variables, options) {
        const QUERY = (typeof options.fields !== 'undefined') ? buildDxpItemsQuery(options.fields, options.additionalParams) : DXP_ITEMS_QUERY
        const queryOptions = {
            query: QUERY,
            variables,
        }
        if (options?.abortController) {
            queryOptions.context = {
                fetchOptions: {
                    signal: options.abortController.signal,
                },
            }
        }
        return apolloClient.query(queryOptions).then(response => response.data.dxpItems)
    },
    retry (variables) {
        return apolloClient.mutate({
            mutation: DXP_ITEMS_RETRY_MUTATION,
            variables,
        }).then(response => response.data.dxpItemsRetry)
    },
    dismiss (variables) {
        return apolloClient.mutate({
            mutation: DXP_ITEMS_DISMISS_MUTATION,
            variables,
        }).then(response => response.data.dxpItemsDismiss)
    },
}
