import { i18n } from '@/i18n'
import { ContactType, GenderChoice } from '@/enums/graphql'

/**
 * Get the gender of a contact.
 * @param {Object} contact - The contact whose gender should be returned.
 * @param {string} contact.gender - The gender of the contact. (Any from the according enum `GenderChoice`.)
 * @param {boolean} [useAlternativeWording=false] - Option to return 'woman'/'man' instead of 'female'/'male'.
 * @returns {string}
 */
export function getContactGender (
    contact,
    {
        useAlternativeWording = false,
    } = {
        useAlternativeWording: false,
    }
) {
    if (contact.gender && !contact.personal_pronoun) {
        if (useAlternativeWording) {
            switch (contact.gender) {
                case GenderChoice.FEMALE:
                    return 'woman'
                case GenderChoice.MALE:
                    return 'man'
                default:
                    return 'neutral'
            }
        } else {
            return contact.gender.toLowerCase()
        }
    } else {
        return 'neutral'
    }
}

// TODO improvement: Add option to only return the additional info.
/**
 * Get the name of a contact. Returned string is formatted based on several options.
 * @param {Object} contact - The contact whose name should be returned.
 * @param {boolean} [salutation=false] - Option to include the salutation (based on the contact’s gender, e.g. 'Mr.' if the gender is 'MALE').
 * @param {boolean} [title=false] - Option to include the title (e.g. 'Prof. Dr.').
 * @param {boolean} [contactNumber=false] - Option to include the contact number.
 * @param {boolean} [commaSeparated=false] - Option to return a person’s name as 'Lastname, Firstname' (instead of 'Firstname Lastname'). Useful for lists that are sorted based on the person’s last name. (Applies to persons only.)
 * @param {boolean} [noFirstName=false] - Option to exclude a person’s first name. (Applies to persons only.)
 * @param {boolean} [noLastName=false] - Option to exclude a person’s last name. (Applies to persons only.)
 * @param {boolean} [displayName=false] - Option to use the display_name (if any) instead of the 'regular' name. (Applies to product providers only.)
 * @param {boolean} [consultingCompanyName=false] - Option to include the name of a consultant’s / consulting company’s superordinate consulting company (e.g. 'Firstname Lastname (Company Name)').
 * @param {boolean} [currentUserHint=false] - Option to include a "(me)" hint if the shown contact is the current user’s `user.consultant`.
 * @param {boolean} [nestedParentheses=false] - Option to use different parentheses in strings. (Used if the contactName is shown in parentheses and any of the options that returns text inside parentheses is activated.)
 * @returns {string}
 */


export function getContactName (
    contact,
    {
        salutation = false,
        title = false,
        contactNumber = false,
        commaSeparated = false,
        noFirstName = false,
        noLastName = false,
        displayName = false,
        consultingCompanyName = false,
        currentUserHint = false,
        nestedParentheses = false,
    } = {
        salutation: false,
        title: false,
        contactNumber: false,
        commaSeparated: false,
        noFirstName: false,
        noLastName: false,
        displayName: false,
        consultingCompanyName: false,
        currentUserHint: false,
        nestedParentheses: false,
    }
) {
    let contactName = []
    const parenthesis = {
        'open': (nestedParentheses) ? '[' : '(',
        'close': (nestedParentheses) ? ']' : ')',
    }

    if (displayName && contact.productProviderSettings && contact.productProviderSettings.display_name) {
        contactName.push(contact.productProviderSettings.display_name)
    } else if (displayName && contact.display_name) {
        contactName.push(contact.display_name)
    } else {
        if (contact.type === ContactType.COMPANY) {
            contactName.push(contact.company_name)
        } else {
            if (salutation && contact.gender) {
                contactName.push(i18n.t('common.contact.salutation-' + contact.gender.toLowerCase()))
            }
            if (title && contact.title) {
                contactName.push(contact.title)
            }
            if (commaSeparated) {
                contactName.push(`${contact.last_name}, ${contact.first_name}`)
            } else {
                if (!noFirstName) {
                    contactName.push(contact.first_name)
                }
                if (!noLastName) {
                    contactName.push(contact.last_name)
                }
            }
        }
    }
    if (contactNumber) {
        contactName.push(`${parenthesis.open}${contact.contactNumber?.number || contact.contact_number}${parenthesis.close}`)
    }
    // TODO: Check if it would make sense to check if the consultingSettings.company also is a product provider – and if so, use the productProviderSettings.display_name instead
    // TODO: If the above should be done, the according info must be added to the schema: contact.consultingSettings.company.productProviderSettings.display_name
    if (contact.type === ContactType.PERSON && consultingCompanyName && contact.consultingCompanyName) {
        contactName.push(`${parenthesis.open}${contact.consultingCompanyName}${parenthesis.close}`)
    }

    if (contact.type === ContactType.PERSON && currentUserHint) {
        contactName.push(`${parenthesis.open}${i18n.t('common.term.me')}${parenthesis.close}`)
    }

    return contactName.join(' ')
}

/**
 * Get the initials of a given contact’s name.
 *
 * As a default, the desired output would be the first letter of the first name and the first letter of the last name.
 * It gets more complex when a person has more than one first and/or last names.
 * In such a case, the desired output would depend on the maxLength and the amount of first and last names.
 *
 * Alternately add initials from the first and last names as long as there are some left.
 * Examples with different maxLength (first names: Jesse Michael, last names: Pinkman White Heisenberg):
 * 1: Jesse Michael Pinkman White Heisenberg --> J
 * 2: Jesse Michael Pinkman White Heisenberg --> JP
 * 3: Jesse Michael Pinkman White Heisenberg --> JMP
 * 4: Jesse Michael Pinkman White Heisenberg --> JMPW
 * 5: Jesse Michael Pinkman White Heisenberg --> JMPWH
 *
 * @param {Object} contact - The contact whose initials should be returned.
 * @param {string} contact.first_name
 * @param {string} contact.last_name
 * @param {Object} options
 * @param {string} [options.joinSeparator] - The string that should be used to join the individual initials.
 * @param {number} [options.maxLength=2] - The maximum amount of initials that should be returned.
 * @returns {string}
 */
export function getContactInitials (
    contact,
    {
        joinSeparator = '',
        maxLength = 2,
    } = {
        joinSeparator: '',
        maxLength: 2,
    }
) {
    // TODO improvement: Enhance so that this also works for companies (as it is used e.g. in the `ContactName` component).
    if (contact && contact.first_name && contact.last_name) {
        const initials = []

        const firstNames = contact.first_name.split(/[\s-]/)
        const lastNames = contact.last_name.split(/[\s-]/)

        const maxNumberOfInitials = Math.min(maxLength, (firstNames.length + lastNames.length))
        let threshold = Math.ceil(maxNumberOfInitials / 2)
        if (threshold > firstNames.length) threshold = firstNames.length // Make sure there are enough firstnames, if not use lastnames before the calculated threshold.
        if (maxNumberOfInitials - threshold > lastNames.length) threshold = maxNumberOfInitials - lastNames.length // Make sure there are enough lastnames, if not use firstnames before the calculated threshold.

        for (let i = 0; i < maxNumberOfInitials; i++) {
            const name = (i < threshold) ? firstNames.shift() : lastNames.shift()
            initials.push(name.substring(0, 1).toUpperCase())
        }

        return initials.join(joinSeparator) + joinSeparator
    }

    return ''
}

/**
 * Returns a formatted address based on the passed params.
 * @param {Object} address - The address that should be formatted.
 * @param {boolean=false} [multiline] - Option to format the address so that it uses one line per address part so that the address can be used for letters etc. (Address parts are divided by a comma ',' by default so that they can be used inline in a text.)
 * @param {boolean=false} [noPOBox] - Option to exclude the 'P.O. Box' info from an address. Useful when the address should be used for something else than a letter or the like (e.g. looked up in a map).
 * @param {string="CH"} [countryOfOrigin] - ISO Code of the country in which the user currently is in.
 * @param {boolean=true} [excludeCountryIfSameAsOrigin] - Option to exclude the country from the address. If the address.country.id is the same country as the user is in (`countryOfOrigin`), it does not make sense to show the country in an address.
 * @returns {string}
 */
// TODO: Rename to "getFormattedAddress" (as we do NOT pass a contact to the function)
export function getContactAddress (
    address,
    {
        multiline = false,
        noPOBox = false,
        countryOfOrigin = 'CH',
        excludeCountryIfSameAsOrigin = true,
    } = {
        multiline: false,
        noPOBox: false,
        countryOfOrigin: 'CH',
        excludeCountryIfSameAsOrigin: true,
    }
) {
    let fullAddress = []

    if (address.address1) fullAddress.push(address.address1)
    if (address.address2) fullAddress.push(address.address2)
    if (address.address3) fullAddress.push(address.address3)
    if (address.po_box && !noPOBox) fullAddress.push(i18n.tc('common.address.po-box', 1))
    if (address.zip && address.city) fullAddress.push(`${address.zip} ${address.city}`)
    // TODO: Always use English or French country names. (Official Swiss Post specs: "Schreiben Sie den Ländernamen in französischer oder englischer Sprache auf der untersten Adresszeile in lateinischer Schrift und in Grossbuchstaben.")
    // TODO: Extended version, optional – use country-specific languages for Germany, Austria, France and Italy. (Official Swiss Post specs: "Für Sendungen in die Nachbarländer der Schweiz können Sie es in der jeweiligen Landesprache vermerken.")
    if (address.country) {
        // Only show the country in an address if the address is in another country (or if this feature is explicitly disabled).
        if (address.country.id !== countryOfOrigin || excludeCountryIfSameAsOrigin === false) {
            fullAddress.push(address.country.name.toUpperCase())
        }
    }

    return (multiline) ? fullAddress.join('\n') : fullAddress.join(', ')
}

/**
 * Get the age of a contact. Either returns the age per now (default) or the age per a specific date.
 * @param {string} dateOfBirth
 * @param {string} [ageByDate]
 * @returns {number}
 */
export function getAge (dateOfBirth, ageByDate) {
    let ageBy = ageByDate ? new Date(ageByDate) : Date.now()
    let birth = new Date(dateOfBirth)
    let ageDifMs = ageBy - birth.getTime()
    let ageDate = new Date(ageDifMs) // milliseconds from epoch

    return Math.abs(ageDate.getUTCFullYear() - 1970)
}

/**
 * Get the icon of the type of a contact.
 * @param {Object} contact - The contact whose gender should be returned.
 * @param {string} contact.type - The type of the contact. (Any from the according enum `ContactType`.)
 * @returns {string}
 */
export function getContactTypeIcon (contact) {
    switch (contact.type) {
        case ContactType.COMPANY:
            return 'mib-building-1'
        case ContactType.PERSON:
            return `mib-single-${getContactGender(contact, { useAlternativeWording: true })}`
        default:
            return 'mib-single-neutral'
    }
}

/**
 * Parse form data values.
 * @export
 * @param  {Object} formData - {company, currentAccount, cancellationReserveAccount, otherIncomeAccount, commissionIncomeAccount, commissionDistributionRuleSet, statementRecipientIds}
 * @return {Object} - ConsultingSettingsInput
 */
export function parseFormDataToConsultingSettingsInput (formData, user) {
    const input = {
        company_id: formData.company || null,
        registration_authority: formData.registrationAuthority || null,
        registration_number: formData.registrationNumber || '',
    }

    // Accounting
    if (user.aclFeatures.includes('Feature:accounting:core') && user.aclPermissions.includes('Accounting:manage')) {
        const accountingInputs = {}

        if (typeof formData.currentAccount != 'undefined') accountingInputs.current_account_id = formData.currentAccount || null
        if (typeof formData.cancellationReserveAccount != 'undefined') accountingInputs.cancellation_reserve_account_id = formData.cancellationReserveAccount || null
        if (typeof formData.otherIncomeAccount != 'undefined') accountingInputs.other_income_account_id = formData.otherIncomeAccount || null
        if (typeof formData.commissionIncomeAccount != 'undefined') accountingInputs.commission_income_account_id = formData.commissionIncomeAccount || null
        if (typeof formData.mailProcessingRevenueAccount != 'undefined') accountingInputs.mail_processing_revenue_account_id = formData.mailProcessingRevenueAccount || null
        if (typeof formData.statementRecipientIds != 'undefined') accountingInputs.statement_recipient_ids = formData.statementRecipientIds || null

        Object.assign(input, accountingInputs)
    }

    // Commissions
    if (user.aclFeatures.includes('Feature:commissions:core') && user.aclPermissions.includes('Commissions:manage')) {
        const commissionsInputs = {}

        if (typeof formData.commissionDistributionRuleSet != 'undefined') commissionsInputs.commission_distribution_rule_set_id = formData.commissionDistributionRuleSet || null

        Object.assign(input, commissionsInputs)
    }

    // MailProcessing
    if (user.aclFeatures.includes('Feature:mailProcessing:core') && user.aclPermissions.includes('MailProcessingSettings:manage')) {
        const mailProcessingSettingsInputs = {}

        if (typeof formData.activeFrom != 'undefined') mailProcessingSettingsInputs.active_from = formData.activeFrom || null
        if (typeof formData.activeUntil != 'undefined') mailProcessingSettingsInputs.active_until = formData.activeUntil || null
        if (typeof formData.pricePerProcessedMailItem != 'undefined') {
            const pricePerProcessedMailItem = parseFloat(formData.pricePerProcessedMailItem)
            mailProcessingSettingsInputs.price_per_processed_mail_item = isNaN(pricePerProcessedMailItem) ? null : pricePerProcessedMailItem
        }
        if (typeof formData.notificationEmailOverride != 'undefined') mailProcessingSettingsInputs.notification_email_override = formData.notificationEmailOverride || ''
        if (typeof formData.errorNotificationEmail != 'undefined') mailProcessingSettingsInputs.error_notification_email = formData.errorNotificationEmail || ''

        Object.assign(input, { mailProcessingSettings: mailProcessingSettingsInputs })
    }

    return input
}

/**
 * Validate consulting settings form data.
 *
 * @param  {Object} formData - {company, currentAccount, cancellationReserveAccount, otherIncomeAccount, commissionIncomeAccount, commissionDistributionRuleSet}
 * @return {Object} - { graphQLErrors: {message}[] }
 */
/// TODO refactoring @TFU: Check if still needed
export function validateConsultingSettingsFormData(formData) {
    const accountKeys = ['currentAccount','cancellationReserveAccount','otherIncomeAccount','commissionIncomeAccount']
    const usedValues = []
    const errorMessages = { graphQLErrors: [] }

    const accountValues = Object.entries(formData)
        .map(entry => ({ key: entry[0], value: entry[1] }))
        .filter(entry => accountKeys.includes(entry.key) && entry.value)

    accountValues.forEach(entry => {
        const { key, value } = entry

        if (usedValues.includes(value)) {
            errorMessages.graphQLErrors.push({ message: `The selection for ${key} is already been taken.` }) // TODO translation @MTR
        } else {
            usedValues.push(value)
        }
    })

    return errorMessages
}
