import { apolloClient } from '@/apollo/client'

import { PREMIUM_INVOICE_QUERY } from '@/graphql/premiumInvoice/premiumInvoice'
import { PREMIUM_INVOICES_QUERY } from '@/graphql/premiumInvoice/premiumInvoices'
import { PREMIUM_INVOICE_CREATE_MUTATION } from '@/graphql/premiumInvoice/premiumInvoiceCreate'
import { PREMIUM_INVOICE_UPDATE_MUTATION } from '@/graphql/premiumInvoice/premiumInvoiceUpdate'
import { PREMIUM_INVOICE_DELETE_MUTATION } from '@/graphql/premiumInvoice/premiumInvoiceDelete'

export const PremiumInvoiceService = {
    get (id) {
        return apolloClient.query({
            query: PREMIUM_INVOICE_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.premiumInvoice)
    },
    all (variables) {
        return apolloClient.query({
            query: PREMIUM_INVOICES_QUERY,
            variables,
        }).then(response => response.data.premiumInvoices)
    },
    create (variables) {
        return apolloClient.mutate({
            mutation: PREMIUM_INVOICE_CREATE_MUTATION,
            variables,
        }).then(response => response.data.premiumInvoiceCreate)
    },
    update (id, premiumInvoiceVariables) {
        return apolloClient.mutate({
            mutation: PREMIUM_INVOICE_UPDATE_MUTATION,
            variables: {
                id: id,
                premiumInvoice: premiumInvoiceVariables,
            },
        }).then(response => response.data.premiumInvoiceUpdate)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: PREMIUM_INVOICE_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.premiumInvoiceDelete)
    },
}
