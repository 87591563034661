<template>
    <page-loading-indicator
        v-if="state.loading"
        :number-of-breadcrumb-elements="2"
        actions
        :number-of-next-actions="1"
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$t('common.eco-hub.eco-hub')" :to="{ name: 'admin-eco-hub' }" data-test="breadcrumb:admin-eco-hub" />
                <q-breadcrumbs-el :label="$tc('views.admin.eco-hub.tech-user.tech-user', 1)" :to="{ name: 'eco-hub-tech-user-detail' }" data-test="breadcrumb:eco-hub-tech-user-detail" />
            </template>

            <h1>{{ $tc('views.admin.eco-hub.tech-user.tech-user', 1) }}</H1>
            <template v-if="state.ecoHubTechUser" v-slot:actions>
                <actions
                    :actions="state.ecoHubTechUser.actions"
                    @click="handleActions"
                />

                <!-- Delete Account -->
                <form-dialog
                    ref="deleteDialog"
                    double-confirm
                    @submit="handleDelete"
                >
                    <template v-slot:title>{{ $tc('views.admin.eco-hub.tech-user.delete-tech-user', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.admin.eco-hub.tech-user.delete-tech-user--confirm--temp-component-interpolation-count" tag="p">
                            <template v-if="state.ecoHubTechUser" v-slot:techUserId><b>{{ state.ecoHubTechUser.idp_user_id }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <div class="row print-row-lg">
            <grid-card
                v-if="state.ecoHubTechUser"
                :columns="{
                    'xs': 12,
                    'sm': 12,
                    'md': 8,
                    'lg': 6,
                    'xl': 6,
                }"
                :heading="$tc('views.admin.eco-hub.tech-user.enrolled-tech-user', 1)"
                :heading-info="$t('views.admin.eco-hub.tech-user.tech-user--info--short')"
            >
                <p>ID: <span class="monospace text-bold">{{ state.ecoHubTechUser.idp_user_id }}</span></p>
            </grid-card>

            <div v-else class="col-xs-12 col-md-8 col-lg-6">
                <info-box>
                    <p>{{ $t('views.admin.eco-hub.tech-user.tech-user--info') }}</p>

                    <template v-slot:action>
                        <base-button
                            :label="$tc('views.admin.eco-hub.tech-user.enroll-tech-user', 1)"
                            :to="{ name: 'eco-hub-tech-user-create' }"
                            primary-button
                            outline
                        />
                    </template>
                </info-box>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { EcoHubTechUser } from '@/models/ecoHubTechUser'
import { onBeforeMount, reactive, ref } from 'vue'
import FormDialog from '@/libs/form/components/FormDialog'
import Actions from '@/components/Actions'
import { useQuasar } from '@/composables/quasar'
import { useI18n } from '@/composables/i18n'
import { useRouter } from '@/composables/router'

export default {
    name: 'EcoHubTechUserDetail',
    meta () {
        return {
            title: this.$tc('views.admin.eco-hub.tech-user.tech-user', 1),
        }
    },
    components: {
        Actions,
        FormDialog,
    },
    setup () {
        // Composables
        const $q = useQuasar()
        const router = useRouter()
        const { tc } = useI18n()

        // Data
        const state = reactive({
            loading: true,
            ecoHubTechUser: null,
        })

        // Template refs
        const deleteDialog = ref(null)

        // Functions
        function fetchObject () {
            EcoHubTechUser.service.get().then(ecoHubTechUser => {
                state.ecoHubTechUser = ecoHubTechUser
                state.loading = false
            }).catch(() => {
                state.loading = false
            })
        }
        function handleActions (action) {
            switch (action.key) {
                case EcoHubTechUser.action.RE_ENROLL:
                    router.push({ name: 'eco-hub-tech-user-update' })
                    break
                case EcoHubTechUser.action.DELETE:
                    deleteDialog.value.open()
                    break
            }
        }
        function handleDelete () {
            state.ecoHubTechUser.delete()
                .then(() => {
                    deleteDialog.value.close()
                    state.ecoHubTechUser = null
                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.eco-hub.tech-user.tech-user-deleted-success', 1),
                    })
                })
                .catch(error => {
                    deleteDialog.value.showFormErrorMessage(error)
                    deleteDialog.value.resetFormSubmitStatus()
                })
        }

        // Lifecycle hooks
        onBeforeMount(() => {
            fetchObject()
        })

        return {
            // Data
            state,

            // Template refs
            deleteDialog,

            // Functions
            handleActions,
            handleDelete,
        }
    },
}
</script>
