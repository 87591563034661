import { DxpDocumentTypeIdentifier } from '@/enums/graphql'
import { checkRouteAccessPermissions, checkRouteAccessPermissionsNested } from '@/helpers/acl'

import DefaultLayout from '@/layouts/Default.vue'
import EcoHubDataExchange from '@/views/ecoHub/EcoHubDataExchange'
import EcoHubTechUserDetail from '@/views/ecoHub/EcoHubTechUserDetail'
import EcoHubTechUserCreate from '@/views/ecoHub/EcoHubTechUserCreate'
import EcoHubTechUserUpdate from '@/views/ecoHub/EcoHubTechUserUpdate'
import DxpServiceProviderConfigurationList from '@/views/ecoHub/dxpServiceProviderConfiguration/DxpServiceProviderConfigurationList'
import DxpServiceProviderConfigurationUpdate from '@/views/ecoHub/dxpServiceProviderConfiguration/DxpServiceProviderConfigurationUpdate.vue'
import { kebabCase } from 'lodash'


export default [
    // System preferences
    {
        path: '/admin/integrations/eco-hub/',
        name: 'admin-eco-hub',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:ecoHub:core'],
            can: checkRouteAccessPermissions,
            appSubSection: 'admin',
            navGroup: 'integrations',
        },
        // TODO improvement: Conditional redirect to submenu item based on user permission? ("go to first (available) sub item")
        redirect: {
            name: 'eco-hub-dxp-service-provider-configuration-list',
        },
    },

    {
        path: '/admin/integrations/eco-hub/tech-user/',
        name: 'eco-hub-tech-user',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:ecoHub:core', 'EcoHubTechUser:manage'],
            can: checkRouteAccessPermissionsNested,
            appSubSection: 'admin',
            navGroup: 'integrations',
        },
        children: [
            {
                path: '/',
                name: 'eco-hub-tech-user-detail',
                component: EcoHubTechUserDetail,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'integrations',
                    subNavGroup: 'ecoHub',
                },
            },
            {
                path: 'create',
                name: 'eco-hub-tech-user-create',
                component: EcoHubTechUserCreate,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'integrations',
                    subNavGroup: 'ecoHub',
                },
            },
            {
                path: 'update',
                name: 'eco-hub-tech-user-update',
                component: EcoHubTechUserUpdate,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'integrations',
                    subNavGroup: 'ecoHub',
                },
            },
        ],
    },

    {
        path: '/admin/integrations/eco-hub/dxp-service-provider-configuration/',
        name: 'eco-hub-dxp-service-provider-configuration',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:ecoHub:core', 'DxpServiceProviderConfiguration:manage'],
            can: checkRouteAccessPermissionsNested,
            appSubSection: 'admin',
            navGroup: 'integrations',
        },
        redirect: {
            name: 'eco-hub-dxp-service-provider-configuration-list',
        },
        children: [
            {
                path: '',
                name: 'eco-hub-dxp-service-provider-configuration-list',
                component: DxpServiceProviderConfigurationList,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'integrations',
                    subNavGroup: 'ecoHub',
                },
            },
            {
                path: ':id',
                name: 'eco-hub-dxp-service-provider-configuration-update',
                component: DxpServiceProviderConfigurationUpdate,
                props: true,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'integrations',
                    subNavGroup: 'ecoHub',
                },
            },
        ],
    },

    {
        path: '/data-exchange/eco-hub',
        name: 'eco-hub',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:ecoHub:dxp'],
            can: checkRouteAccessPermissionsNested,
            navGroup: 'dataExchange',
            subNavGroup: 'ecoHub',
        },
        redirect: { name: 'eco-hub-data-exchange', params: { statusGroup: 'pending' } },
        children: [
            {
                path: `:statusGroup(pending|processing|done)/:documentType(${Object.values(DxpDocumentTypeIdentifier).map(documentTypeIdentifier => kebabCase(documentTypeIdentifier)).join('|')})?`,
                name: 'eco-hub-data-exchange',
                component: EcoHubDataExchange,
                meta: {
                    navGroup: 'dataExchange',
                    subNavGroup: 'ecoHub',
                },
                props: true,
            },
        ],
    },
]
