import DxpServiceProviderSelect from '@/components/dxp/DxpServiceProviderSelect.vue'
import BaseInput from '@/components/form/BaseInput.vue'
import CalendarInput from '@/components/form/CalendarInput.vue'
import MonthDayInput from '@/components/form/MonthDayInput.vue'
import BaseCheckbox from '@/components/form/BaseCheckbox.vue'
import BaseSelect from '@/components/form/BaseSelect.vue'
import BaseToggle from '@/components/form/BaseToggle.vue'
import CountrySelect from '@/components/form/CountrySelect.vue'
import ContactSelect from '@/components/contact/ContactSelect.vue'
import ContactRelationshipSelect from '@/components/contactRelationship/ContactRelationshipSelect.vue'
import ContactRelationshipDefinitionSelect from '@/components/contactRelationshipDefinitions/ContactRelationshipDefinitionSelect.vue'
import ConsultantSelect from '@/components/contact/ConsultantSelect.vue'
import CompanySelect from '@/components/contact/CompanySelect.vue'
import RoleSelect from '@/components/user/RoleSelect.vue'
import ProductTemplateSelect from '@/components/product/ProductTemplateSelect.vue'
import ProductProviderSelect from '@/components/product/ProductProviderSelect.vue'
import ProductSelect from '@/components/product/ProductSelect.vue'
import ContactAddressCategorySelect from '@/components/contact/ContactAddressCategorySelect'
import ContactAddressLabelSelect from '@/components/contact/ContactAddressLabelSelect.vue'
import ContactEmailAddressCategorySelect from '@/components/contact/ContactEmailAddressCategorySelect.vue'
import ContactEmailAddressLabelSelect from '@/components/contact/ContactEmailAddressLabelSelect.vue'
import ContactPhoneNumberCategorySelect from '@/components/contact/ContactPhoneNumberCategorySelect.vue'
import ContactPhoneNumberLabelSelect from '@/components/contact/ContactPhoneNumberLabelSelect.vue'
import ContactFileTypeSelect from '@/components/consultingFile/ContactFileTypeSelect.vue'
import ApplicationFileTypeSelect from '@/components/consultingFile/ApplicationFileTypeSelect.vue'
import ContractFileTypeSelect from '@/components/consultingFile/ContractFileTypeSelect.vue'
import EntityNumberInputNext from '@/components/form/EntityNumberInputNext.vue'
import CorrespondenceLanguageSelect from '@/components/CorrespondenceLanguageSelect'
import CorrespondenceSalutationSelect from '@/components/correspondence/CorrespondenceSalutationSelect'
import Editor from '@/components/editor/Editor'
import CommissionListImportExcelMappingSelect from '@/components/commission/CommissionListImportExcelMappingSelect'
import CommissionDistributionRuleSetSelect from '@/components/commission/CommissionDistributionRuleSetSelect'
import IGB2BInsurerCodeSelect from '@/components/IGB2BInsurerCodeSelect'
import IGB2BSectorCodeSelect from '@/components/IGB2BSectorCodeSelect'
import PostingTypeSelect from '@/components/accounting/PostingTypeSelect'
import AccountSelect from '@/components/accounting/AccountSelect'
import GeneralAgencySelect from '@/components/contact/GeneralAgencySelect'
import ConsultingFileSelect from '@/components/consultingFile/ConsultingFileSelect'
import FileUpload from '@/components/form/FileUpload'
import BaseOptionGroup from '@/components/form/BaseOptionGroup'
import { i18n } from '@/i18n'
import DocumentTemplateSelect from '@/components/documents/DocumentTemplateSelect'
import ConsultingFileTypeSelect from '@/components/consultingFile/ConsultingFileTypeSelect'

export const FormBuilderConfiguration = {
    supportedInputTypes: {},
    registerInputType (inputTypeKey, inputTypeConfiguration) {
        this.supportedInputTypes[inputTypeKey] = inputTypeConfiguration
    },
    errorMessages: {},
    registerErrorMessage (validatorType, fn) {
        this.errorMessages[validatorType] = fn
    },
}

// Input types
FormBuilderConfiguration.registerInputType('text', { component: BaseInput, initialValue: '' })
FormBuilderConfiguration.registerInputType('password', { component: BaseInput, initialValue: '' })
FormBuilderConfiguration.registerInputType('textarea', { component: BaseInput, initialValue: '' })
FormBuilderConfiguration.registerInputType('number', { component: BaseInput, initialValue: '' })
FormBuilderConfiguration.registerInputType('calendar', { component: CalendarInput, initialValue: '' })
FormBuilderConfiguration.registerInputType('monthDay', { component: MonthDayInput, initialValue: '' })
FormBuilderConfiguration.registerInputType('checkbox', { component: BaseCheckbox, initialValue: false })
FormBuilderConfiguration.registerInputType('select', { component: BaseSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('toggle', { component: BaseToggle, initialValue: false })
FormBuilderConfiguration.registerInputType('country', { component: CountrySelect, initialValue: null })
FormBuilderConfiguration.registerInputType('contact', { component: ContactSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('contactRelationship', { component: ContactRelationshipSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('contactRelationshipDefinition', { component: ContactRelationshipDefinitionSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('consultant', { component: ConsultantSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('consultants', { component: ConsultantSelect, initialValue: () => [] })
FormBuilderConfiguration.registerInputType('company', { component: CompanySelect, initialValue: null })
FormBuilderConfiguration.registerInputType('roles', { component: RoleSelect, initialValue: [] })
FormBuilderConfiguration.registerInputType('productTemplate', { component: ProductTemplateSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('productProvider', { component: ProductProviderSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('product', { component: ProductSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('addressCategory', { component: ContactAddressCategorySelect, initialValue: null })
FormBuilderConfiguration.registerInputType('addressLabel', { component: ContactAddressLabelSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('emailAddressCategory', { component: ContactEmailAddressCategorySelect, initialValue: null })
FormBuilderConfiguration.registerInputType('emailAddressLabel', { component: ContactEmailAddressLabelSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('phoneNumberCategory', { component: ContactPhoneNumberCategorySelect, initialValue: null })
FormBuilderConfiguration.registerInputType('phoneNumberLabel', { component: ContactPhoneNumberLabelSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('contactFileType', { component: ContactFileTypeSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('applicationFileType', { component: ApplicationFileTypeSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('contractFileType', { component: ContractFileTypeSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('entityNumber', { component: EntityNumberInputNext, initialValue: () => [] })
FormBuilderConfiguration.registerInputType('correspondenceLanguage', { component: CorrespondenceLanguageSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('correspondenceSalutation', { component: CorrespondenceSalutationSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('editor', { component: Editor }) // Initial value is handled directly inside of @/helpers/form.js // TODO @TFU: Handle initial value when using inputType='editor' with formbuilder-next.
FormBuilderConfiguration.registerInputType('commissionListImportExcelMapping', { component: CommissionListImportExcelMappingSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('commissionDistributionRuleSet', { component: CommissionDistributionRuleSetSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('igb2bInsurerCode', { component: IGB2BInsurerCodeSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('igb2bSectorCode', { component: IGB2BSectorCodeSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('postingType', { component: PostingTypeSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('account', { component: AccountSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('generalAgency', { component: GeneralAgencySelect, initialValue: null })
FormBuilderConfiguration.registerInputType('consultingFiles', { component: ConsultingFileSelect, initialValue: () => [] })
FormBuilderConfiguration.registerInputType('fileUpload', { component: FileUpload, initialValue: () => [] })
FormBuilderConfiguration.registerInputType('optionGroup', { component: BaseOptionGroup, initialValue: (configuration) => (configuration.props?.type === 'radio') ? false : [] })
FormBuilderConfiguration.registerInputType('documentTemplate', { component: DocumentTemplateSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('consultingFileType', { component: ConsultingFileTypeSelect, initialValue: null })
FormBuilderConfiguration.registerInputType('dxpServiceProvider', { component: DxpServiceProviderSelect, initialValue: null })

// Error messages
// TODO: How to handle pluralization?
FormBuilderConfiguration.registerErrorMessage('required', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.required', { field: item.configuration.label }))
FormBuilderConfiguration.registerErrorMessage('requiredIf', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.required-if', { field: item.configuration.label, target: i18n.tc(validator.params.targetLabel) }))
FormBuilderConfiguration.registerErrorMessage('requiredUnless', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.required-unless', { field: item.configuration.label, target: i18n.tc(validator.params.targetLabel) }))
FormBuilderConfiguration.registerErrorMessage('requiredProperty', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.required', { field: item.configuration.label }))
FormBuilderConfiguration.registerErrorMessage('email', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.type-email', { field: item.configuration.label }))
FormBuilderConfiguration.registerErrorMessage('decimal', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.type-decimal', { field: item.configuration.label }))
FormBuilderConfiguration.registerErrorMessage('maxLength', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.max-length', { field: item.configuration.label, n: validator.params.max }))
FormBuilderConfiguration.registerErrorMessage('maxValue', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.max-value', { field: item.configuration.label, n: validator.params.max }))
FormBuilderConfiguration.registerErrorMessage('minLength', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.min-length', { field: item.configuration.label, n: validator.params.minErrorMessage || validator.params.min }))
FormBuilderConfiguration.registerErrorMessage('minValue', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.min-value', { field: item.configuration.label, n: validator.params.min }))
FormBuilderConfiguration.registerErrorMessage('minRequirementsLowercase', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.min-requirements-lowercase', { field: item.configuration.label }))
FormBuilderConfiguration.registerErrorMessage('minRequirementsUppercase', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.min-requirements-uppercase', { field: item.configuration.label }))
FormBuilderConfiguration.registerErrorMessage('minRequirementsSpecialCharOrNumber', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.min-requirements-special-char-number', { field: item.configuration.label }))
FormBuilderConfiguration.registerErrorMessage('sameAs', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.same-as', { field: item.configuration.label, equalTo: validator.params.equalToLabel }))
FormBuilderConfiguration.registerErrorMessage('notSameAs', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.not-same-as', { field: item.configuration.label, target: i18n.tc(validator.params.targetLabel) }))
FormBuilderConfiguration.registerErrorMessage('date', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.type-date', { field: item.configuration.label }))
FormBuilderConfiguration.registerErrorMessage('monthDay', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.type-month-day', { field: item.configuration.label }))
FormBuilderConfiguration.registerErrorMessage('isTrue', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.is-true', { field: item.configuration.label }))
FormBuilderConfiguration.registerErrorMessage('url', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.type-url', { field: item.configuration.label }))
FormBuilderConfiguration.registerErrorMessage('notEqualTo', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.not-equal-to', { field: item.configuration.label }))
FormBuilderConfiguration.registerErrorMessage('isBeforeOrEqualToDate', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.is-before-or-equal-to-date', { field: item.configuration.label, target: i18n.tc(validator.params.targetLabel) }))
FormBuilderConfiguration.registerErrorMessage('isAfterOrEqualToDate', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.is-after-or-equal-to-date', { field: item.configuration.label, target: i18n.tc(validator.params.targetLabel) }))
FormBuilderConfiguration.registerErrorMessage('lessThan', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.less-than', { field: item.configuration.label, target: i18n.tc(validator.params.targetLabel) }))
FormBuilderConfiguration.registerErrorMessage('moreThan', (item, validator) => i18n.t(validator.errorMessageTranslationKey || 'common.error-message.more-than', { field: item.configuration.label, target: i18n.tc(validator.params.targetLabel) }))
