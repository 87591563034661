<template>
    <div class="display-contents">
        <h2>{{ $tc('common.premium-invoice.premium-invoice', 2) }}</h2>
        <q-tabs
            dense
            no-caps
            inline-label
            align="left"
            class="q-mr-sm bg-background-secondary"
            indicator-color="accent"
        >
            <q-route-tab
                v-if="$can('Feature:ecoHub:dxp') && $can('PremiumInvoice:manage')"
                :to="{ name: 'contract-detail-premium-invoices', params: { contactId: contract.customer.id, id: contract.id, tab: 'premium-invoices', type: 'invoices' } }"
                :label="$tc('common.premium-invoice.invoice', 2)"
            />
            <q-route-tab
                v-if="$can('Feature:ecoHub:dxp') && $can('PremiumReminder:manage')"
                :to="{ name: 'contract-detail-premium-invoices', params: { contactId: contract.customer.id, id: contract.id, tab: 'premium-invoices', type: 'reminders' } }"
                :label="$tc('common.premium-reminder.reminder', 2)"
            />
        </q-tabs>

        <q-tab-panels
            v-model="currentType"
            animated
            transition-prev="fade"
            transition-next="fade"
        >
            <q-tab-panel v-if="$can('Feature:ecoHub:dxp') && $can('PremiumInvoice:manage')" name="invoices">
                <contract-premium-invoice-list
                    ref="contractPremiumInvoiceList"
                    :contract="contract"
                />
            </q-tab-panel>
            <q-tab-panel v-if="$can('Feature:ecoHub:dxp') && $can('PremiumReminder:manage')" name="reminders">
                <contract-premium-reminder-list
                    ref="contractPremiumReminderList"
                    :contract="contract"
                />
            </q-tab-panel>
        </q-tab-panels>
    </div>
</template>

<script>
import BaseTable from '@/components/BaseTable'
import { computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import ContractPremiumInvoiceList from '@/components/contract/ContractPremiumInvoiceList'
import ContractPremiumReminderList from '@/components/contract/ContractPremiumReminderList'

export default {
    name: 'ContractPremiumInvoices',
    components: {
        BaseTable,
        ContractPremiumInvoiceList,
        ContractPremiumReminderList,
    },
    props: {
        contract: {
            type: Object,
            required: true,
        },
    },
    setup (props) {
        // Composables
        const route = useRoute()
        const currentType = computed(() => route.params.type)

        return {
            currentType,
        }
    },
}
</script>
