import { Model } from '@/models'

import { ContractService } from '@/services'
import { ContractStatus, CancellationStatus, ProductDerivedEntityType } from '@/enums/graphql'
import { ContractActions } from '@/enums'
import { getTargetObjectType, walkJSON } from '@/helpers'
import { formatDate, formatMonthDay, monthDayToIsoDate } from '@/helpers/date'
import { formatCurrency } from '@/helpers/number'
import { createProductFieldIdValuePairs } from '@/helpers/contract'
import { ProductDerivedEntity } from '@/models/productDerivedEntity'
import store from '@/store/store'
import { isValidIsoDate } from '@/helpers/form'

export class Contract extends ProductDerivedEntity {
    constructor (data) {
        super(data)
        Model.initializeFields(this, [
            'contractNumbers',
            'applications',
            'currentChangeApplication',
            'cancellations',
            'termination_date',
            'pinnedComments',
            'replacedBy',
            'replaces',
            'is_expired',
            'number_of_contract_files',
            'number_of_comments',
            'commissionSplits',
            'commissionRecipientOverride',
            'has_commission_recipient_override',
        ], data)
        this.type = ProductDerivedEntityType.CONTRACT

        this.statusTransitions[ContractStatus.ACTIVE] = [ContractStatus.TERMINATED]
        this.statusTransitions[ContractStatus.TERMINATED] = [ContractStatus.ACTIVE, ContractStatus.ARCHIVED]

        this.statusActionMapping[ContractStatus.TERMINATED] = ContractActions.TERMINATE
        this.statusActionMapping[ContractStatus.ACTIVE] = ContractActions.ACTIVATE
        this.statusActionMapping[ContractStatus.ARCHIVED] = ContractActions.ARCHIVE
    }

    static get service () { return ContractService }
    static status = ContractStatus
    static action = ContractActions

    static create ({ product, productFields, contractNumber, customerId, consultantId, startDate, endDate, premium, generalAgencyId, managedByStatus, consultingMandateEnquirySendDate }) {
        const productFieldValues = createProductFieldIdValuePairs(product.template, productFields)
        const variables = {
            contract: {
                contract_number: contractNumber,
                product_id: product.id,
                product_template_version: product.template.version,
                product_field_values: JSON.stringify(productFieldValues),
                customer_id: customerId,
                consultant_id: consultantId,
                start_date: (startDate === '') ? null : startDate,
                end_date: (endDate === '') ? null : endDate,
                premium: (premium === '') ? null : parseFloat(premium),
                general_agency_id: generalAgencyId ?? null,
                managed_by_status: managedByStatus,
                consulting_mandate_enquiry_send_date: (consultingMandateEnquirySendDate === '') ? null : consultingMandateEnquirySendDate,
            },
        }
        return ContractService.create(variables)
    }

    update ({ productFields, consultantId, startDate, endDate, premium, generalAgencyId, managedByStatus, consultingMandateEnquirySendDate, commissionRecipientOverrideId }) {
        const productFieldValues = createProductFieldIdValuePairs(this.currentContractInformation.productTemplate, productFields)
        const variables = {
            id: this.id,
            contract: {
                product_field_values: JSON.stringify(productFieldValues),
                consultant_id: consultantId,
                start_date: !startDate ? null : startDate,
                end_date: !endDate ? null : endDate,
                premium: !premium ? null : parseFloat(premium),
                general_agency_id: typeof generalAgencyId !== 'undefined' ? generalAgencyId : this.currentContractInformation?.generalAgency?.id,
                managed_by_status: managedByStatus,
                consulting_mandate_enquiry_send_date: (consultingMandateEnquirySendDate === '') ? null : consultingMandateEnquirySendDate,
            },
        }

        if (store.state.user.aclFeatures.includes('Feature:commissions:core') &&  store.state.user.aclPermissions.includes('CommissionSplit:manage')) {
            variables.contract.commission_recipient_override_id = typeof commissionRecipientOverrideId !== 'undefined' ? commissionRecipientOverrideId : this.commissionRecipientOverride?.id
        }

        return ContractService.update(variables).then(contract => {
            Object.assign(this, contract)
            return contract
        })
    }

    terminate (terminationDate) {
        const variables = {
            id: this.id,
            status: ContractStatus.TERMINATED,
            terminationDate: terminationDate,
        }
        return ContractService.changeStatus(variables).then(contract => {
            Object.assign(this, contract)
            return contract
        })
    }

    activate () {
        const variables = {
            id: this.id,
            status: ContractStatus.ACTIVE,
        }
        return ContractService.changeStatus(variables).then(contract => {
            Object.assign(this, contract)
            return contract
        })
    }

    archive () {
        const variables = {
            id: this.id,
            status: ContractStatus.ARCHIVED,
        }
        return ContractService.changeStatus(variables).then(contract => {
            Object.assign(this, contract)
            return contract
        })
    }

    unarchive () { // TODO: Implement handling for special case if consultant was archived
        const variables = {
            id: this.id,
            status: ContractStatus.TERMINATED,
            terminationDate: this.termination_date,
        }
        return ContractService.changeStatus(variables).then(contract => {
            Object.assign(this, contract)
            return contract
        })
    }

    delete () {
        return ContractService.delete(this.id)
    }

    get actions () {
        const actions = []

        if (store.state.user.aclPermissions.includes('Application:manage')) {
            actions.push({
                key: ContractActions.CREATE_CHANGE_APPLICATION,
                item: this,
            })
        }

        if (this.currentCancellation) {
            actions.push(...this.currentCancellation.actions)
        } else if (![ContractStatus.ARCHIVED, ContractStatus.TERMINATED].includes(this.status)) {
            actions.push({
                key: ContractActions.CANCEL,
                item: this,
            })
        }

        actions.push(...super.actions)

        if (this.status === ContractStatus.ARCHIVED) {
            actions.push({
                key: ContractActions.UNARCHIVE,
                item: this,
            })
        }

        actions.push({
            key: ContractActions.DELETE,
            item: this,
        })

        return actions
    }

    get currentContractNumber () {
        return this.contractNumbers[0].number
    }

    get contractNumberHistory () {
        if (this.contractNumbers.length > 1) {
            const contractNumberHistory = Array.from(this.contractNumbers)
            contractNumberHistory.splice(0, 1)
            return contractNumberHistory
        } else {
            return undefined
        }
    }

    /**
     * Get the current cancellation, if there is one that is pending or approved with a cancellation date in the future.
     * @returns {Cancellation|null}
     */
    get currentCancellation () {
        if (this.cancellations.length && [CancellationStatus.PENDING, CancellationStatus.APPROVED].includes(this.cancellations[0].status)) {
            return (this.cancellations[0].status === CancellationStatus.APPROVED && this.cancellations[0].hasPastCancellationDate) ? null : this.cancellations[0]
        } else {
            return null
        }
    }

    /**
     * Get the currently pending cancellation, if any exists.
     * @returns {Cancellation|null}
     */
    get pendingCancellation () {
        return (this.currentCancellation && this.currentCancellation.status === CancellationStatus.PENDING) ? this.currentCancellation : null
    }

    /**
     * Get all cancellations that were withdrawn, declined or approved with a cancellation date in the past.
     * That are in fact all cancellations except the current one, if there is one.
     * @returns {Cancellation[]}
     */
    get cancellationHistory () {
        if (this.currentCancellation) {
            const cancellationHistory = Array.from(this.cancellations)
            cancellationHistory.splice(0, 1)
            return cancellationHistory
        } else {
            return this.cancellations
        }
    }

    get formattedTerminationDate () {
        return formatDate(this.termination_date)
    }

    get targetObjectType () {
        return getTargetObjectType(this)
    }

    get hasActiveCommissionSplits () {
        return !!this.commissionSplits?.some(commissionSplit => commissionSplit.is_active)
    }

    // TODO: Add `userHasAccessToActiveCommissionSplits` once `this.has_active_commission_splits` has been implemented
    // get userHasAccessToActiveCommissionSplits () {
    // }

    get hasCommissionRecipientOverrides () {
        // TODO improvement: Refactor template code to directly use `this.has_commission_recipient_override` instead.
        return this.has_commission_recipient_override
    }

    /**
     * Checks if access to commission recipient details is restricted for the current user.
     *
     * This method evaluates whether the access to the commission recipient details is restricted
     * based on the presence of a commission recipient override and its details.
     *
     * @return {boolean} Returns true if commission recipient override exists but is not accessible to current user, otherwise false.
     */
    get accessToCommissionRecipientDetailsRestricted () {
        return this.has_commission_recipient_override && !this.commissionRecipientOverride
    }

    updateContractNumber (contractNumber) {
        return ContractService.updateContractNumber(this.id, contractNumber).then(contract => {
            this.contractNumbers = contract.contractNumbers
            return contract
        })
    }

    deleteContractNumber (contractNumberId) {
        return ContractService.deleteContractNumber(contractNumberId).then(response => {
            const index = this.contractNumbers.findIndex(item => item.id === contractNumberId)
            this.contractNumbers.splice(index, 1)
            return response
        })
    }

    updateToProductTemplateVersion (productTemplateVersion) {
        return ContractService.updateToProductTemplateVersion(this.id, productTemplateVersion)
    }

    contractCommissionPostings(variables) {
        return ContractService.contractCommissionPostings(variables)
    }
}

export class ContractInformation extends Model {
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'product',
            'productTemplate',
            'product_field_values',
            'start_date',
            'end_date',
            'premium',
            'generalAgency',
            'managed_by_status',
            'consulting_mandate_enquiry_send_date',
        ], data)
    }

    get productFields () {
        const productFields = {}

        walkJSON(this.productTemplate.form_configuration, (value, key, node) => {
            if (key === 'type' && value === 'field') {
                let displayValue = null
                let rawValue = null
                const field = this.product_field_values.find(fieldValue => fieldValue.field_id === node.id)
                if (field) {
                    if (node.input_type === 'select') {
                        const displayValueItem = node.extra_attributes.list.options.find(option => option.value === field.value)
                        if (displayValueItem) displayValue = displayValueItem.label
                    } else {
                        displayValue = field.value
                    }
                    rawValue = field.value
                }
                const productFieldAttributes = {
                    label: node.label,
                    value: displayValue,
                    rawValue,
                }
                if (node.input_type === 'currency' && displayValue) productFieldAttributes.formattedValue = formatCurrency(parseFloat(displayValue))
                if (node.input_type === 'date' && displayValue) productFieldAttributes.formattedValue = formatDate(displayValue)
                if (node.input_type === 'monthDay' && displayValue) {
                    const value = monthDayToIsoDate(displayValue)
                    if (isValidIsoDate(value)) productFieldAttributes.formattedValue = formatMonthDay(value)
                }
                productFields[node.key] = productFieldAttributes
            }
        })

        return productFields
    }

    get nestedProductFields () {
        const productFields = this.productFields
        const nestedproductFields = JSON.parse(JSON.stringify(this.productTemplate.form_configuration))

        walkJSON(nestedproductFields, (value, key, node, parent) => {
            if (key === 'type' && value === 'section') node.key = window.crypto.getRandomValues(new Uint32Array(1))[0]
            if (key === 'type' && value === 'field') {
                const nestedProductFieldAttributes = {
                    type: value,
                    key: node.key,
                    label: node.label,
                    value: productFields[node.key].value,
                }
                if (productFields[node.key].formattedValue) nestedProductFieldAttributes.formattedValue = productFields[node.key].formattedValue
                parent.node[Array.isArray(parent.node) ? parent.index : parent.key] = nestedProductFieldAttributes
            }
        })

        return nestedproductFields
    }

    get formattedStartDate () {
        return formatDate(this.start_date)
    }

    get formattedEndDate () {
        return formatDate(this.end_date)
    }

    get formattedPremium () {
        return formatCurrency(this.premium)
    }

    get formattedConsultingMandateEnquirySendDate () {
        return formatDate(this.consulting_mandate_enquiry_send_date)
    }
}
