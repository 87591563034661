<template>
    <page-loading-indicator
        v-if="!state.ecoHubTechUser"
        :number-of-breadcrumb-elements="3"
        actions
        :number-of-next-actions="1"
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$t('common.eco-hub.eco-hub')" :to="{ name: 'admin-eco-hub' }" data-test="breadcrumb:admin-eco-hub" />
                <q-breadcrumbs-el :label="$tc('views.admin.eco-hub.tech-user.tech-user', 1)" :to="{ name: 'eco-hub-tech-user-detail' }" data-test="breadcrumb:eco-hub-tech-user-detail" />
                <q-breadcrumbs-el :label="$tc('views.admin.eco-hub.tech-user.re-enroll-tech-user', 1)" :to="{ name: 'eco-hub-tech-user-update' }" data-test="breadcrumb:eco-hub-tech-user-update" />
            </template>

            <h1>{{ $tc('views.admin.eco-hub.tech-user.tech-user', 1) }}</h1>
        </page-header>

        <div class="row print-row-lg">
            <grid-card
                :columns="{
                    'xs': 12,
                    'sm': 12,
                    'md': 8,
                    'lg': 6,
                    'xl': 6,
                }"
                :heading="$tc('views.admin.eco-hub.tech-user.re-enroll-tech-user', 1)"
                :heading-info="$t('views.admin.eco-hub.tech-user.tech-user--info--short')"
                container-type-normal
            >
                <eco-hub-tech-user-form
                    ref="ecoHubTechUserForm"
                    :eco-hub-tech-user="state.ecoHubTechUser"
                    @cancel="onCancel"
                    @submit="onSubmit" />
            </grid-card>
        </div>
    </page-wrapper>
</template>

<script>
import { EcoHubTechUser } from '@/models/ecoHubTechUser'
import EcoHubTechUserForm from '@/components/ecoHub/EcoHubTechUserForm.vue'
import { useRouter } from '@/composables/router'
import { onBeforeMount, reactive, ref } from 'vue'
import { useQuasar } from '@/composables/quasar'
import { useI18n } from '@/composables/i18n'

export default {
    name: 'EcoHubTechUserUpdate',
    meta () {
        return {
            title: this.$tc('views.admin.eco-hub.tech-user.re-enroll-tech-user', 1),
        }
    },
    components: {
        EcoHubTechUserForm,
    },
    setup () {
        // Composables
        const $q = useQuasar()
        const router = useRouter()
        const { t, tc } = useI18n()

        // Data
        const state = reactive({
            ecoHubTechUser: null,
        })

        // Template refs
        const ecoHubTechUserForm = ref(null)

        // Functions
        function fetchObject () {
            EcoHubTechUser.service.get().then(ecoHubTechUser => {
                state.ecoHubTechUser = ecoHubTechUser
            }).catch(() => {
                router.replace({ name: 'eco-hub-tech-user-detail' })
            })
        }
        function onCancel () {
            router.push({ name: 'eco-hub-tech-user-detail' })
        }
        function onSubmit (formData) {
            state.ecoHubTechUser.update(formData)
                .then(() => {
                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.eco-hub.tech-user.tech-user-re-enrolled-success', 1),
                    })
                    router.replace({ name: 'eco-hub-tech-user-detail' })
                })
                .catch(error => {
                    ecoHubTechUserForm.value.formComponent.formBuilder.showFormErrorMessage(error)
                    ecoHubTechUserForm.value.formComponent.formBuilder.resetFormSubmitStatus()
                })
        }

        // Lifecycle hooks
        onBeforeMount(() => {
            fetchObject()
        })

        return {
            // Data
            state,

            // Template refs
            ecoHubTechUserForm,

            // Functions
            onCancel,
            onSubmit,
        }
    },
}
</script>
