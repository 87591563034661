import gql from 'graphql-tag'

export const DXP_INBOX_ITEMS_CREATE_MUTATION = gql`
    mutation dxpInboxItemsCreate(
        $serviceProviderId: ID!
        $fileInfos: [DxpFileInfoInput!]!
    ) {
        dxpInboxItemsCreate(
            service_provider_id: $serviceProviderId
            fileInfos: $fileInfos
        ) {
            id
        }
    }
`
