<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$t('common.eco-hub.eco-hub')" :to="{ name: 'admin-eco-hub' }" data-test="breadcrumb:admin-eco-hub" />
                <q-breadcrumbs-el :label="$tc('common.data-exchange.service-provider', 2)" :to="{ name: 'eco-hub-dxp-service-provider-configuration' }" data-test="breadcrumb:eco-hub-dxp-service-provider-configuration" />
            </template>

            <h1>{{ $tc('common.data-exchange.service-provider', 2) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-12">
                <base-table
                    :columns="columns"
                    :fetch-objects-fn="DxpServiceProviderConfiguration.objects.all"
                    user-settings-base-path="DxpServiceProviderConfigurationList"
                    pagination-sort-by-default-key="service_provider_id"
                    :show-filter="false"
                    @row-dblclick="(evt, row) => $router.push({ name: 'eco-hub-dxp-service-provider-configuration-update', params: { id: row.service_provider_id } })"
                >
                    <!-- BILLING -->
                    <template v-slot:body-cell-is_enabled_billing="slotProps">
                        <q-td :props="slotProps">
                            <span>
                                <q-icon v-if="slotProps.row.isEnabled({ id: DxpDocumentTypeIdentifier.BILLING })" name="mib-check-1" color="positive" />
                                <span v-else class="additional-info">–</span>
                                <q-tooltip
                                    anchor="top middle"
                                    self="center middle"
                                    :delay="500"
                                    max-width="25rem"
                                >
                                    <i18n :path="`views.data-exchange.service-provider.data-exchange-process-integration--activated--${slotProps.row.isEnabled({ id: DxpDocumentTypeIdentifier.BILLING })}`">
                                        <template v-slot:process><b>{{ $t(`common.data-exchange.process.${kebabCase(DxpDocumentTypeIdentifier.BILLING)}`) }}</b></template>
                                    </i18n>
                                </q-tooltip>
                            </span>
                        </q-td>
                    </template>
                    <template v-slot:body-cell-is_enabled_for_automatic_processing_billing="slotProps">
                        <q-td :props="slotProps">
                            <span>
                                <q-icon v-if="slotProps.row.isEnabledForAutomaticProcessing({ id: DxpDocumentTypeIdentifier.BILLING })" name="mib-check-1" color="positive" />
                                <span v-else class="additional-info">–</span>
                                <q-tooltip
                                    anchor="top middle"
                                    self="center middle"
                                    :delay="500"
                                    max-width="25rem"
                                >
                                    <i18n :path="`views.data-exchange.service-provider.data-exchange-process-integration--automatic-processing--${slotProps.row.isEnabledForAutomaticProcessing({ id: DxpDocumentTypeIdentifier.BILLING })}`">
                                        <template v-slot:process><b>{{ $t(`common.data-exchange.process.${kebabCase(DxpDocumentTypeIdentifier.BILLING)}`) }}</b></template>
                                    </i18n>
                                </q-tooltip>
                            </span>
                        </q-td>
                    </template>

                    <!-- REMINDER -->
                    <template v-slot:body-cell-is_enabled_reminder="slotProps">
                        <q-td :props="slotProps">
                            <span>
                                <q-icon v-if="slotProps.row.isEnabled({ id: DxpDocumentTypeIdentifier.REMINDER })" name="mib-check-1" color="positive" />
                                <span v-else class="additional-info">–</span>
                                <q-tooltip
                                    anchor="top middle"
                                    self="center middle"
                                    :delay="500"
                                    max-width="25rem"
                                >
                                    <i18n :path="`views.data-exchange.service-provider.data-exchange-process-integration--activated--${slotProps.row.isEnabled({ id: DxpDocumentTypeIdentifier.REMINDER })}`">
                                        <template v-slot:process><b>{{ $t(`common.data-exchange.process.${kebabCase(DxpDocumentTypeIdentifier.REMINDER)}`) }}</b></template>
                                    </i18n>
                                </q-tooltip>
                            </span>
                        </q-td>
                    </template>
                    <template v-slot:body-cell-is_enabled_for_automatic_processing_reminder="slotProps">
                        <q-td :props="slotProps">
                            <span>
                                <q-icon v-if="slotProps.row.isEnabledForAutomaticProcessing({ id: DxpDocumentTypeIdentifier.REMINDER })" name="mib-check-1" color="positive" />
                                <span v-else class="additional-info">–</span>
                                <q-tooltip
                                    anchor="top middle"
                                    self="center middle"
                                    :delay="500"
                                    max-width="25rem"
                                >
                                    <i18n :path="`views.data-exchange.service-provider.data-exchange-process-integration--automatic-processing--${slotProps.row.isEnabledForAutomaticProcessing({ id: DxpDocumentTypeIdentifier.REMINDER })}`">
                                        <template v-slot:process><b>{{ $t(`common.data-exchange.process.${kebabCase(DxpDocumentTypeIdentifier.REMINDER)}`) }}</b></template>
                                    </i18n>
                                </q-tooltip>
                            </span>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-actions="slotProps">
                        <q-td :props="slotProps" auto-width>
                            <q-btn
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-pencil"
                                :to="{ name: 'eco-hub-dxp-service-provider-configuration-update', params: { id: slotProps.row.service_provider_id } }"
                            >
                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.term.show-details', 1) }}</q-tooltip>
                            </q-btn>
                        </q-td>
                    </template>
                </base-table>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import BaseTable from '@/components/BaseTable'
import { useI18n } from '@/composables/i18n'
import { DxpDocumentTypeIdentifier } from '@/enums/graphql'
import { DxpServiceProviderConfiguration } from '@/models/models'
import { kebabCase } from 'lodash'

export default {
    name: 'DxpServiceProviderConfigurationList',
    components: {
        BaseTable,
    },
    meta () {
        return {
            title: this.$tc('common.data-exchange.service-provider', 2),
        }
    },
    setup (props) {
        const { tc } = useI18n()

        const columns = [
            {
                name: 'service_provider_id',
                label: tc('common.term.name'),
                field: row => row.service_provider_display_name,
                sortable: true,
                align: 'left',
            },
            {
                name: 'is_enabled_billing',
                label: tc(`common.data-exchange.process--entity-name.${kebabCase(DxpDocumentTypeIdentifier.BILLING)}`, 2),
                align: 'left',
            },
            {
                name: 'is_enabled_for_automatic_processing_billing',
                label: `${tc(`common.data-exchange.process--entity-name.${kebabCase(DxpDocumentTypeIdentifier.BILLING)}`, 2)} (${tc('views.data-exchange.service-provider.column-title--automatic-processing--short', 1)})`,
                align: 'left',
            },
            {
                name: 'is_enabled_reminder',
                label: tc(`common.data-exchange.process--entity-name.${kebabCase(DxpDocumentTypeIdentifier.REMINDER)}`, 2),
                align: 'left',
            },
            {
                name: 'is_enabled_for_automatic_processing_reminder',
                label: `${tc(`common.data-exchange.process--entity-name.${kebabCase(DxpDocumentTypeIdentifier.REMINDER)}`, 2)} (${tc('views.data-exchange.service-provider.column-title--automatic-processing--short', 1)})`,
                align: 'left',
            },
            { name: 'actions', label: tc('common.term.action', 2), align: 'left' },
        ]

        return {
            kebabCase,
            DxpDocumentTypeIdentifier,
            DxpServiceProviderConfiguration,
            columns,
        }
    },
}
</script>
