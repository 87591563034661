import { ensureLocaleConformity, i18n } from '@/i18n'
import { sha256 } from '@/helpers/index'
import store from '@/store/store'
import { ConsultingFileService, ContactService, IGB2BInsurerCodeService } from '@/services'

/**
 * Get the name of a given user.
 * @param {Object} user - The user whose name should be returned.
 * @param {Boolean} [salutation=false] - Option to include the salutation of the linked person (Mr./Mrs./…) in the returned string.
 * @param {Boolean} [noFirstName=false] - Option to exclude the linked person’s first name from the returned string.
 * @param {Boolean} [noLastName=false] - Option to exclude the linked person’s last name from the returned string.
 * @param {Boolean} [email=false] - Option to include the user’s email address in the returned string. (Only applies if the user has a linked person.)
 * @returns {string} Returns the name of the linked person if available or the email address of the user otherwise.
 */
export function getUserName (
    user,
    {
        salutation = false,
        noFirstName = false,
        noLastName = false,
        email = false,
    } = {
        salutation: false,
        noFirstName: false,
        noLastName: false,
        email: false,
    }
) {
    const userName = []

    if (user?.consultant) {
        if (salutation) {
            userName.push(i18n.t(`common.contact.salutation-${user.consultant.gender.toLowerCase()}`))
        }
        if (!noFirstName) {
            userName.push(user.consultant.first_name)
        }
        if (!noLastName) {
            userName.push(user.consultant.last_name)
        }
        if (email) {
            userName.push(`(${user.email})`)
        }
        return userName.join(' ')
    } else {
        return user.email
    }
}

export class UserSettings {
    constructor (user) {
        this.user = user
    }

    setItem (key, value) {
        localStorage.setItem(`${this.user.hashedUserId}:${key}`, value)
    }

    getItem (key) {
        return localStorage.getItem(`${this.user.hashedUserId}:${key}`)
    }

    removeItem (key) {
        localStorage.removeItem(`${this.user.hashedUserId}:${key}`)
    }
}

export async function saveUserToStoreAndLoadInitialAppData (user) {
    ensureLocaleConformity(user.tenantSettings.systemLanguages, user.tenantSettings.defaultSystemLanguage)
    user.hashedUserId = await sha256(user.id)
    store.dispatch('setUser', user)

    // TODO refactoring @TFU: Combine into one query.
    /**
     * Load general data like categories, labels and countries and commit to store, so they can be used everywhere in the app.
     * All of this data is seeded and cannot be changed within the app, therefore loading it here
     * and only once does not generate any potential problems with outdated data.
     */
    const promises = []

    promises.push(ContactService.getAddressLabels().then(addressLabels => {
        const composedAddressLabels = {}
        addressLabels.forEach(addressLabel => {
            composedAddressLabels[addressLabel.id] = addressLabel.label
        })
        store.dispatch('setAddressLabels', composedAddressLabels)
    }))

    promises.push(ContactService.getEmailAddressLabels().then(emailAddressLabels => {
        const composedEmailAddressLabels = {}
        emailAddressLabels.forEach(emailAddressLabel => {
            composedEmailAddressLabels[emailAddressLabel.id] = emailAddressLabel.label
        })
        store.dispatch('setEmailAddressLabels', composedEmailAddressLabels)
    }))

    promises.push(ContactService.getPhoneNumberLabels().then(phoneNumberLabels => {
        const composedPhoneNumberLabels = {}
        phoneNumberLabels.forEach(phoneNumberLabel => {
            composedPhoneNumberLabels[phoneNumberLabel.id] = phoneNumberLabel.label
        })
        store.dispatch('setPhoneNumberLabels', composedPhoneNumberLabels)
    }))

    promises.push(ContactService.getCountries().then(response => {
        const composedCountryLabels = {}
        response.data.forEach(country => {
            composedCountryLabels[country.id] = country.name
        })
        store.dispatch('setCountryLabels', composedCountryLabels)
    }))

    promises.push(ConsultingFileService.getContractFileTypes().then(contractFileTypes => {
        const composedContractFileTypes = {}
        contractFileTypes.forEach(contractFileType => {
            composedContractFileTypes[contractFileType.key] = contractFileType
        })
        store.dispatch('setContractFileTypes', composedContractFileTypes)
    }))

    // This block was implemented by mistake, and we currently don't need to load this globally.
    // Still here because we might need it later and instead of re-implementing we can just un-comment.
    // promises.push(IGB2BSectorCodeService.all().then(response => {
    //     const composedIgb2bSectorCodes = {}
    //     response.data.forEach(igb2bSectorCode => {
    //         composedIgb2bSectorCodes[igb2bSectorCode.id] = igb2bSectorCode.name
    //     })
    //     store.dispatch('setIgb2bSectorCodes', composedIgb2bSectorCodes)
    // }))

    promises.push(IGB2BInsurerCodeService.all().then(response => {
        store.dispatch('setIgb2bInsurerCodes', response.data)
    }))

    return Promise.allSettled(promises)
}
