import { DxpContractMatchNextStep, DxpContractMatchStatus } from '@/enums'
import { DxpCustomerNameMatch } from '@/enums/graphql'
import { getNumberOfMatchesOfType } from '@/helpers/dxp'

export function DxpContractMatchMixin(Superclass) {
    return class extends Superclass {
        get nextStep () {
            if (this.matched_contract_confirmed) return null
            if (this.matchedContract) return DxpContractMatchNextStep.CONFIRM_SELECTED_CONTRACT
            if (this.contractMatchShortlist?.length) return DxpContractMatchNextStep.SELECT_CONTRACT_FROM_SHORTLIST
            return DxpContractMatchNextStep.SELECT_CONTRACT
        }

        get contractMatchStatus () {
            // One contract matched.
            if (this.contractMatchShortlist?.length === 1) {
                switch (this.contractMatchShortlist.at(0).customer_name_match) {
                    case DxpCustomerNameMatch.FULL:
                        return DxpContractMatchStatus.CONTRACT_MATCH__NAME_MATCH_FULL
                    case DxpCustomerNameMatch.PARTIAL:
                        return DxpContractMatchStatus.CONTRACT_MATCH__NAME_MATCH_PARTIAL
                    case DxpCustomerNameMatch.NONE:
                        return DxpContractMatchStatus.CONTRACT_MATCH__NAME_MATCH_NONE
                }
            } else if (this.contractMatchShortlist?.length > 1) { // Multiple contracts matched.
                if (getNumberOfMatchesOfType(this.contractMatchShortlist, DxpCustomerNameMatch.FULL) === 1) return DxpContractMatchStatus.CONTRACT_SHORTLIST__NAME_MATCH_FULL
                if (getNumberOfMatchesOfType(this.contractMatchShortlist, DxpCustomerNameMatch.PARTIAL) === 1) return DxpContractMatchStatus.CONTRACT_SHORTLIST__NAME_MATCH_PARTIAL
            }

            return DxpContractMatchStatus.NO_MATCH
        }
    }
}
