import { i18n } from '@/i18n'
import { DxpContractMatchMixin } from '@/models/mixins/dxpContractMatchMixin'
import { computed } from 'vue'
import { Model } from '@/models'
import { formatDate } from '@/helpers/date'
import { PremiumInvoiceService } from '@/services'
import { PremiumInvoicePositionType } from '@/enums/graphql'
import { PremiumInvoiceActions } from '@/enums'

export class PremiumInvoice extends DxpContractMatchMixin(Model) {
    /**
     * Create a PremiumInvoice model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'file_import_identification_number',
            'contract_number',
            'replaced_contract_number',
            'framework_agreement_number',
            'invoice_number',
            'invoice_date',
            'document_recipient',
            'customer_first_name',
            'customer_last_name',
            'customer_company_name',
            'remarks',
            'files',
            'positions',
            'contractMatchShortlist',
            'matchedContract',
            'matched_contract_confirmed',
            'created_manually',
        ], data)

        // Computed
        this.totalPosition = computed(() => {
            return this.positions?.filter(position => position.type === PremiumInvoicePositionType.TOTAL).at(0) ?? null
        })

        this.detailPositions = computed(() => {
            return this.positions?.filter(position => position.type === PremiumInvoicePositionType.DETAIL) ?? null
        })

        this.formattedInvoiceDate = computed( () => {
            return formatDate(this.invoice_date)
        })

        this.formattedTotalAmount = computed( () => {
            return this.totalPosition.value?.formattedInvoicedAmount ?? ''
        })

        this.hasIdentifier = computed( () => {
            return !!(this.invoice_number || this.invoice_date)
        })

        this.formattedIdentifier = computed( () => {
            if (this.hasIdentifier.value) {
                return this.invoice_number || `${i18n.tc('common.premium-invoice.invoice-from', 1)} ${this.formattedInvoiceDate.value}`
            } else if (this.totalPosition.value) {
                return `${i18n.tc('common.premium-invoice.billing-period', 1)} ${this.totalPosition.value.formattedBillingPeriod.value}`
            }
            return ''
        })
    }

    static get service () { return PremiumInvoiceService }
    static action = PremiumInvoiceActions

    /**
     * Add custom actions.
     * @returns {Array}
     */
    get actions () {
        const actions = super.actions
        actions.push(
            {
                key: PremiumInvoiceActions.UPDATE,
                item: this,
            },
            {
                key: PremiumInvoiceActions.DELETE,
                item: this,
            }
        )
        return actions
    }

    /**
     * Create a new premiumInvoice.
     * @param {Object} variables - The values used to create the premiumInvoice.
     * @returns {Promise<*>}
     */
    static create ({
        matchedContractId,
        invoiceNumber,
        invoiceDate,
        documentRecipient,
        files,
        contractFileIds,
        positions,
    }) {
        const variables = {
            matchedContractId,
            premiumInvoice: {
                invoice_number: invoiceNumber,
                invoice_date: invoiceDate || null,
                document_recipient: documentRecipient,
                files,
                contract_file_ids: contractFileIds,
                positions: positions.map((premiumInvoicePosition, index) => {
                    return {
                        type: index === 0 ? PremiumInvoicePositionType.TOTAL : PremiumInvoicePositionType.DETAIL,
                        premium_from: premiumInvoicePosition.premiumFrom,
                        premium_to: premiumInvoicePosition.premiumTo,
                        premium_kind: premiumInvoicePosition.premiumKind,
                        premium_type: premiumInvoicePosition.premiumType,
                        sector_code_id: premiumInvoicePosition.sectorCodeId,
                        annual_premium_amount: parseFloat(premiumInvoicePosition.annualPremiumAmount),
                        instalment_surcharge: parseFloat(premiumInvoicePosition.instalmentSurcharge),
                        taxes_statutory_charges: parseFloat(premiumInvoicePosition.taxesStatutoryCharges),
                        invoiced_amount: parseFloat(premiumInvoicePosition.invoicedAmount),
                    }
                }),
            },
        }
        return PremiumInvoiceService.create(variables)
    }

    /**
     * Update this premiumInvoice.
     * @param {Object} variables - The values used to update the premiumInvoice.
     * @returns {Promise<*>}
     */
    update ({
        invoiceNumber,
        invoiceDate,
        documentRecipient,
        files,
        deleteFileIds,
        contractFileIds,
        positions,
    }) {
        const variables = {
            invoice_number: invoiceNumber,
            invoice_date: invoiceDate || null,
            document_recipient: documentRecipient,
            files,
            delete_file_ids: deleteFileIds,
            contract_file_ids: contractFileIds,
            positions: positions.map((premiumInvoicePosition, index) => {
                const position = {
                    type: index === 0 ? PremiumInvoicePositionType.TOTAL : PremiumInvoicePositionType.DETAIL,
                    premium_from: premiumInvoicePosition.premiumFrom,
                    premium_to: premiumInvoicePosition.premiumTo,
                    premium_kind: premiumInvoicePosition.premiumKind,
                    premium_type: premiumInvoicePosition.premiumType,
                    sector_code_id: premiumInvoicePosition.sectorCodeId,
                    annual_premium_amount: parseFloat(premiumInvoicePosition.annualPremiumAmount),
                    instalment_surcharge: parseFloat(premiumInvoicePosition.instalmentSurcharge),
                    taxes_statutory_charges: parseFloat(premiumInvoicePosition.taxesStatutoryCharges),
                    invoiced_amount: parseFloat(premiumInvoicePosition.invoicedAmount),
                }
                if (premiumInvoicePosition.id) position.id = premiumInvoicePosition.id
                return position
            }),
        }

        return PremiumInvoiceService.update(this.id, variables).then(premiumInvoice => {
            Object.assign(this, premiumInvoice)
            return premiumInvoice
        })
    }

    /**
     * Delete this premiumInvoice.
     * @returns {Promise<*>}
     */
    delete () {
        return PremiumInvoiceService.delete(this.id)
    }
}
