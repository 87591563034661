import { apolloClient } from '@/apollo/client'

import { PREMIUM_REMINDER_QUERY } from '@/graphql/premiumReminder/premiumReminder'
import { PREMIUM_REMINDERS_QUERY } from '@/graphql/premiumReminder/premiumReminders'
import { PREMIUM_REMINDER_DELETE_MUTATION } from '@/graphql/premiumReminder/premiumReminderDelete'

export const PremiumReminderService = {
    get (id) {
        return apolloClient.query({
            query: PREMIUM_REMINDER_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.premiumReminder)
    },
    all (variables) {
        return apolloClient.query({
            query: PREMIUM_REMINDERS_QUERY,
            variables,
        }).then(response => response.data.premiumReminders)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: PREMIUM_REMINDER_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.premiumReminderDelete)
    },
}
