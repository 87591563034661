<template>
    <div class="configuration-wrapper">
        <!-- Select target object type -->
        <form
            v-if="currentStep === 'configuration'"
            class="row q-col-gutter-md"
            @submit.prevent="onConfigurationSubmit"
        >
            <div class="col-xs-12 col-md-6 col-lg-4 offset-lg-1 text-center q-mr-md-xl">
                <h3>
                    {{ $tc('common.correspondence.target-object.target-object-type', 1) }}
                    <info-icon :text="$tc('common.correspondence.target-object.target-object--description', 1)" />
                </h3>

                <!-- TODO improvement @MTR: Create `illustration-toggle` component for this -->
                <q-btn-toggle
                    v-model="formData.configuration.correspondenceItemTargetObjectType"
                    :options="[
                        {
                            value: CorrespondenceItemTargetObjectType.CONTACT,
                            slot: 'contact'
                        },
                        {
                            value: CorrespondenceItemTargetObjectType.APPLICATION,
                            slot: 'application'
                        },
                        {
                            value: CorrespondenceItemTargetObjectType.CONTRACT,
                            slot: 'contract'
                        },
                    ]"
                    unelevated
                    no-caps
                    flat
                    :ripple="false"
                    spread
                >
                    <template v-slot:contact>
                        <div class="items-center text-center no-wrap">
                            <illustration
                                name="max-person-check"
                                width="10vw"
                                min-width="8rem"
                                max-width="11rem"
                                faded
                                interactive
                                :selected="formData.configuration.correspondenceItemTargetObjectType === CorrespondenceItemTargetObjectType.CONTACT"
                            />
                            <div class="text-center">
                                <q-radio
                                    v-model="formData.configuration.correspondenceItemTargetObjectType"
                                    :val="CorrespondenceItemTargetObjectType.CONTACT"
                                    :label="$tc('common.term.contact', 1)"
                                />
                            </div>
                        </div>
                    </template>

                    <template v-slot:application>
                        <div class="items-center text-center no-wrap">
                            <illustration
                                name="max-file-content-signature-check"
                                width="10vw"
                                min-width="8rem"
                                max-width="11rem"
                                faded
                                interactive
                                :selected="formData.configuration.correspondenceItemTargetObjectType === CorrespondenceItemTargetObjectType.APPLICATION"
                            />
                            <div class="text-center">
                                <q-radio
                                    v-model="formData.configuration.correspondenceItemTargetObjectType"
                                    :val="CorrespondenceItemTargetObjectType.APPLICATION"
                                    :label="$tc('common.term.application', 1)"
                                />
                            </div>
                        </div>
                    </template>

                    <template v-slot:contract>
                        <div class="items-center text-center no-wrap">
                            <illustration
                                name="max-file-content-check"
                                width="10vw"
                                min-width="8rem"
                                max-width="11rem"
                                faded
                                interactive
                                :selected="formData.configuration.correspondenceItemTargetObjectType === CorrespondenceItemTargetObjectType.CONTRACT"
                            />
                            <div class="text-center">
                                <q-radio
                                    v-model="formData.configuration.correspondenceItemTargetObjectType"
                                    :val="CorrespondenceItemTargetObjectType.CONTRACT"
                                    :label="$tc('common.term.contract', 1)"
                                />
                            </div>
                        </div>
                    </template>
                </q-btn-toggle>
            </div>

            <div class="col-xs-12 col-md-6 col-lg-4 offset-lg-2 text-center q-ml-md-xl">
                <h3>{{ $tc('common.correspondence.initial-data-source', 1) }}</h3>

                <!-- TODO improvement @MTR: Create `illustration-toggle` component for this -->
                <q-btn-toggle
                    v-model="formData.configuration.correspondenceItemInitialDataSource"
                    :options="[
                        { value: CorrespondenceItemInitialDataSource.TEMPLATE, slot: 'template'},
                        { value: CorrespondenceItemInitialDataSource.BLANK, slot: 'blank' }
                    ]"
                    unelevated
                    no-caps
                    flat
                    :ripple="false"
                    spread
                >
                    <template v-slot:template>
                        <div class="items-center text-center no-wrap">
                            <illustration
                                name="max-file-content-image-sync"
                                width="10vw"
                                min-width="8rem"
                                max-width="11rem"
                                faded
                                :selected="formData.configuration.correspondenceItemInitialDataSource === CorrespondenceItemInitialDataSource.TEMPLATE"
                            />
                            <div class="text-center">
                                <q-radio
                                    v-model="formData.configuration.correspondenceItemInitialDataSource"
                                    :val="CorrespondenceItemInitialDataSource.TEMPLATE"
                                    :label="$tc('common.term.template', 1)"
                                />
                            </div>
                        </div>
                    </template>

                    <template v-slot:blank>
                        <div class="items-center text-center no-wrap">
                            <illustration
                                name="max-file-add"
                                width="10vw"
                                min-width="8rem"
                                max-width="11rem"
                                faded
                                :selected="formData.configuration.correspondenceItemInitialDataSource === CorrespondenceItemInitialDataSource.BLANK"
                            />
                            <div class="text-center">
                                <q-radio
                                    v-model="formData.configuration.correspondenceItemInitialDataSource"
                                    :val="CorrespondenceItemInitialDataSource.BLANK"
                                    :label="$tc('common.document.blank-document', 1)"
                                />
                            </div>
                        </div>
                    </template>
                </q-btn-toggle>
            </div>

            <div class="col-xs-12 q-mt-lg text-center">
                <info-box type="secondary" auto-width centered>
                    <i18n :path="`views.correspondence.${correspondenceItem.type.toLowerCase()}.create.user-choose-action-sentence`" tag="p">
                        <template v-slot:targetObjectType>
                            <span :class="['gap-text', { 'is-empty': !formData.configuration.correspondenceItemTargetObjectType }]"><template v-if="formData.configuration.correspondenceItemTargetObjectType">{{ $t(`views.correspondence.${correspondenceItem.type.toLowerCase()}.create.user-choose-action-sentence--target-object-type--${formData.configuration.correspondenceItemTargetObjectType.toLowerCase()}`) }}</template></span>
                        </template>
                        <template v-slot:initialDataSource>
                            <span :class="['gap-text', { 'is-empty': !formData.configuration.correspondenceItemInitialDataSource }]"><template v-if="formData.configuration.correspondenceItemInitialDataSource">{{ $t(`views.correspondence.${correspondenceItem.type.toLowerCase()}.create.user-choose-action-sentence--initial-data-source--${formData.configuration.correspondenceItemInitialDataSource.toLowerCase()}`) }}</template></span>
                        </template>
                    </i18n>
                </info-box>

                <base-button
                    data-test="btn:submit"
                    type="submit"
                    :label="$t('common.term.continue')"
                    :disable="$v.formData.configuration.$invalid"
                    primary-button
                />
            </div>
        </form>

        <!-- Select document template -->
        <form
            v-if="currentStep === 'selectDocumentTemplate'"
            @submit.prevent="onSelectDocumentTemplateSubmit"
        >
            <base-table
                :columns="selectDocumentTemplateTableColumns"
                :fetch-objects-fn="DocumentTemplate.objects.all"
                :additional-filters="selectDocumentTemplateTableFilters"
                pagination-sort-by-default-key="name"
                selection="single"
                :selected.sync="selectedDocumentTemplate"
                @update:selected="onSelectDocumentTemplate"
            >
                <template v-slot:tabs>
                    <h3>{{ $tc('common.correspondence.document-template.choose-document-template', 1) }}</h3>
                </template>
            </base-table>

            <div class="row q-gutter-sm">
                <base-button
                    :label="$t('common.term.cancel')"
                    flat
                    primary-button
                    @click.prevent="cancelSelectDocumentTemplate"
                />
                <base-button
                    data-test="btn:submit"
                    type="submit"
                    :label="$t('common.term.continue')"
                    :disable="$v.formData.templateId.$invalid"
                    primary-button
                    class="column"
                />
            </div>
        </form>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { CorrespondenceItemInitialDataSource } from '@/enums'
import { CorrespondenceItemType, CorrespondenceItemTargetObjectType } from '@/enums/graphql'
import { DocumentTemplate } from '@/models/models'
import BaseTable from '@/components/BaseTable'

export default {
    name: 'CorrespondenceItemCreateConfigurator',
    components: {
        BaseTable,
    },
    props: {
        correspondenceItem: {
            type: Object,
            required: true,
        },
        correspondenceItemTargetObjectType: {
            type: String,
            default: null,
        },
        correspondenceItemInitialDataSource: {
            type: String,
            default: null,
        },
    },
    data () {
        const data = {
            CorrespondenceItemType,
            CorrespondenceItemTargetObjectType,
            CorrespondenceItemInitialDataSource,
            DocumentTemplate,
            currentStep: 'configuration',
            selectDocumentTemplateTableColumns: [
                {
                    name: 'name',
                    label: this.$tc('common.term.name'),
                    field: row => row.name,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'language_name',
                    label: this.$tc('common.term.language', 1),
                    field: row => row.language.name,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'owner_name',
                    label: this.$tc('common.term.owner', 1),
                    field: row => row.getOwnerName(),
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'updated_at',
                    label: this.$tc('common.term.updated--last-updated', 1),
                    field: row => row.formattedUpdatedAt,
                    sortable: true,
                    align: 'left',
                },
            ],
            selectedDocumentTemplate: [],
            formData: {
                configuration: {
                    correspondenceItemTargetObjectType: this.correspondenceItemTargetObjectType,
                    correspondenceItemInitialDataSource: this.correspondenceItemInitialDataSource,
                },
                templateId: null,
            },

        }

        return data
    },
    validations () {
        return {
            formData: {
                configuration: {
                    correspondenceItemTargetObjectType: {
                        required,
                    },
                    correspondenceItemInitialDataSource: {
                        required,
                    },
                },
                templateId: {
                    required,
                },
            },
        }
    },
    computed: {
        selectDocumentTemplateTableFilters () {
            const filters = {
                filterType: this.correspondenceItem.type,
            }

            if (this.formData.configuration.correspondenceItemTargetObjectType) filters.filterSupportedTargetObjectTypes = this.formData.configuration.correspondenceItemTargetObjectType

            return filters
        },
    },
    created () {
        if (this.correspondenceItemTargetObjectType && this.correspondenceItemInitialDataSource) this.onConfigurationSubmit() // Skip first step of this view, if both values are provided via props.
    },
    methods: {
        onConfigurationSubmit () {
            if (!this.$v.formData.configuration.$invalid) {
                if (this.formData.configuration.correspondenceItemInitialDataSource === CorrespondenceItemInitialDataSource.TEMPLATE) {
                    this.currentStep = 'selectDocumentTemplate'
                } else {
                    this.$emit('submit', { target_object_type: this.formData.configuration.correspondenceItemTargetObjectType })
                }
            }
        },
        onSelectDocumentTemplate (selected) {
            if (selected.length) {
                this.formData.templateId = selected[0].id
            } else {
                this.formData.templateId = null
            }
        },
        cancelSelectDocumentTemplate () {
            this.currentStep = 'configuration'
            this.formData.templateId = null
        },
        onSelectDocumentTemplateSubmit () {
            if (!this.$v.formData.templateId.$invalid) {
                DocumentTemplate.objects.get(this.formData.templateId).then(documentTemplate => {
                    this.$emit('submit', {
                        target_object_type: this.formData.configuration.correspondenceItemTargetObjectType,
                        name: documentTemplate.name,
                        language: documentTemplate.language,
                        confidentiality_notice: documentTemplate.confidentiality_notice,
                        differentiate_formality: documentTemplate.differentiate_formality,
                        contents: documentTemplate.contents.map(documentContent => {
                            delete documentContent.id
                            return documentContent
                        }),
                    })
                })
            }
        },
    },
}
</script>
