import gql from 'graphql-tag'

export function buildContactsQuery (fields) {
    return gql`
        query Contacts(
            $page: Int = 1
            $count: Int = 1000
            $orderField: ContactOrderField = CONTACT_NAME
            $orderDirection: OrderDirection = ASC
            $filterQuery: String
            $contact_type: ContactType
            $contact_status: ContactStatus = ACTIVE
            $consultingSettingsStatus: [ConsultingSettingsStatus]
            $hasConsultingSettings: Boolean
            $productProviderSettingsStatus: [ProductProviderSettingsStatus]
            $hasProductProviderSettings: Boolean
            $filterHasIgb2bInsurerCode: Boolean
        ) {
            contacts(
                page: $page
                count: $count
                order: [{ field: $orderField, direction: $orderDirection }]
                search: {
                    contact_search: $filterQuery
                    contact_type: $contact_type
                    contact_status: $contact_status
                    consulting_settings_status: $consultingSettingsStatus
                    has_consulting_settings: $hasConsultingSettings
                    product_provider_settings_status: $productProviderSettingsStatus
                    has_product_provider_settings: $hasProductProviderSettings
                    has_igb2b_insurer_code: $filterHasIgb2bInsurerCode
                }
            ) {
                paginatorInfo {
                    count
                    firstItem
                    lastItem
                    total
                    currentPage
                    lastPage
                    perPage
                    hasMorePages
                }
                data {
                    ${fields}
                }
            }
        }
    `
}

const defaultContactsQueryFields = `
    id
    status
    consultingSettings {
        status
        company {
            id
            company_name
        }
        registration_authority
        registration_number
        currentAccount {
            id
            name
            number
        }
        cancellationReserveAccount {
            id
            name
            number
        }
        statementRecipients {
            consultants {
                id
                first_name
                last_name
                gender
                consulting_company_name
            }
        }
    }
    productProviderSettings {
        status
        display_name
        igb2bInsurerCode {
            id
            name
        }
    }
    contactNumber {
        id
        number
    }
    mainAddress {
        address1
        zip
        city
    }
    emailAddresses {
        id
        category {
            id
            key
            name
        }
        label {
            id
            key
            label
        }
        email
        is_main_email_address
    }
    phoneNumbers {
        id
        category {
            id
            key
            name
        }
        label {
            id
            key
            label
        }
        phone_number
        is_main_phone_number
    }
    customer_status
    ... on Person {
        first_name
        last_name
        gender
        personal_pronoun
    }
    ... on Company {
        company_name
        is_tenant_company
    }
`

export const CONTACTS_QUERY = buildContactsQuery(defaultContactsQueryFields)
