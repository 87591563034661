import { RegistrationAuthority } from '@/enums/graphql'
import MAIL_PROCESSING_SETTINGS_FIELDS from '@/forms/mailProcessingSettings.fields.js'

const registrationAuthorityOptions = Object.values(RegistrationAuthority).map(value => ({ value: value, label: `common.registration-authority.${value.toLowerCase().replaceAll('_', '-')}.registration-authority-name--short` }))

export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'company',
            label: 'views.admin.consulting-settings.superordinate-consulting-company',
            infoText: 'views.admin.consulting-settings.company-select--description',
            inputType: 'company',
            validators: [
                { type: 'required' },
            ],
        },
        {
            type: 'field',
            key: 'registrationAuthority',
            label: 'common.registration-authority.registration-authority',
            inputType: 'select',
            extra_attributes: {
                list: {
                    options: registrationAuthorityOptions,
                },
            },
            validators: [
                { type: 'dirtyChecker' },
            ],
        },
        {
            type: 'field',
            key: 'registrationNumber',
            label: 'common.registration-authority.registration-number', // TODO: Depending on the selected registration authority, the translation key should be adjusted: `common.registration-authority.(che-finma|lie-fma|che-igb2b).registration-number`
            inputType: 'text',
            validators: [
                { type: 'minLength', params: { min: 1 } },
                { type: 'maxLength', params: { max: 15 } },
            ],
        },
        {
            type: 'field',
            key: 'currentAccount',
            label: 'common.accounting.account.type.current-account',
            inputType: 'account',
            validators: [
                {
                    type: 'notSameAs',
                    params: {
                        target: 'cancellationReserveAccount',
                        targetLabel: 'common.accounting.account.type.cancellation-reserve-account',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'cancellationReserveAccount',
            label: 'common.accounting.account.type.cancellation-reserve-account',
            inputType: 'account',
            validators: [
                {
                    type: 'notSameAs',
                    params: {
                        target: 'currentAccount',
                        targetLabel: 'common.accounting.account.type.current-account',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'otherIncomeAccount',
            label: 'common.accounting.account.type.other-income-account',
            inputType: 'account',
            validators: [
                {
                    type: 'notSameAs',
                    params: {
                        target: 'commissionIncomeAccount',
                        targetLabel: 'common.accounting.account.type.commission-income-account',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'commissionIncomeAccount',
            label: 'common.accounting.account.type.commission-income-account',
            inputType: 'account',
            validators: [
                {
                    type: 'notSameAs',
                    params: {
                        target: 'otherIncomeAccount',
                        targetLabel: 'common.accounting.account.type.other-income-account',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'mailProcessingRevenueAccount',
            label: 'common.accounting.account.type.mail-processing-revenue-account',
            inputType: 'account',
            validators: [ // TODO @TFU: This should be notSameAs all the other assigned accounts (not just currentAccount), but that is currently not possible.
                {
                    type: 'notSameAs',
                    params: {
                        target: 'currentAccount',
                        targetLabel: 'common.accounting.account.type.current-account',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'commissionDistributionRuleSet',
            label: 'views.commission.commission-distribution.commission-distribution-rule',
            inputType: 'commissionDistributionRuleSet',
            validators: [
                { type: 'dirtyChecker' },
            ],
        },
        {
            type: 'field',
            key: 'statementRecipientIds',
            label: {
                key: 'views.contact.settings.consulting-settings.statement-recipient.statement-recipient',
                count: 2,
            },
            inputType: 'consultants',
            validators: [
                { type: 'dirtyChecker' },
            ],
        },
        ...MAIL_PROCESSING_SETTINGS_FIELDS.children,
    ],
}
