<template>
    <div>
        <base-table
            :columns="columns"
            enable-visible-columns
            :fetch-objects-fn="ProductDerivedEntityService.all"
            :additional-filters="additionalFilters"
            user-settings-base-path="ContactContractList"
            pagination-sort-by-default-key="product_name"
            data-test="table:contract-list"
            @row-dblclick="onDblClick"
            @update:paginator-info="handleUpdatePaginatorInfo"
        >
            <template v-slot:tabs>
                <q-tabs
                    v-model="currentTab"
                    dense
                    no-caps
                    inline-label
                    align="left"
                    class="q-mr-sm bg-background-secondary"
                    indicator-color="accent"
                    data-test="tabs-contract:links"
                >
                    <q-tab
                        name="active"
                        :label="$t('common.status.active')"
                        data-test="tab-contract:active"
                    />
                    <q-tab
                        name="terminated"
                        :label="$t('common.status.terminated')"
                        data-test="tab-contract:terminated"
                    />
                    <q-tab
                        name="archived"
                        :label="$t('common.status.archived')"
                        data-test="tab-contract:archived"
                    />
                </q-tabs>
            </template>

            <template v-slot:body-cell-number="slotProps">
                <q-td :props="slotProps">
                    <b>{{ slotProps.row.type === ProductDerivedEntityType.APPLICATION ? slotProps.row.formattedNumber : slotProps.row.currentContractNumber }}</b>
                    <status-badge
                        v-if="slotProps.row.type === ProductDerivedEntityType.APPLICATION"
                        :status="slotProps.row.is_change_application ? 'change-application' : kebabCase(slotProps.row.type)"
                        :default-translation-base-path="`common.status.${kebabCase(slotProps.row.type)}-status`"
                        class="q-ml-xs"
                    />
                </q-td>
            </template>

            <template v-slot:body-cell-product_provider_name="slotProps">
                <q-td :props="slotProps">
                    {{ slotProps.row.currentContractInformation.product.providerName }}
                    <info-icon
                        v-if="slotProps.row.currentContractInformation.generalAgency"
                        :text="slotProps.row.currentContractInformation.generalAgency.company_name"
                    />
                </q-td>
            </template>

            <template v-slot:body-cell-consultant="slotProps">
                <q-td :props="slotProps">
                    <contact-name
                        :contact="slotProps.row.consultant"
                        :consulting-company-name="!slotProps.row.consultant.worksForUserConsultingCompany"
                    />
                </q-td>
            </template>

            <template v-slot:body-cell-commission_recipient="slotProps">
                <q-td :props="slotProps">
                    <span :class="['contact-name', { 'additional-info': !slotProps.row.commissionRecipientOverride && !slotProps.row.hasActiveCommissionSplits }]" data-test="text:contact-name">
                        <template v-if="slotProps.row.has_commission_recipient_override">{{ slotProps.row.accessToCommissionRecipientDetailsRestricted ? null : slotProps.row.commissionRecipientOverride.getContactName({ consultingCompanyName: !slotProps.row.commissionRecipientOverride.worksForUserConsultingCompany }) }}</template>
                        <template v-else>{{ slotProps.row.consultant.getContactName({ consultingCompanyName: !slotProps.row.consultant.worksForUserConsultingCompany }) }}</template>
                    </span>

                    <info-icon
                        v-if="slotProps.row.hasCommissionRecipientOverrides || slotProps.row.hasActiveCommissionSplits"
                        :name="slotProps.row.accessToCommissionRecipientDetailsRestricted ? 'mib-lock-1' : 'mib-information-circle'"
                        :text="$t('views.contract.settings.commission.commission-recipient-override.contract-has-override-text')"
                        :color="slotProps.row.accessToCommissionRecipientDetailsRestricted ? 'text' : 'secondary-light'"
                        :class="[{ 'q-ml-xs': !slotProps.row.accessToCommissionRecipientDetailsRestricted }]"
                        :do-not-print-icon="false"
                    >
                        <p v-if="slotProps.row.accessToCommissionRecipientDetailsRestricted" class="title">{{ $t('common.term.access--no-access') }}</p>

                        <p v-if="slotProps.row.hasCommissionRecipientOverrides && slotProps.row.hasActiveCommissionSplits">{{ $tc('common.contract.commission.has-commission-recipient-override--has-active-commission-splitting', 1) }}</p>
                        <p v-else-if="slotProps.row.hasCommissionRecipientOverrides">{{ $tc('common.contract.commission.has-commission-recipient-override', 1) }}</p>
                        <p v-else-if="slotProps.row.hasActiveCommissionSplits">{{ $tc('common.contract.commission.has-active-commission-splitting', 1) }}</p>

                        <p v-if="slotProps.row.accessToCommissionRecipientDetailsRestricted">{{ $tc('common.contract.commission.has-commission-recipient-override--no-access', 1) }}</p>
                    </info-icon>

                </q-td>
            </template>

            <template v-slot:body-cell-status="slotProps">
                <q-td :props="slotProps">
                    <contract-status-badge
                        :contract="slotProps.row"
                        :default-translation-base-path="`common.status.${kebabCase(slotProps.row.type)}-status`"
                    />
                    <status-badge
                        v-if="slotProps.row.currentContractInformation.managed_by_status !== ContractManagedByStatus.INTERNAL"
                        :status="kebabCase(slotProps.row.currentContractInformation.managed_by_status)"
                        default-translation-base-path="common.contract.managed-by-status"
                    />
                </q-td>
            </template>

            <template v-slot:body-cell-actions="slotProps">
                <q-td :props="slotProps" auto-width>
                    <template v-if="slotProps.row.type === ProductDerivedEntityType.APPLICATION">
                        <q-btn
                            color="primary"
                            size="sm"
                            flat
                            round
                            icon="mib-view-1"
                            :to="{ name: 'application-detail', params: { contactId: contactId, id: slotProps.row.id } }"
                            data-test="btn:tooltip-details"
                        >
                            <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.contract.list.show-details-of-contract-xy', 1, { contractNumber: slotProps.row.currentContractNumber }) }}</q-tooltip>
                        </q-btn>
                        <q-btn
                            color="primary"
                            size="sm"
                            flat
                            round
                            icon="mib-common-file-stack-alternate"
                            :to="{ name: 'application-detail', params: { contactId: contactId, id: slotProps.row.id, tab: 'documents' } }"
                            data-test="btn:tooltip-actions"
                        >
                            <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.contract.list.show-documents-of-contract-xy', 1, { contractNumber: slotProps.row.currentContractNumber }) }}</q-tooltip>
                        </q-btn>
                        <!-- TODO: Uncomment this when history tab has been reintroduced on contract detail view. -->
                        <!--
                        <q-btn color="primary" size="sm" flat round icon="mib-synchronize-arrow-clock" :to="{ name: 'contract-detail', params: { contactId: contactId, id: slotProps.row.id, tab: 'history' } }">
                            <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.contract.list.show-change-log-of-contract-xy', 1, { contractNumber: slotProps.row.currentContractNumber }) }}</q-tooltip>
                        </q-btn>
                        -->
                    </template>
                    <template v-else>
                        <q-btn
                            color="primary"
                            size="sm"
                            flat
                            round
                            icon="mib-view-1"
                            :to="{ name: 'contract-detail', params: { contactId: contactId, id: slotProps.row.id } }"
                            data-test="btn:tooltip-details"
                        >
                            <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.contract.list.show-details-of-contract-xy', 1, { contractNumber: slotProps.row.currentContractNumber }) }}</q-tooltip>
                        </q-btn>
                        <q-btn
                            color="primary"
                            size="sm"
                            flat
                            round
                            icon="mib-common-file-stack-alternate"
                            :to="{ name: 'contract-detail', params: { contactId: contactId, id: slotProps.row.id, tab: 'documents' } }"
                            data-test="btn:tooltip-actions"
                        >
                            <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.contract.list.show-documents-of-contract-xy', 1, { contractNumber: slotProps.row.currentContractNumber }) }}</q-tooltip>
                        </q-btn>
                        <!-- TODO: Uncomment this when history tab has been reintroduced on contract detail view. -->
                        <!--
                        <q-btn color="primary" size="sm" flat round icon="mib-synchronize-arrow-clock" :to="{ name: 'contract-detail', params: { contactId: contactId, id: slotProps.row.id, tab: 'history' } }">
                            <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.contract.list.show-change-log-of-contract-xy', 1, { contractNumber: slotProps.row.currentContractNumber }) }}</q-tooltip>
                        </q-btn>
                        -->
                    </template>

                </q-td>
            </template>
        </base-table>

        <in-page-footer>
            <base-button
                :to="{ name: 'application-create', params: { contactId: contactId } }"
                :label="$tc('views.contract.add-update-application.add-application', 1)"
                icon="mib-common-file-text-clock"
                primary-button
                data-test="link:contact-add-application"
            />
            <base-button
                :to="{ name: 'contract-create', params: { contactId: contactId } }"
                :label="$tc('views.contract.add-contract.add-contract', 1)"
                icon="mib-common-file-text-add"
                primary-button
                outline
                data-test="link:contact-add-contract"
            />
        </in-page-footer>
    </div>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import { EventBus } from '@/event-bus'
import StatusBadge from '@/components/StatusBadge'
import ContractStatusBadge from '@/components/contract/ContractStatusBadge.vue'
import ContactName from '@/components/contact/ContactName.vue'
import BaseTable from '@/components/BaseTable'

import { Contract } from '@/models/contract'
import { ApplicationStatus, ContractStatus, ContractManagedByStatus, ProductDerivedEntityType } from '@/enums/graphql'
import { ProductDerivedEntityService } from '@/services'

export default {
    name: 'ContactContractList',
    components: {
        BaseTable,
        ContactName,
        StatusBadge,
        ContractStatusBadge,
    },
    props: {
        contactId: {
            type: String,
            required: true,
        },
    },
    data () {
        const columns = [
            {
                name: 'number',
                label: this.$t('views.contract.list.column-title--number'),
                sortable: true,
                align: 'left',
                required: true,
            },
            {
                name: 'product_name',
                label: this.$tc('common.product.product-name', 1),
                field: row => row.currentContractInformation.product.name,
                sortable: true,
                align: 'left',
                required: true,
            },
            {
                name: 'product_provider_name',
                label: this.$tc('common.product.product-provider', 1),
                field: row => row.currentContractInformation.product.providerName,
                // sortable: true, // TODO: in order to make this work the contracts query needs to be extended
                align: 'left',
                required: true,
            },
            {
                name: 'start_date',
                label: this.$tc('common.contract.contract-start', 1),
                field: row => row.currentContractInformation.formattedStartDate,
                sortable: true,
                align: 'left',
            },
            {
                name: 'end_date',
                label: this.$tc('common.contract.contract-end', 1),
                field: row => row.currentContractInformation.formattedEndDate,
                sortable: true,
                align: 'left',
            },
            {
                name: 'consultant',
                label: this.$tc('common.contact.consultant', 1),
                align: 'left',
                field: row => row.consultant.getContactName({ consultingCompanyName: !row.consultant.worksForUserConsultingCompany }),
                hideInitially: true,
            },
            {
                name: 'status',
                label: this.$tc('common.term.status'),
                align: 'left',
                required: true,
            },
            {
                name: 'actions',
                label: this.$tc('common.term.action', 2),
                align: 'left',
                required: true,
            },
        ]

        // Only insert commission recipient column if commissions module is available
        if (this.$can('Feature:commissions:core')) {
            columns.splice(-2, 0, {
                name: 'commission_recipient',
                label: this.$tc('views.contract.settings.commission.commission-recipient-override.commission-recipient', 1),
                align: 'left',
                hideInitially: true,
            })
        }

        return {
            ProductDerivedEntityType,
            ProductDerivedEntityService,
            Contract,
            ContractStatus,
            ContractManagedByStatus,
            currentTab: 'active',
            columns,
        }
    },
    computed: {
        applicationStatusFilter () {
            const statusFilter = []
            switch (this.currentTab) {
                case 'active':
                    statusFilter.push(
                        ApplicationStatus.ON_HOLD,
                        ApplicationStatus.PENDING_AT_PRODUCT_PROVIDER,
                        ApplicationStatus.COUNTER_PROPOSAL_PENDING_AT_CUSTOMER
                    )
                    break
                case 'archived':
                    statusFilter.push(
                        ApplicationStatus.APPROVED_BY_PRODUCT_PROVIDER,
                        ApplicationStatus.DECLINED_BY_PRODUCT_PROVIDER,
                        ApplicationStatus.COUNTER_PROPOSAL_DECLINED_BY_CUSTOMER,
                        ApplicationStatus.WITHDRAWN
                    )
                    break
            }
            return statusFilter
        },
        entityTypeFilter () {
            const entityTypeFilter = []
            if (this.currentTab === 'terminated') entityTypeFilter.push(ProductDerivedEntityType.CONTRACT)
            return entityTypeFilter
        },
        additionalFilters () {
            const filters = {
                filterCustomerId: this.contactId,
                filterEntityTypes: this.entityTypeFilter,
                filterContractStatus: [this.currentTab.toUpperCase()],
                filterApplicationStatus: this.applicationStatusFilter,
            }
            return filters
        },
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
        EventBus.$on('shortcut:newAlternativeEntity', this.onNewAlternativeEntity)
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
        EventBus.$off('shortcut:newAlternativeEntity', this.onNewAlternativeEntity)
    },
    methods: {
        handleUpdatePaginatorInfo (paginatorInfo) {
            this.$emit('numberOfContracts', paginatorInfo.total)
        },
        onNewEntity () {
            this.$router.push({ name: 'application-create', params: { contactId: this.contactId } })
        },
        onNewAlternativeEntity () {
            this.$router.push({ name: 'contract-create', params: { contactId: this.contactId } })
        },
        onDblClick (evt, row) {
            switch (row.type) {
                case ProductDerivedEntityType.APPLICATION:
                    this.$router.push({ name: 'application-detail', params: { contactId: this.contactId, id: row.id } })
                    break
                case ProductDerivedEntityType.CONTRACT:
                    this.$router.push({ name: 'contract-detail', params: { contactId: this.contactId, id: row.id } })
                    break
            }
        },
        kebabCase: function (text) {
            return kebabCase(text)
        },
    },
}
</script>
