import { apolloClient } from '@/apollo/client'

import { DXP_SERVICE_PROVIDER_CONFIGURATION_QUERY } from '@/graphql/dxpServiceProviderConfiguration/dxpServiceProviderConfiguration'
import { DXP_SERVICE_PROVIDER_CONFIGURATIONS_QUERY } from '@/graphql/dxpServiceProviderConfiguration/dxpServiceProviderConfigurations'
import { DXP_SERVICE_PROVIDER_CONFIGURATION_UPDATE_MUTATION } from '@/graphql/dxpServiceProviderConfiguration/dxpServiceProviderConfigurationUpdate'

export const DxpServiceProviderConfigurationService = {
    get (service_provider_id) {
        return apolloClient.query({
            query: DXP_SERVICE_PROVIDER_CONFIGURATION_QUERY,
            variables: {
                service_provider_id: service_provider_id,
            },
        }).then(response => response.data.dxpServiceProviderConfiguration)
    },
    all (variables) {
        return apolloClient.query({
            query: DXP_SERVICE_PROVIDER_CONFIGURATIONS_QUERY,
            variables,
        }).then(response => response.data.dxpServiceProviderConfigurations)
    },
    update (dxpServiceProviderConfigurationVariables) {
        return apolloClient.mutate({
            mutation: DXP_SERVICE_PROVIDER_CONFIGURATION_UPDATE_MUTATION,
            variables: {
                dxpServiceProviderConfiguration: dxpServiceProviderConfigurationVariables,
            },
        }).then(response => response.data.dxpServiceProviderConfigurationUpdate)
    },
}
