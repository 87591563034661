import { apolloClient } from '@/apollo/client'

import { DXP_INSURER_LIST_QUERY } from '@/graphql/dxpInsurerList/dxpInsurerList'

export const DxpInsurerListService = {
    get ({ hasActiveAgreement }) {
        return apolloClient.query({
            query: DXP_INSURER_LIST_QUERY,
            variables: {
                hasActiveAgreement,
            },
        }).then(response => response.data.dxpInsurerList)
    },
}
