import { EcoHubTechUser } from '@/models/ecoHubTechUser'
import { DxpServiceProviderConfigurationService } from '@/services'
import { DxpInsurerListService } from '@/services/dxpInsurerList'
import store from '@/store/store'

export function getAgreementPermissions (serviceProviderId) {
    if (store.state.dxpInsurerListActiveAgreements) {
        let permissionScore = 0
        if (store.state.dxpInsurerListActiveAgreements.download_permissions_granted.includes(serviceProviderId)) permissionScore++
        if (store.state.dxpInsurerListActiveAgreements.upload_permissions_granted.includes(serviceProviderId)) permissionScore++

        // TODO improvement @TFU: Extract enums and refactor if used elsewhere.
        switch (permissionScore) {
            case 0:
                return 'NO_PERMISSIONS'
            case 1:
                return 'PARTIAL_PERMISSIONS'
            case 2:
                return 'FULL_PERMISSIONS'
        }
    } else {
        return null
    }
}

export function loadEcoHubAndDxpBaseData () {
    const promises = []

    promises.push(EcoHubTechUser.service.get().then(ecoHubTechUser => {
        store.dispatch('setEcoHubTechUser', ecoHubTechUser)
    }).catch(() => {})) // Catch error, so it doesn't get logged to the console.

    promises.push(DxpInsurerListService.get({ hasActiveAgreement: true }).then(insurerList => {
        store.dispatch('setDxpInsurerListActiveAgreements', insurerList)
    }).catch(() => {})) // Catch error, so it doesn't get logged to the console.

    promises.push(DxpServiceProviderConfigurationService.all().then(response => {
        const dxpServiceProviderConfigurationDisplayNames = {}
        response.data.forEach(dxpServiceProviderConfiguration => {
            dxpServiceProviderConfigurationDisplayNames[dxpServiceProviderConfiguration.service_provider_id] = dxpServiceProviderConfiguration.service_provider_display_name;
        })
        store.dispatch('setDxpServiceProviderConfigurationDisplayNames', dxpServiceProviderConfigurationDisplayNames)
    }).catch(() => {})) // Catch error, so it doesn't get logged to the console.

    return Promise.allSettled(promises)
}

export function getNumberOfMatchesOfType (contractMatchShortlist, customerNameMatch) {
    return contractMatchShortlist.filter(contractMatch => contractMatch.customer_name_match === customerNameMatch).length
}
