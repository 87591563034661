import gql from 'graphql-tag'

export const DXP_SERVICE_PROVIDER_CONFIGURATION_QUERY = gql`
    query dxpServiceProviderConfiguration(
        $service_provider_id: ID!
    ) {
        dxpServiceProviderConfiguration(
            service_provider_id: $service_provider_id
        ) {
            service_provider_id
            service_provider_display_name
            supportedProcesses {
                dxpProcess {
                    id
                }
                version
            }
            enabledProcesses {
                id
            }
            enabledForAutomaticProcessing {
                id
            }
            created_at
            updated_at
        }
    }
`
