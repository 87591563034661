<template>
    <div class="col-xs-12">
        <div class="row q-col-gutter-md">
            <grid-card
                :columns="{
                    xs: 12,
                    sm: 12,
                    md: 6,
                    lg: 6,
                    xl: 4
                }"
                container-type-normal
                :heading="$tc('common.product-provider.product-provider-info', 2)"
            >
                <form-builder-next
                    v-slot="{ formBuilder }"
                    :form="productProviderSettingsForm"
                    :initial-data="state.initialData"
                    in-page-footer
                    :submit-button-label="productProviderSettings ? $t('common.term.save') : $t('views.admin.products.product-providers.add-product-provider-settings')"
                    @cancel="$emit('cancel')"
                    @submit="formData => $emit('submit', formData)"
                />
            </grid-card>
        </div>
    </div>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import { reactive, ref } from 'vue'
import { ProductProviderSettingsForm } from '@/forms/productProviderSettings'
import { useStore } from '@/composables/store'

export default {
    name: 'ProductProviderSettingsForm',
    props: {
        productProviderSettings: {
            type: Object,
            required: false,
            default: undefined,
        },
    },
    setup (props, context) {
        // Composables
        const store = useStore()

        // Data
        const state = reactive({
            ecoHubTechUser: null,
            igb2bInsurerCodes: null,
            insurerList: null,
            initialData: null,
        })

        if (props.productProviderSettings) {
            state.initialData = {
                displayName: props.productProviderSettings.display_name,
            }
            if (props.productProviderSettings.igb2bInsurerCode) state.initialData.igb2bInsurerCode = props.productProviderSettings.igb2bInsurerCode.id
        }

        const formContext = {
            fields: props.productProviderSettings ? ['displayName', 'igb2bInsurerCode'] : ['contactId', 'displayName', 'igb2bInsurerCode'], // TODO refactor @TFU: This is experimental. A more generic solution without using the context would be nice.
        }
        if (props.productProviderSettings?.igb2bInsurerCode) formContext.igb2bInsurerCodeInjectOptions = [props.productProviderSettings.igb2bInsurerCode]
        const productProviderSettingsForm = new ProductProviderSettingsForm({ context: formContext })

        // Template refs
        const formComponent = ref(null)

        return {
            // Data
            store,
            state,
            productProviderSettingsForm,

            // Template refs
            formComponent,

            // Functions
            kebabCase,
        }
    },
}
</script>
