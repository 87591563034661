import Vue from 'vue'
import Vuex from 'vuex'
import { LocalStorage } from 'quasar' // TODO: check if we really need this quasar overhead for LocalStorage…
import packageJson from '#/package.json'

Vue.use(Vuex)

export default new Vuex.Store({
    state () {
        return {
            release: packageJson.version,
            drawerLeft: true,
            drawerRight: false,
            drawerRightMiniState: (localStorage.getItem('drawerRightMiniState') === null),
            editMode: (localStorage.getItem('editMode') !== null),
            online: navigator.onLine,
            user: null,
            addressLabels: {},
            emailAddressLabels: {},
            phoneNumberLabels: {},
            countryLabels: {},
            contractFileTypes: {},
            igb2bSectorCodes: {},
            ecoHubTechUser: null,
            dxpInsurerListActiveAgreements: null,
            dxpServiceProviderConfigurationDisplayNames: {},
            igb2bInsurerCodes: null,

            // DEV ONLY
            devHeader: false,
        }
    },
    mutations: {
        SET_DRAWER_LEFT (state, isOpen) {
            state.drawerLeft = isOpen
        },
        SET_DRAWER_RIGHT (state, isOpen) {
            state.drawerRight = isOpen
        },
        SET_DRAWER_RIGHT_MINISTATE (state, isMini) {
            state.drawerRightMiniState = isMini
        },
        SET_EDIT_MODE (state, isActive) {
            state.editMode = isActive
        },
        SET_ONLINE_STATUS (state, isOnline) {
            state.online = isOnline
        },
        SET_USER (state, user) {
            state.user = user
        },
        SET_ACL_FEATURES (state, aclFeatures) {
            state.user.aclFeatures = aclFeatures
        },
        SET_ACL_ROLES (state, aclRoles) {
            state.user.aclRoles = aclRoles
        },
        SET_ACL_PERMISSIONS (state, aclPermissions) {
            state.user.aclPermissions = aclPermissions
        },
        SET_ADDRESS_LABELS (state, addressLabels) {
            state.addressLabels = addressLabels
        },
        SET_EMAIL_ADDRESS_LABELS (state, emailAddressLabels) {
            state.emailAddressLabels = emailAddressLabels
        },
        SET_PHONE_NUMBER_LABELS (state, phoneNumberLabels) {
            state.phoneNumberLabels = phoneNumberLabels
        },
        SET_COUNTRY_LABELS (state, countryLabels) {
            state.countryLabels = countryLabels
        },
        SET_CONTRACT_FILE_TYPES (state, contractFileTypes) {
            state.contractFileTypes = contractFileTypes
        },
        SET_IGB2B_SECTOR_CODES (state, igb2bSectorCodes) {
            state.igb2bSectorCodes = igb2bSectorCodes
        },
        SET_ECO_HUB_TECH_USER (state, ecoHubTechuser) {
            state.ecoHubTechUser = ecoHubTechuser
        },
        SET_DXP_INSURER_LIST_ACTIVE_AGREEMENTS (state, insurerList) {
            state.dxpInsurerListActiveAgreements = insurerList
        },
        SET_DXP_SERVICE_PROVIDER_CONFIGURATION_DISPLAY_NAMES (state, dxpServiceProviderConfigurationDisplayNames) {
            state.dxpServiceProviderConfigurationDisplayNames = dxpServiceProviderConfigurationDisplayNames
        },
        SET_IGB2B_INSURER_CODES (state, igb2bInsurerCodes) {
            state.igb2bInsurerCodes = igb2bInsurerCodes
        },
        SET_DEV_HEADER (state, isActive) {
            state.devHeader = isActive
        },
    },
    actions: {
        toggleDrawerLeft ({ state, dispatch }) {
            if (state.drawerLeft) {
                dispatch('hideDrawerLeft')
            } else {
                dispatch('showDrawerLeft')
            }
        },
        showDrawerLeft ({ commit }) {
            commit('SET_DRAWER_LEFT', true)
        },
        hideDrawerLeft ({ commit }) {
            commit('SET_DRAWER_LEFT', false)
        },
        toggleDrawerRight ({ state, dispatch }) {
            if (state.drawerRight) {
                dispatch('hideDrawerRight')
            } else {
                dispatch('showDrawerRight')
            }
        },
        showDrawerRight ({ commit }) {
            commit('SET_DRAWER_RIGHT', true)
        },
        hideDrawerRight ({ commit }) {
            commit('SET_DRAWER_RIGHT', false)
        },
        toggleDrawerRightMiniState ({ state, dispatch }) {
            if (state.drawerRightMiniState) {
                dispatch('disableDrawerRightMiniState')
            } else {
                dispatch('enableDrawerRightMiniState')
            }
        },
        enableDrawerRightMiniState ({ commit }) {
            localStorage.removeItem('drawerRightMiniState')
            commit('SET_DRAWER_RIGHT_MINISTATE', true)
        },
        disableDrawerRightMiniState ({ commit }) {
            localStorage.setItem('drawerRightMiniState', false)
            commit('SET_DRAWER_RIGHT_MINISTATE', false)
        },
        activateOnlineStatus ({ commit }) {
            commit('SET_ONLINE_STATUS', true)
        },
        deactivateOnlineStatus ({ commit }) {
            commit('SET_ONLINE_STATUS', false)
        },
        toggleEditMode ({ state, dispatch }) {
            if (state.editMode) {
                dispatch('disableEditMode')
            } else {
                dispatch('enableEditMode')
            }
        },
        enableEditMode ({ commit }) {
            localStorage.setItem('editMode', true)
            commit('SET_EDIT_MODE', true)
        },
        disableEditMode ({ commit }) {
            localStorage.removeItem('editMode')
            commit('SET_EDIT_MODE', false)
        },
        toggleDevHeader ({ state, dispatch }) {
            if (state.devHeader) {
                dispatch('hideDevHeader')
            } else {
                dispatch('showDevHeader')
            }
        },
        setUser ({ commit }, user) {
            commit('SET_USER', user)
        },
        setAclFeatures ({ state, commit }, aclFeatures) {
            if (state.user) {
                commit('SET_ACL_FEATURES', aclFeatures)
            }
        },
        setAclRoles ({ state, commit }, aclRoles) {
            if (state.user) {
                commit('SET_ACL_ROLES', aclRoles)
            }
        },
        setAclPermissions ({ state, commit }, aclPermissions) {
            if (state.user) {
                commit('SET_ACL_PERMISSIONS', aclPermissions)
            }
        },
        setAddressLabels ({ commit }, addressLabels) {
            commit('SET_ADDRESS_LABELS', addressLabels)
        },
        setEmailAddressLabels ({ commit }, emailAddressLabels) {
            commit('SET_EMAIL_ADDRESS_LABELS', emailAddressLabels)
        },
        setPhoneNumberLabels ({ commit }, phoneNumberLabels) {
            commit('SET_PHONE_NUMBER_LABELS', phoneNumberLabels)
        },
        setCountryLabels ({ commit }, countryLabels) {
            commit('SET_COUNTRY_LABELS', countryLabels)
        },
        setContractFileTypes ({ commit }, contractFileTypes) {
            commit('SET_CONTRACT_FILE_TYPES', contractFileTypes)
        },
        setIgb2bSectorCodes ({ commit }, igb2bSectorCodes) {
            commit('SET_IGB2B_SECTOR_CODES', igb2bSectorCodes)
        },
        setEcoHubTechUser ({ commit }, ecoHubTechUser) {
            commit('SET_ECO_HUB_TECH_USER', ecoHubTechUser)
        },
        setDxpInsurerListActiveAgreements ({ commit }, insurerList) {
            commit('SET_DXP_INSURER_LIST_ACTIVE_AGREEMENTS', insurerList)
        },
        setDxpServiceProviderConfigurationDisplayNames ({ commit }, dxpServiceProviderConfigurationDisplayNames) {
            commit('SET_DXP_SERVICE_PROVIDER_CONFIGURATION_DISPLAY_NAMES', dxpServiceProviderConfigurationDisplayNames)
        },
        setIgb2bInsurerCodes ({ commit }, igb2bInsurerCodes) {
            commit('SET_IGB2B_INSURER_CODES', igb2bInsurerCodes)
        },
        showDevHeader ({ commit }) {
            LocalStorage.set('show-dev-header', true)
            commit('SET_DEV_HEADER', true)
        },
        hideDevHeader ({ commit }) {
            LocalStorage.remove('show-dev-header')
            commit('SET_DEV_HEADER', false)
        },
    },
})
