import gql from 'graphql-tag'

export const DXP_SERVICE_PROVIDER_CONFIGURATION_UPDATE_MUTATION = gql`
    mutation dxpServiceProviderConfigurationUpdate(
        $dxpServiceProviderConfiguration: DxpServiceProviderConfigurationInput!
    ) {
        dxpServiceProviderConfigurationUpdate(
            dxpServiceProviderConfiguration: $dxpServiceProviderConfiguration
        ) {
            service_provider_id
            service_provider_display_name
            supportedProcesses {
                dxpProcess {
                    id
                }
                version
            }
            enabledProcesses {
                id
            }
            enabledForAutomaticProcessing {
                id
            }
            created_at
            updated_at
        }
    }
`
