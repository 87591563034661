import { isAfter, isBefore, isEqual, isValid } from 'date-fns'
import { helpers } from 'vuelidate/lib/validators'

export function isBeforeOrEqualToDate(dateString, targetDateString) {
    if (!targetDateString) return true
    const date = new Date(dateString)
    const targetDate = new Date(targetDateString)
    if (!isValid(date) || !isValid(targetDate)) return true
    return !helpers.req(dateString) || (isEqual(date, targetDate) || isBefore(date, targetDate))
}

export function isAfterOrEqualToDate(dateString, targetDateString) {
    if (!targetDateString) return true
    const date = new Date(dateString)
    const targetDate = new Date(targetDateString)
    if (!isValid(date) || !isValid(targetDate)) return true
    return !helpers.req(dateString) || (isEqual(date, targetDate) || isAfter(date, targetDate))
}
