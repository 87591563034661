<template>
    <div>
        <template v-if="currentMode === ContentStoreViewMode.LIST">
            <content-store-list
                :items="items"
                :columns="columns"
                :content-type="contentType"
                @update-selection="selectedItems => { selection = selectedItems }"
            />
            <q-card-actions class="q-gutter-sm">
                <base-button
                    :label="$t('common.term.import-action')"
                    :disable="selection.length === 0"
                    primary-button
                    @click="triggerImports"
                />
                <base-button
                    :label="$t('common.term.cancel')"
                    primary-button
                    flat
                    @click="$emit('hide')"
                />
            </q-card-actions>
        </template>
        <div v-else-if="currentMode === ContentStoreViewMode.IMPORT">
            <content-store-import
                :queue="importQueue"
                :log="importLog"
                display-property-name="name"
                @abort="abortImport"
                @acknowledge="acknowledgeImport"
            />
        </div>
    </div>
</template>

<script>
import { walkTree } from '@/helpers'
import PRODUCT_TEMPLATES from '#/content-store/product-templates.json'
import { contentStoreMixin } from '@/mixins/contentStoreMixin'
import ContentStoreList from '@/components/content-store/ContentStoreList'
import ContentStoreImport from '@/components/content-store/ContentStoreImport'
import { ProductService } from '@/services'
import { ProductTemplate } from '@/models/models'
import { ContentStoreItemWrapperStatus } from '@/enums'

export default {
    name: 'ContentStoreProductProvider',
    components: {
        ContentStoreList,
        ContentStoreImport,
    },
    mixins: [contentStoreMixin],
    contentStoreSettings: {
        items: PRODUCT_TEMPLATES,
    },
    props: {
        contentType: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            columns: [
                // TODO improvement: Add more details/info to table (category, subcategory, as well as a preview of the items)
                { name: 'name', label: this.$tc('common.term.name'), field: row => row.item.name, sortable: true, align: 'left' },
                { name: 'status', label: this.$tc('common.term.status'), field: row => row.status, sortable: true, align: 'left' },
            ],
            productCategories: [],
            productFields: [],
        }
    },
    created () {
        ProductService.getCategories().then(productCategories => (this.productCategories = productCategories.data))
        ProductService.getProductFields().then(productFieldGroups => {
            const productFields = []
            productFieldGroups.forEach(productFieldGroup => productFields.push(...productFieldGroup.children))
            this.productFields = productFields
        })
    },
    methods: {
        importItem (contentStoreItemWrapper) {
            contentStoreItemWrapper.status = ContentStoreItemWrapperStatus.IMPORTING
            const productTemplate = contentStoreItemWrapper.item

            // Map product categories to the current tenant environment (from key to id).
            const productCategory = this.productCategories.find(productCategory => productCategory.key === productTemplate.category.key)
            const productCategoryId = productCategory.id
            const productSubCategoryId = productCategory.subCategories.find(subCategory => subCategory.key === productTemplate.subCategory.key).id

            // Map product fields to the current tenant environment (from key to id).
            walkTree(productTemplate.templateConfiguration, node => {
                if (node.type === 'field') {
                    const productField = this.productFields.find(productField => productField.key === node.key)
                    if (productField) node.id = productField.id
                }
            })

            // Import
            ProductTemplate.create(productTemplate.name, productCategoryId, productSubCategoryId, productTemplate.templateConfiguration)
                .then(() => {
                    this.handleImportSuccess(contentStoreItemWrapper)
                })
                .catch(() => {
                    this.handleImportFailure(contentStoreItemWrapper)
                })
        },
    },
}
</script>
